/* eslint-disable complexity */
import type { ReactElement } from 'react';
import { useState, useContext } from 'react';
import {
  WrappedHeading,
  isReducedMotion,
  appliedCloudinaryParams,
  useLanguage,
  SharedContext,
  useHeadingLevel,
} from '@curated-property/utils';
import { Trans, useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Telephone, Location, Clock, Contact, Mail } from '@curated-property/icon-list';
import { formatReviewDate, HandleAnimations } from './functions/helper';
import { GenericModal, DialogTitle } from './global/generic-modal';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from './functions/global-instance-styles';
import { WhatsApp } from '@curated-property/icons';
import { HotelAddress } from '@dx-ui/osc-hotel-address';
import type { WordpressPageInfoQuery } from './generated/wp';
import type { FlexComponents, PageProps } from 'apps/dx-curated-ui';

export function addressBarPropMapper(
  componentData: AddressBarProps & {
    addressBarSettings?: StyleObject;
  },
  globalData: NonNullable<
    NonNullable<WordpressPageInfoQuery['componentStyles']>['globalComponentSettings']
  >['globalComponentSettings'],
  dxGql: NonNullable<NonNullable<PageProps>['dxGqlInfo']>['hotel']
) {
  return {
    addressTitle: componentData?.addressTitle,
    addressTitleMobile: componentData?.addressTitleMobile,
    addressLine1: componentData?.addressLine1,
    addressUrl: componentData?.addressUrl,
    arrivalTitle: componentData?.arrivalTitle,
    arrivalTitleMobile: componentData?.arrivalTitleMobile,
    checkInText: componentData?.checkInText,
    checkInTitle: componentData?.checkInTitle,
    checkOutText: componentData?.checkOutText,
    checkOutTitle: componentData?.checkOutTitle,
    telephoneIcon: componentData?.telephoneIcon,
    telephoneLine1: componentData?.telephoneLine1,
    telephoneLine2: componentData?.telephoneLine2,
    telephoneLink: componentData?.telephoneLink,
    telephoneTitle: componentData?.telephoneTitle,
    telephoneTitleMobile: componentData?.telephoneTitleMobile,
    tripAdvisorToggle: componentData?.tripAdvisorToggle,
    tripAdvisorTitle: componentData?.tripAdvisorTitle,
    tripAdvisorTitleMobile: componentData?.tripAdvisorTitleMobile,
    hideArrivalDeparture: componentData?.hideArrivalDeparture,
    hideAddress: componentData?.hideAddress,
    hideContactInfo: componentData?.hideContactInfo,
    line2Icon: componentData?.line2Icon,
    line2Link: componentData?.line2Link,
    line2Text: componentData?.line2Text,
    line3Icon: componentData?.line3Icon,
    line3Link: componentData?.line3Link,
    line3Text: componentData?.line3Text,
    instanceStyles: componentData?.addressBarSettings,
    globalStyles: globalData?.addressBarSettings,
    corePlusData: {
      phone: dxGql?.contactInfo?.phoneNumber,
      name: dxGql?.name,
      lat: dxGql?.localization?.coordinate?.latitude,
      lng: dxGql?.localization?.coordinate?.longitude,
      address: {
        addressStacked: dxGql?.address?.addressStacked,
        addressLine1: dxGql?.address?.addressLine1,
        city: dxGql?.address?.city,
        country: dxGql?.address?.country,
        state: dxGql?.address?.state,
      },
      checkInTime: dxGql?.registration?.checkinTimeFmt,
      checkOutTime: dxGql?.registration?.checkoutTimeFmt,
      networkDisclaimer: dxGql?.contactInfo?.networkDisclaimer,
    },
  };
}

export interface AddressBarProps {
  componentIndex?: number;
  allComponents?: FlexComponents;
  addressTitle?: string;
  addressTitleMobile?: string;
  addressLine1?: string;
  addressUrl?: string;
  arrivalTitle?: string;
  arrivalTitleMobile?: string;
  checkInText?: string;
  checkInTitle?: string;
  checkOutText?: string;
  checkOutTitle?: string;
  telephoneIcon?: string;
  telephoneLine1?: string;
  telephoneLine2?: string;
  telephoneLink?: string;
  telephoneTitle?: string;
  telephoneTitleMobile?: string;
  line2Text?: string;
  line2Icon?: string;
  line2Link?: string;
  line3Text?: string;
  line3Icon?: string;
  line3Link?: string;
  tripAdvisorToggle?: boolean;
  tripAdvisorTitle?: string;
  tripAdvisorTitleMobile?: string;
  hideArrivalDeparture?: boolean;
  hideAddress?: boolean;
  hideContactInfo?: boolean;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  tripAdvisor?: any;
  corePlusData?: {
    phone?: string;
    name?: string;
    lat?: number;
    lng?: number;
    address?: {
      addressStacked?: string;
      addressLine1?: string;
      city?: string;
      country?: string;
      state?: string;
    };
    checkInTime?: string;
    checkOutTime?: string;
    networkDisclaimer?: string;
  };
  modalSettings?: {
    modalCloseButtonIconColour?: string;
    modalCloseButtonBackgroundColour?: string;
    modalBackgroundOpacity?: number;
    modalBackgroundColour?: string;
    hideBoxShadow?: boolean;
    contentBackgroundColour?: string;
  };
}

export function AddressBar({
  componentIndex,
  allComponents,
  addressTitle,
  addressTitleMobile,
  addressLine1,
  addressUrl,
  arrivalTitle,
  arrivalTitleMobile,
  checkInText,
  checkInTitle,
  checkOutText,
  checkOutTitle,
  telephoneIcon,
  telephoneLine2,
  telephoneLink,
  telephoneTitle,
  telephoneTitleMobile,
  line2Text,
  line2Icon,
  line2Link,
  line3Text,
  line3Icon,
  line3Link,
  tripAdvisorToggle,
  tripAdvisorTitle,
  tripAdvisorTitleMobile,
  hideArrivalDeparture,
  hideAddress,
  hideContactInfo,
  globalStyles,
  instanceStyles,
  tripAdvisor,
  corePlusData,
  modalSettings,
}: AddressBarProps) {
  const lang = useLanguage();
  const { t } = useTranslation();
  // check for route change and reset component instance
  const [modalActive, setModalActive] = useState(false);
  const [mobileAddressState, setMobileAddressState] = useState({
    reviews: false,
    callus: false,
    address: false,
    arrivaltime: false,
  });

  const sharedContext = useContext(SharedContext);
  const enableHHR = sharedContext?.enableHHR;

  const ratingStr = '' + tripAdvisor?.rating;
  const fullRating = ratingStr.length === 1 ? ratingStr + '.0' : ratingStr;

  const tripAdvisorCorePlusCheck =
    tripAdvisorToggle === true &&
    tripAdvisor &&
    tripAdvisor?.rating !== null &&
    tripAdvisor?.ratingImageUrl !== null;

  const headingLevel = useHeadingLevel(componentIndex, allComponents);
  const titleClasses = 'text-sm leading-5 font-bold w-full block text-center md:text-left';
  const wrapperClasses =
    'flex-col md:flex justify-start md:justify-center w-full md:w-auto md:h-16 mt-4 md:mt-0 py-6 lg:py-0 md:px-6 border-t md:border-r last:border-r-0 border-text-disabled md:border-t-0 rtl:md:border-l rtl:md:border-r-0 rtl:last:border-l-0';
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const componentPaddingCheck =
    inlineStyles?.paddingTop?.paddingTopDesktop ||
    inlineStyles?.paddingBottom?.paddingBottomDesktop ||
    inlineStyles?.paddingTop?.paddingTopTablet ||
    inlineStyles?.paddingBottom?.paddingBottomTablet ||
    inlineStyles?.paddingTop?.paddingTopMobile ||
    inlineStyles?.paddingBottom?.paddingBottomMobile
      ? true
      : false;
  const paddingStyles = componentPaddingCheck
    ? GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom)
    : null;
  /** core + data start */
  const cpAddress = {
    ...corePlusData?.address,
    addressStacked: addressLine1
      ? corePlusData?.address?.addressStacked?.replace(
          corePlusData?.address?.addressStacked.slice(
            0,
            corePlusData?.address?.addressStacked?.indexOf('\n')
          ),
          addressLine1
        )
      : corePlusData?.address?.addressStacked,
    addressLine1: addressLine1 || corePlusData?.address?.addressLine1,
  };
  const corePlusPhoneNumber = corePlusData?.phone;
  const corePlusCheckIn = corePlusData?.checkInTime;
  const corePlusCheckOut = corePlusData?.checkOutTime;
  const tripAdvisorTitleMobileString = tripAdvisorTitleMobile
    ? tripAdvisorTitleMobile
    : t('reviews');
  const telephoneTitleMobileString = telephoneTitleMobile ? telephoneTitleMobile : t('callUs');
  const addressTitleMobileString = addressTitleMobile ? addressTitleMobile : t('address');
  const arrivalTitleMobileString = arrivalTitleMobile ? arrivalTitleMobile : t('arrivalTime');

  let seperatorColor = inlineStyles?.seperatorColour
    ? inlineStyles?.seperatorColour
    : 'rgb(var(--color-bg-disabled))';

  if (enableHHR) {
    seperatorColor = inlineStyles?.seperatorColour ? inlineStyles?.seperatorColour : '';
  }
  /** end */
  const hideAnimations = inlineStyles?.hideAnimations !== 'show' || isReducedMotion;

  function iconSwitcher(icon: string | undefined) {
    let telIcon: ReactElement | null;
    switch (icon) {
      case 'Phone':
        telIcon = (
          <Telephone className="w-full" fillColor={inlineStyles?.subtitleColor || '#121212'} />
        );
        break;
      case 'Speech Bubble':
        telIcon = (
          <Contact className="w-full" fillColor={inlineStyles?.subtitleColor || '#121212'} />
        );
        break;
      case 'WhatsApp':
        telIcon = (
          <WhatsApp className="w-full" fillColor={inlineStyles?.subtitleColor || '#121212'} />
        );
        break;
      case 'Mail':
        telIcon = <Mail className="w-full" fillColor={inlineStyles?.subtitleColor || '#121212'} />;
        break;
      case 'Message':
        telIcon = (
          <Contact className="w-full" fillColor={inlineStyles?.subtitleColor || '#121212'} />
        );
        break;
      default:
        telIcon = null;
    }
    return telIcon;
  }

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
  });

  const tripAdvisorSection = (
    <div
      data-testid="tripadvisor"
      className={cx(
        'cp-contactBar-tripadvisor border-text-disabled mx-4 mt-4 w-full flex-col border-t py-6 last:border-x-0 md:mb-8 md:mt-0 md:flex md:border-b md:border-t-0 md:pt-0 lg:mx-0 lg:my-4 lg:h-16 lg:w-auto lg:border-b-0 lg:border-r lg:px-6 lg:py-0 rtl:lg:border-l rtl:lg:border-r-0',
        mobileAddressState.reviews ? 'flex' : 'hidden',
        animations?.one
      )}
      style={{ borderColor: seperatorColor }}
    >
      <div className="flex h-full flex-col items-center justify-center lg:items-start">
        <div>
          <div className="-ml-2.5 flex items-center">
            <img
              src={tripAdvisor?.ratingImageUrl}
              alt="Trip Advisor"
              width="164"
              height="24"
              className="block pr-1"
            />
            <span
              className={`text-text-alt font-bold ${
                lang === 'ar' ? 'border-r-1 mr-3.5 border-r pr-3.5' : 'border-l-1 border-l pl-3.5'
              } border-text-disabled overflow-hidden whitespace-nowrap`}
              style={{ borderColor: seperatorColor }}
            >
              <span className="sr-only absolute">{t('rating')}:</span>
              <span style={{ color: inlineStyles?.textColor }}>{fullRating}</span>
              <span className="sr-only absolute">{t('outOfFive')}</span>
            </span>
          </div>
          <div className="flex flex-col justify-center">
            <div>
              <span
                className="text-text-alt text-sm"
                style={{ color: inlineStyles?.subtitleColor }}
              >
                <Trans i18nKey="basedOnGuestReviews" count={tripAdvisor?.numReviews || 0} />
              </span>
            </div>
            <div className="flex flex-row">
              <button
                className="text-primary cursor-pointer whitespace-nowrap p-0 font-normal underline hover:no-underline"
                style={{ color: inlineStyles?.textColor }}
                onClick={() => {
                  modalActive ? setModalActive(false) : setModalActive(true);
                }}
              >
                {t('readRecentReviews', {
                  count: parseInt(tripAdvisor?.numReviews) > 5 ? 5 : tripAdvisor?.numReviews || 0,
                })}
              </button>
            </div>
            {tripAdvisorTitle && (
              <p
                id="addressBarPhone"
                className={titleClasses}
                style={{ color: inlineStyles?.titleColor }}
              >
                {tripAdvisorTitle || ''}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const telephoneLinkAlt = telephoneLine2
    ? `tel:${telephoneLine2?.split(' ').join('-')}`
    : `tel:${corePlusPhoneNumber?.split(' ').join('-')}`;

  const telephoneSection = (
    <div
      data-testid="telephone"
      className={cx(
        'cp-contactBar-telephone',
        wrapperClasses,
        mobileAddressState.callus ? 'flex' : 'hidden',
        animations?.two
      )}
      style={{ borderColor: seperatorColor }}
    >
      {telephoneTitle && (
        <p
          id="addressBarPhone"
          className={titleClasses}
          style={{ color: inlineStyles?.titleColor }}
        >
          {telephoneTitle || ''}
        </p>
      )}
      <div className="flex justify-center whitespace-nowrap md:justify-start">
        {telephoneIcon !== 'None' && (
          <div className="mr-2 flex w-6 items-center">{iconSwitcher(telephoneIcon)}</div>
        )}
        <div className="flex flex-col justify-center md:justify-start">
          <a
            className="text-primary text-base font-normal"
            href={telephoneLink?.split(' ').join('-') || telephoneLinkAlt}
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: inlineStyles?.textColor }}
            aria-label={`${t('phone')}, ${telephoneLine2 || corePlusPhoneNumber}`}
          >
            {telephoneLine2 || corePlusPhoneNumber}
          </a>
          {corePlusData?.networkDisclaimer ? (
            <p className="mt-1 max-w-48 whitespace-normal text-xs">
              {corePlusData?.networkDisclaimer}
            </p>
          ) : null}
        </div>
      </div>

      {line2Text && (
        <div className="flex justify-center whitespace-nowrap md:justify-start">
          {telephoneIcon !== 'None' && (
            <div className="mr-2 flex w-6 items-center">{iconSwitcher(line2Icon)}</div>
          )}
          <div className="flex flex-col justify-center md:justify-start">
            <a
              className="text-primary text-base font-normal"
              href={line2Link}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: inlineStyles?.textColor }}
            >
              {line2Text}
            </a>
          </div>
        </div>
      )}

      {line3Text && (
        <div className="flex justify-center whitespace-nowrap md:justify-start">
          {telephoneIcon !== 'None' && (
            <div className="mr-2 flex w-6 items-center">{iconSwitcher(line3Icon)}</div>
          )}
          <div className="flex flex-col justify-center md:justify-start">
            <a
              className="text-primary text-base font-normal"
              href={line3Link}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: inlineStyles?.textColor }}
            >
              {line3Text}
            </a>
          </div>
        </div>
      )}
    </div>
  );

  const addressSection = (
    <div
      data-testid="address"
      className={cx(
        'cp-contactBar-address',
        wrapperClasses,
        {
          'w-full md:max-w-[50%] lg:w-auto': tripAdvisorToggle,
        },
        mobileAddressState.address ? 'flex' : 'hidden',
        animations?.three
      )}
      style={{ borderColor: seperatorColor }}
    >
      {addressTitle ? (
        <p className={titleClasses} style={{ color: inlineStyles?.titleColor }}>
          {addressTitle}
        </p>
      ) : (
        <div className="block">
          <WrappedHeading header={headingLevel}>
            <span
              className="block w-full text-center text-sm font-bold leading-5 md:text-left"
              style={{
                color: inlineStyles?.titleColor,
              }}
            >
              {corePlusData?.name}
            </span>
          </WrappedHeading>
        </div>
      )}
      <HotelAddress
        addressStacked={cpAddress?.addressStacked || ''}
        linkProps={{
          anchorClassName: 'text-primary font-normal text-center md:text-left text-base',
          className: 'whitespace-pre-line',
          url: addressUrl,
          style: {
            color: inlineStyles?.textColor,
          },
        }}
        hasLink={true}
        addressLine1={cpAddress?.addressLine1 || ''}
        city={cpAddress?.city || ''}
        hotelName={corePlusData?.name || ''}
        stateCode={cpAddress?.state}
        countryCode={cpAddress?.country || ''}
      />
    </div>
  );

  const checkinSection = (
    <div
      data-testid="checkin"
      className={cx(
        'cp-contactBar-checkin',
        wrapperClasses,
        mobileAddressState.arrivaltime ? 'flex' : 'hidden',
        animations?.three
      )}
      style={{ borderColor: seperatorColor }}
    >
      {arrivalTitle && (
        <p className={titleClasses} style={{ color: inlineStyles?.titleColor }}>
          {arrivalTitle}
        </p>
      )}
      <div className="flex flex-col justify-center">
        {checkInTitle || checkInText || corePlusCheckIn ? (
          <CheckInOut
            title={checkInTitle || t('policies.checkIn')}
            copy={checkInText || corePlusCheckIn}
            inlineStyles={inlineStyles}
            className="justify-center whitespace-nowrap pb-1 md:justify-start"
          />
        ) : null}

        {checkOutTitle || checkOutText || corePlusCheckOut ? (
          <CheckInOut
            title={checkOutTitle || t('policies.checkOut')}
            copy={checkOutText || corePlusCheckOut}
            inlineStyles={inlineStyles}
            className="justify-center whitespace-nowrap md:justify-start"
          />
        ) : null}
      </div>
    </div>
  );

  const mobileSection = (
    <div className={cx('py-4 md:hidden')}>
      <div className="flex justify-center">
        {tripAdvisorCorePlusCheck && (
          <div className={animations?.one}>
            <MobileContactBarIcon
              title={tripAdvisorTitleMobileString}
              label="reviews"
              icon="Contact"
              mobileAddressState={mobileAddressState}
              setMobileAddressState={setMobileAddressState}
              textColor={inlineStyles?.textColor}
            />
          </div>
        )}

        {!hideContactInfo && (
          <div className={animations?.two}>
            <MobileContactBarIcon
              title={telephoneTitleMobileString}
              label="callus"
              icon="Phone"
              mobileAddressState={mobileAddressState}
              setMobileAddressState={setMobileAddressState}
              textColor={inlineStyles?.textColor}
            />
          </div>
        )}

        {!hideAddress && (
          <div className={animations?.three}>
            <MobileContactBarIcon
              title={addressTitleMobileString}
              label="address"
              icon="Location"
              mobileAddressState={mobileAddressState}
              setMobileAddressState={setMobileAddressState}
              textColor={inlineStyles?.textColor}
            />
          </div>
        )}
        {!hideArrivalDeparture && (
          <div className={animations?.four}>
            <MobileContactBarIcon
              title={arrivalTitleMobileString}
              label="arrivaltime"
              icon="Clock"
              mobileAddressState={mobileAddressState}
              setMobileAddressState={setMobileAddressState}
              textColor={inlineStyles?.textColor}
            />
          </div>
        )}
      </div>
      {corePlusData?.networkDisclaimer ? (
        <div className="w-full text-xs">{corePlusData?.networkDisclaimer}</div>
      ) : null}
    </div>
  );

  return (
    <section
      data-testid="contactBar"
      className={cx(
        inlineStyles?.showHide && 'hidden',
        'cp-contactBar',
        inlineStyles?.borderBottomColour && 'border-b-2',
        inlineStyles?.borderTopColour && 'border-t-2',
        'overflow-hidden',
        paddingStyles
      )}
      style={{
        backgroundColor: inlineStyles?.componentBackgroundColor,
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        borderTopColor: inlineStyles?.borderTopColour,
        borderBottomColor: inlineStyles?.borderBottomColour,
      }}
    >
      <div
        ref={animations?.ref}
        className={cx(
          'container flex flex-col flex-nowrap items-center justify-center py-4 md:flex-row md:py-8',
          tripAdvisorToggle && 'md:flex-wrap lg:flex-nowrap'
        )}
        style={{
          backgroundColor: inlineStyles?.contentBackgroundColor,
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || undefined,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
        }}
      >
        {mobileSection}

        {tripAdvisorCorePlusCheck && tripAdvisorSection}

        {!hideContactInfo && telephoneSection}

        {!hideAddress && addressSection}

        {!hideArrivalDeparture && checkinSection}
      </div>

      {modalActive ? (
        <GenericModal
          setModalActive={setModalActive}
          modalActive={modalActive}
          modalChild={
            <TripAdvisorReviewsModal tripAdvisor={tripAdvisor} inlineStyles={inlineStyles} />
          }
          modalChildStyles="bg-bg p-3 md:p-10"
          modalStyles="justify-start pt-12"
          modalSettings={modalSettings}
        />
      ) : (
        false
      )}
    </section>
  );
}

interface CheckInOutProps {
  title: string | undefined;
  copy: string | undefined;
  className: string | undefined;
  inlineStyles: any;
}

function CheckInOut({ title, copy, className, inlineStyles }: CheckInOutProps) {
  return (
    <div className={cx('flex items-center', className)}>
      <p className="text-text font-normal" style={{ color: inlineStyles?.subtitleColor }}>
        {title} <strong>{copy}</strong>
      </p>
    </div>
  );
}

interface MobileContactBarIconProps {
  title: string;
  label: string;
  icon: string;
  setMobileAddressState: any;
  mobileAddressState: any;
  textColor?: string;
}

function MobileContactBarIcon({
  title,
  label,
  icon,
  mobileAddressState,
  setMobileAddressState,
  textColor,
}: MobileContactBarIconProps) {
  const [hovered, setHovered] = useState(false);
  const iconStyles = 'mb-2 w-[30px]';
  const fillColor =
    hovered || mobileAddressState[label]
      ? 'rgb(var(--color-primary))'
      : textColor
      ? textColor
      : '#666666';
  let mobileIcon: ReactElement | null;
  switch (icon) {
    case 'Phone':
      mobileIcon = <Telephone className={iconStyles} fillColor={fillColor} />;
      break;
    case 'Contact':
      mobileIcon = <Contact className="mb-2 w-[30px]" fillColor={fillColor} />;
      break;
    case 'Location':
      mobileIcon = <Location className={iconStyles} fillColor={fillColor} />;
      break;
    case 'Clock':
      mobileIcon = <Clock className={iconStyles} fillColor={fillColor} />;
      break;
    default:
      mobileIcon = null;
  }
  return (
    <button
      data-testid="mobile-button-trigger"
      data-cy={label + 'Button'}
      className={cx(
        'address-icon text-text-alt border-b-3 relative mx-3 flex flex-col items-center justify-center pb-1 transition-all duration-300',
        {
          'text-primary border-primary': mobileAddressState[label] || hovered,
          'border-[#00000000]': !mobileAddressState[label] && !hovered,
        }
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onClick={() => {
        setMobileAddressState({
          ...mobileAddressState,
          reviews: false,
          callus: false,
          address: false,
          arrivaltime: false,
          [label]: !mobileAddressState[label],
        });
      }}
    >
      {mobileIcon}
      <p style={{ color: fillColor }}>{title}</p>
    </button>
  );
}

interface TripAdvisorReviewsModalProps {
  tripAdvisor: any;
  inlineStyles: any;
}

function TripAdvisorReviewsModal({ tripAdvisor, inlineStyles }: TripAdvisorReviewsModalProps) {
  const { t } = useTranslation();
  return (
    <div className="overflow-y-auto">
      <div className="border-b-1 flex flex-wrap items-center justify-center border-b p-4">
        <DialogTitle className="text-lg font-bold">{t('reviews')}</DialogTitle>
      </div>
      <div className="border-b-1 flex flex-wrap items-center justify-center border-b p-4">
        <div className="flex items-center">
          <img
            src={tripAdvisor?.ratingImageUrl}
            alt="Trip Advisor"
            width="164"
            className="block pr-4"
          />
        </div>
        <div className="flex flex-col justify-start">
          <div className="text-2xl font-bold">
            {tripAdvisor?.rating} {t('outOfFive')}
          </div>
          <div className="text-xs font-bold" style={{ color: inlineStyles?.subtitleColor }}>
            <Trans i18nKey="basedOnGuestReviews" count={tripAdvisor?.numReviews || 0} />
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex flex-col items-center px-4 py-8">
          <div className="text-lg font-bold">
            {tripAdvisor?.reviews?.length} {t('reviews')}
          </div>
          <div className="text-text-alt text-sm">{t('newReviewsFirst')}</div>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {tripAdvisor?.reviews.map((review: any) => (
            <div data-testid="reviews" className="" key={review?.id}>
              <div className="flex flex-row justify-between">
                <div className="text-sm font-bold">{review?.user?.username}</div>
                <div className="text-sm">
                  {review?.helpfulVotes} {t('helpfulVote')}
                </div>
              </div>
              <div className="flex flex-row text-sm">{formatReviewDate(review?.publishedDate)}</div>
              <div className="flex flex-row py-1">
                <img
                  src={review?.ratingImageUrl}
                  alt="Trip Advisor Review"
                  width="90"
                  className="block"
                />
              </div>
              <div className="py-1 text-sm font-bold leading-4">{review?.title}</div>
              <div className="pb-1 text-sm">&quot;{review?.text}&quot;</div>

              <a
                className="text-primary text-sm font-bold"
                href={review?.url}
                target="_blank"
                rel="noreferrer"
              >
                {t('readMore')}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
