import { useState, useEffect } from 'react';
import type { ArrayElement, GuestsState, Icon, mergeRooms } from '../utils';
import { getIcon, carouselImageURL } from '../utils';
import type { RoomsCTAProps } from './rooms-cta';
import { RoomsCTA } from './rooms-cta';
import { HandleAnimations } from '../../functions/helper';
import { CroppedImage } from '../../cropped-image/cropped-image';
import type { IBrandDetails } from '@curated-property/utils';
import {
  appliedCloudinaryParams,
  getBrandDetails,
  useInitializeConductrics,
} from '@curated-property/utils';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { ThreeSixtyTour } from '@curated-property/icon-list';
import { getConductricsSelection, sendReward, Status } from '@dx-ui/framework-conductrics';
import { RoomDetailsModal } from './room-details-modal';
import { RoomVirtualTourModal } from './room-virtual-tour-modal';

export function RoomCard({
  ind,
  room,
  ctyhocn,
  loading,
  status,
  queryData,
  isError,
  pricingEnabled,
  hideLeadRate,
  rate,
  icons,
  inlineStyles,
  textAlignment,
  currency,
  resEnabled,
  associatedHotels,
  ctaData,
  guests,
  hasPrices,
  dates,
  enableHHR,
}: {
  ind: number;
  room: ArrayElement<ReturnType<typeof mergeRooms>>;
  ctyhocn: string;
  loading: boolean;
  status?: string;
  queryData?: any;
  isError: boolean;
  pricingEnabled: boolean;
  hideLeadRate: boolean;
  rate: string;
  icons?: Icon[];
  inlineStyles?: any;
  textAlignment?: any;
  currency?: any;
  resEnabled?: boolean;
  associatedHotels?: string[];
  guests?: GuestsState;
  ctaData?: RoomsCTAProps;
  roomDetailsButton?: React.ReactNode;
  hasPrices?: boolean;
  dates?: { arrival: string; departure: string };
  enableHHR?: boolean;
}) {
  const { t } = useTranslation();
  const [detailsButtonHover, setDetailsButtonHover] = useState(false);
  const [conductricsData, setConductricsData] = useState('');
  const noPriceRoom = !loading && !isError && !rate;
  const dxRoomImage = carouselImageURL(room?.carousel[0]?.variants, 'md');
  const brandDetails: IBrandDetails = getBrandDetails(room?.brandName?.toLowerCase());

  const [roomDetailsModalIsOpen, setRoomDetailsModalIsOpen] = useState(false);
  const [roomVirtualTourModalIsOpen, setRoomVirtualTourModalIsOpen] = useState(false);

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useInitializeConductrics();

  useEffect(() => {
    const fetchConductics = async function () {
      await getConductricsSelection('a-aBiTtEIItwSa', Status.OK).then((res) => {
        setConductricsData(res?.items?.[0]?.c);
      });
    };
    void fetchConductics();
  }, []);

  const feeStringColor = inlineStyles?.feeTransparencyDefaultColor;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-500',
    delayThree: 'delay-700',
    delayFour: 'delay-1000',
  });

  const defaultTileBackground = enableHHR ? '' : '#e9e9e9';

  return (
    <div
      ref={animations?.ref}
      data-testid="roomTypeCard"
      data-element-id="tile-wrapper"
      data-ctyhocn={room?.hotelCtyhocn || ctyhocn}
      data-id={room.roomTypeCode}
      className={cx(
        `relative ${!inlineStyles?.disableDropShadow ? 'shadow-lg' : ''} flex flex-col`,
        animations?.one
      )}
      style={{
        backgroundImage: inlineStyles?.contentBackgroundImage
          ? `url('${appliedCloudinaryParams(inlineStyles?.contentBackgroundImage?.sourceUrl)}')`
          : null,
        backgroundColor: inlineStyles?.contentBackgroundColor || defaultTileBackground,
        backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
        textAlign: textAlignment,
        justifyContent: inlineStyles?.textAlignment,
        transitionDelay: `${ind + 2}00ms`,
      }}
    >
      <div>
        {room.image?.sourceUrl ? (
          // wordpress image
          <CroppedImage
            src={room.image?.sourceUrl}
            alt={room.image?.altText}
            width="528"
            height="316"
            key={!noPriceRoom ? '1' : '2'}
            className="w-full"
            layout="responsive"
          />
        ) : (
          // core+ image
          <div className="relative">
            {dxRoomImage !==
            'https://assets.hiltonstatic.com/images/v1583957952/no-image/no-image.png' ? (
              <img className="w-full" src={dxRoomImage} alt="" />
            ) : (
              <div className="relative">
                <div className="absolute flex size-full items-center justify-center text-lg">
                  <p>No image available</p>
                </div>
                <CroppedImage
                  src=""
                  alt=""
                  width="528"
                  height="316"
                  key={!noPriceRoom ? '1' : '2'}
                  className="w-full"
                  layout="responsive"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={cx('text-text flex w-full flex-1 flex-col p-4 text-left transition-all')}
        style={{
          backgroundColor: inlineStyles?.activeTileColor,
        }}
      >
        <div className="flex justify-between">
          <h2
            className={cx(
              'w-auto basis-4/5 text-balance font-bold leading-tight',
              {
                'text-base sm:text-lg': room?.icon?.sourceUrl || room?.brandName,
                'text-2xl': !room?.icon?.sourceUrl && !room?.brandName,
              },
              animations?.two
            )}
            style={{
              color: inlineStyles?.titleColor,
            }}
          >
            {room.roomTypeName}
          </h2>
          {room?.icon?.sourceUrl || (room?.brandName && brandDetails?.brandLogoAlt != null) ? (
            <div
              data-testid="roomIcon"
              className={cx('flex basis-1/5 items-center justify-center py-2', animations?.three)}
              style={{ fill: brandDetails?.brandColour || null }}
            >
              {room?.icon?.sourceUrl ? (
                <img
                  data-testid="roomIconImage"
                  src={room?.icon?.sourceUrl}
                  alt={room?.icon?.altText}
                  style={{
                    width: room?.iconWidth ? room?.iconWidth + 'px' : '70px',
                    maxWidth: '120px',
                  }}
                />
              ) : (
                <>
                  <div aria-hidden="true" data-testid="associatedHotelIcon">
                    {brandDetails?.brandLogoAlt}
                  </div>
                  <p className="sr-only">{room?.brandName}</p>
                </>
              )}
            </div>
          ) : null}
        </div>
        <div className="border-overlay mt-3 border-t" />
        <div className="flex flex-1 flex-col content-between justify-between py-3">
          <div>
            {room?.virtualMedia?.length > 0 ? (
              <RoomVirtualTourModal
                assetUrl={room.virtualMedia[0].assetUrl}
                ctaData={ctaData}
                modalActive={roomVirtualTourModalIsOpen}
                setModalActive={setRoomVirtualTourModalIsOpen}
                dialogTrigger={
                  <button
                    data-testid="btn-virtual-tours"
                    className="btn btn-primary btn-virtual-tours fill-current px-2 py-0 !text-xs"
                    onClick={() => setRoomVirtualTourModalIsOpen(true)}
                  >
                    <span className="flex items-center gap-2">
                      <ThreeSixtyTour fillColor="fill-current" className="mt-1 w-8" />
                      <span>{t('tourThisRoom')}</span>
                    </span>
                  </button>
                }
              />
            ) : null}
          </div>
          <div
            className={cx('block pb-2 text-left', animations?.three)}
            data-element-id="room-feature-list"
          >
            {icons?.map((icon) => {
              const { name, Icon } = getIcon(icon);
              if (!(name || Icon) || icon === undefined) {
                return null;
              }
              return (
                <div
                  key={name}
                  className="my-2 flex flex-row items-center leading-normal"
                  style={{
                    color: inlineStyles?.textColor,
                  }}
                >
                  <Icon
                    className="size-7"
                    style={{
                      stroke: inlineStyles?.textColor,
                      color: inlineStyles?.textColor,
                    }}
                    fillColor={inlineStyles?.textColor}
                    fill={inlineStyles?.textColor}
                  />
                  <div data-testid="roomItemDesc" className="ms-2 text-center">
                    {name}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={cx(`cp-roomTypes-ctas flex flex-wrap items-center gap-2`, animations?.four)}
          >
            {resEnabled || associatedHotels?.length > 0 ? (
              <RoomsCTA
                {...ctaData}
                className="mt-1 w-auto"
                inlineStyles={inlineStyles}
                currency={currency}
                onClick={() => sendReward('EngageCheckRatesCTA')}
              />
            ) : null}
            <RoomDetailsModal
              ctyhocn={ctyhocn}
              room={room}
              icons={icons}
              ctaData={ctaData}
              guests={guests}
              hasPrices={hasPrices}
              rate={rate}
              textAlignment={textAlignment}
              arrivalDate={dates?.arrival}
              departureDate={dates?.departure}
              currency={currency}
              resEnabled={resEnabled}
              inlineStyles={{
                ...inlineStyles,
                roomDetailsModalBackgroundColor: inlineStyles?.modalBackgroundColor,
                roomDetailsModalTextColor: inlineStyles?.modalTextColor,
                roomDetailsModalHeadingColor: inlineStyles?.modalHeadingColor,
                roomDetailsModalAccentColor: inlineStyles?.modalAccentColor,
                roomDetailsModalIconColor: inlineStyles?.modalIconColor,
              }}
              modalActive={roomDetailsModalIsOpen}
              setModalActive={setRoomDetailsModalIsOpen}
              dialogTrigger={
                <button
                  data-testid="detailsBtn"
                  className="text-primary btn btn-primary-outline mt-1 w-auto py-2 rtl:ml-3  rtl:mr-0"
                  onClick={() => {
                    setRoomDetailsModalIsOpen(!roomDetailsModalIsOpen);
                    sendReward('click-room-details');
                  }}
                  style={{
                    color: detailsButtonHover
                      ? inlineStyles?.activeViewDetailsTextColor
                      : inlineStyles?.viewDetailsTextColor,
                    backgroundColor: detailsButtonHover
                      ? inlineStyles?.activeViewDetailsBackgroundColor
                      : inlineStyles?.viewDetailsBackgroundColor,
                    borderColor: detailsButtonHover
                      ? inlineStyles?.activeViewDetailsBorderColor
                      : inlineStyles?.viewDetailsBorderColor,
                  }}
                  onMouseOver={() => setDetailsButtonHover(true)}
                  onMouseOut={() => setDetailsButtonHover(false)}
                >
                  <span className="flex items-center">
                    {t('moreDetails')}
                    <span className="sr-only">
                      {t('forRoomTitle', { roomTitle: room.roomTypeName })}
                    </span>
                  </span>
                </button>
              }
            />
          </div>
          {room?.moreRatesFromRate?.feeTransparencyIndicator && (
            <div
              data-testid="feeTransparency"
              data-element-id="fee-transparency"
              className={cx('text-primary my-0 pt-4 text-xs lg:text-sm', animations?.four)}
              style={{ color: feeStringColor }}
              dangerouslySetInnerHTML={{
                __html: room?.moreRatesFromRate?.serviceChargeDetails || '',
              }}
            />
          )}
        </div>
      </div>
      <div className="absolute right-0 top-8">
        {isClient && pricingEnabled && !isError && !hideLeadRate && conductricsData !== 'B' && (
          <FromPricingOverlay
            loading={loading}
            status={status}
            queryData={queryData}
            fromRate={rate}
            noPriceRoom={noPriceRoom}
            inlineStyles={inlineStyles}
            currency={currency}
          />
        )}
      </div>
    </div>
  );
}

interface FromPricingOverlayProps {
  loading?: boolean;
  status?: string;
  queryData?: any;
  fromRate?: string;
  noPriceRoom?: boolean;
  inlineStyles?: any;
  currency?: any;
}

export function FromPricingOverlay({
  loading,
  status,
  queryData,
  fromRate,
  noPriceRoom,
  inlineStyles,
  currency,
}: FromPricingOverlayProps) {
  const { t } = useTranslation();
  return (
    <div
      data-testid="pricingOverlay"
      data-element-id="pricing-overlay"
      className="bg-primary text-bg px-5 py-3 leading-tight"
      style={{ backgroundColor: inlineStyles?.rateBackgroundColor || null }}
    >
      <span className="sr-only">{`${t('from')} ${
        loading
          ? t('loadingPrice')
          : fromRate?.replace(currency?.currencyCode, currency?.description)
      }`}</span>
      {(loading || fromRate) && (
        <span
          className="block text-right text-base"
          style={{ color: inlineStyles?.rateFromColor || null }}
          aria-hidden={true}
        >
          {t('from')}
        </span>
      )}
      <p
        className={cx('block text-right font-bold', {
          'text-2xl': !loading,
          'text-sm animate-pulse': loading,
        })}
        style={{ color: inlineStyles?.rateFigureColor || null }}
        aria-hidden={true}
      >
        {loading ? t('loadingPrice') : fromRate}
      </p>
      {noPriceRoom && (
        <div style={{ color: inlineStyles?.rateErrorColor || null }}>
          {status === 'success' && !queryData
            ? t('unableToRetrievePricing')
            : t('roomNotAvailable')}
        </div>
      )}
    </div>
  );
}
