import type { KeyboardEvent, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_Padder, GIS_TextAlignment } from './functions/global-instance-styles';
import { ImageModalSeries } from './includes/image-modal-series';
import { CroppedImage } from './cropped-image/cropped-image';
import {
  WrappedSubtitle,
  useLanguage,
  isReducedMotion,
  appliedCloudinaryParams,
  sanitize,
} from '@curated-property/utils';
import { useTranslation } from 'next-i18next';
import { adobeEventTracking } from './global/adobe-analytics';
import { matchValuesToFlexAlignment, HandleAnimations } from './functions/helper';
import galleryWallStyles from './css/imageGalleryWall.module.css';

interface ModalSettings {
  modalBackgroundOpacity?: number;
  modalBackgroundColour?: string;
  modalCloseButtonBackgroundColour?: string;
  modalCloseButtonIconColour?: string;
}

export interface ImageGalleryWallProps {
  galleryTitle?: string;
  gallerySubtitle?: string;
  columnCount?: number;
  imageVerticalAlignment?: string;
  repeater?: {
    title?: string;
    image?: {
      sourceUrl?: string;
      altText?: string;
    };
    selectOrientation?: string;
    description?: string;
    imageHeight?: string;
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  modalSettings?: ModalSettings;
  enableHHR?: boolean;
}

interface ActiveImageState {
  id: number;
  lastFocusedElement: HTMLDivElement | null;
}

export function ImageGalleryWall({
  galleryTitle,
  gallerySubtitle,
  columnCount,
  imageVerticalAlignment,
  repeater,
  globalStyles,
  instanceStyles,
  modalSettings,
  enableHHR,
}: ImageGalleryWallProps) {
  const [modalActive, setModalActive] = useState(false);
  const [activeImage, setActiveImage] = useState<ActiveImageState>(null);
  const lang = useLanguage();

  function open(e, id) {
    if (!repeater) {
      return;
    }
    setModalActive(true);
    setActiveImage({
      id,
      lastFocusedElement: e?.target,
    });
  }

  useEffect(() => {
    if (!modalActive) {
      activeImage?.lastFocusedElement?.focus();
    }
  }, [activeImage, modalActive]);

  function close() {
    if (!repeater) {
      return;
    }
    setModalActive(false);
  }

  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const slideAnimation: string = inlineStyles?.sliderAnimations || 'fade';
  const zoomOnHover = inlineStyles?.zoomOnHover === 'show' && !isReducedMotion;

  const headingTextDefaultAlign = GIS_TextAlignment(inlineStyles?.textAlignment) || 'inherit';
  const headingLanguageAlign = lang === 'ar' ? 'right' : headingTextDefaultAlign;

  // lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6
  const colCountClasses =
    columnCount && columnCount > 3
      ? `sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-${columnCount}`
      : 'sm:grid-cols-2 md:grid-cols-3';
  const vertAlignment = `items-${matchValuesToFlexAlignment(imageVerticalAlignment)}`;
  const containerWidth = matchValuesToContainerWidth(inlineStyles?.containerMaxWidth);
  const hideAnimations = inlineStyles?.hideAnimations !== 'show';
  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : headingLanguageAlign === 'center'
      ? '-translate-y-12'
      : headingLanguageAlign === 'right'
      ? 'translate-x-8'
      : '-translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
  });

  // Set title/subtitle color default as inherit for non Hilton Hotel Resorts sites
  const titleColorDefault = enableHHR ? '' : 'inherit';
  const subtitleColorDefault = enableHHR ? '' : 'inherit';

  return (
    <section
      className={cx(
        !enableHHR && 'bg-bg-inverse text-text-inverse',
        paddingStyles,
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : '',
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || '',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || '',
        backgroundColor: inlineStyles?.componentBackgroundColor || '',
      }}
      data-element-id="image-gallery-wall-wrapper"
    >
      <div
        className={
          inlineStyles?.containerMaxWidth
            ? `mx-auto w-full px-2 md:px-4 2xl:px-0 ${containerWidth}`
            : 'container'
        }
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : '',
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || '',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || '',
          backgroundColor: inlineStyles?.contentBackgroundColor || '',
        }}
      >
        {galleryTitle || gallerySubtitle ? (
          <div ref={animations?.ref} className="mb-8 w-full">
            {galleryTitle ? (
              <h2
                className={cx(
                  'font-headline text-4xl leading-none',
                  !enableHHR && 'font-black',
                  animations?.one
                )}
                style={{
                  textAlign: headingLanguageAlign,
                  color: inlineStyles?.titleColor || titleColorDefault,
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(galleryTitle || ''),
                }}
              />
            ) : (
              ''
            )}
            {gallerySubtitle ? (
              <WrappedSubtitle>
                <p
                  className={cx(
                    'font-headline my-4 text-center text-2xl leading-none',
                    !enableHHR && 'font-black',
                    animations?.two
                  )}
                  style={{
                    textAlign: headingLanguageAlign,
                    color: inlineStyles?.subtitleColor || subtitleColorDefault,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(gallerySubtitle || ''),
                  }}
                />
              </WrappedSubtitle>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}

        <div
          className={cx(
            imageVerticalAlignment === 'bottom' && galleryWallStyles.flip,
            `block w-full sm:grid sm:gap-2 ${colCountClasses} ${vertAlignment}`
          )}
        >
          {repeater?.map((item, key) => {
            return (
              <ImageWallGalleryItem
                key={key}
                id={key}
                image={item?.image?.sourceUrl}
                altText={item?.image?.altText}
                orientation={item?.selectOrientation}
                open={(e) => open(e, key)}
                modalActive={modalActive}
                galleryTitle={galleryTitle}
                imageCount={repeater?.length}
                imageHeight={item?.imageHeight}
                imageVerticalAlignment={imageVerticalAlignment}
                zoomOnHover={zoomOnHover}
                hideAnimations={hideAnimations}
                animationDirection={inlineStyles?.animationDirection}
              />
            );
          })}
          <ImageModalSeries
            title={galleryTitle}
            activeImage={activeImage?.id}
            images={repeater}
            close={close}
            active={modalActive}
            modalBackgroundColour={
              inlineStyles?.galleryWallBackgroundColour || modalSettings?.modalBackgroundColour
            }
            modalBackgroundOpacity={
              inlineStyles?.galleryWallBackgroundOpacity || modalSettings?.modalBackgroundOpacity
            }
            controlsBackgroundColour={
              inlineStyles?.galleryWallControlsBackgroundColour ||
              modalSettings?.modalCloseButtonBackgroundColour
            }
            controlsIconColour={
              inlineStyles?.galleryWallControlsIconColour ||
              modalSettings?.modalCloseButtonIconColour
            }
            captionColour={inlineStyles?.galleryWallCaptionColour}
            slideAnimation={slideAnimation}
          />
        </div>
      </div>
    </section>
  );
}

interface ImageWallGalleryItemProps {
  id: number;
  image?: string;
  altText?: string;
  open: (e, id) => void;
  modalActive?: boolean;
  orientation?: string;
  portraitModal?: boolean;
  faceDetection?: boolean;
  galleryTitle?: string;
  imageHeight?: string;
  imageCount?: number;
  imageVerticalAlignment?: string;
  zoomOnHover?: boolean;
  hideAnimations?: boolean;
  animationDirection?: string;
}

const ImageWallGalleryItem = ({
  id,
  image,
  altText,
  open,
  modalActive,
  orientation,
  imageHeight,
  galleryTitle,
  imageCount,
  imageVerticalAlignment,
  zoomOnHover,
  hideAnimations,
  animationDirection,
}: ImageWallGalleryItemProps) => {
  const { t } = useTranslation();

  let portraitHeight = 660;
  switch (imageHeight) {
    case 'large':
      portraitHeight = 740;
      break;
    case 'small':
      portraitHeight = 580;
      break;
    default:
      portraitHeight = 660;
      break;
  }

  const handleOpenModal = (e: KeyboardEvent | MouseEvent) => {
    if ((e.type === 'keydown' && (e as KeyboardEvent).code === 'Enter') || e.type === 'click') {
      e.preventDefault();
      open(e, id);
      adobeEventTracking({
        carouselNumber: `${id + 1}:${imageCount}`,
        sectionHeading: galleryTitle,
        actionDetail: 'Carousel',
        interactionName: 'propertyGalleryCarousel',
      });
    }
  };

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-200',
  });

  return (
    <div
      key={id}
      className={cx(
        'relative my-2 flex overflow-hidden p-0 sm:my-0',
        imageVerticalAlignment === 'bottom' ? galleryWallStyles.flip : '',
        orientation === 'landscape' ? 'row-span-2' : 'row-span-3'
      )}
      data-testid="gallery-modal-trigger"
      aria-label={t('openGallery') + (galleryTitle || '')}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleOpenModal(e)}
      onClick={(e) => handleOpenModal(e)}
      aria-expanded={modalActive ? true : false}
    >
      <div className={cx('pointer-events-none', animations?.one)} ref={animations?.ref}>
        <CroppedImage
          key={id}
          src={image || ''}
          alt={altText}
          width="660"
          height={orientation === 'landscape' ? 440 : portraitHeight}
          className={cx('transition-all duration-500', {
            'hover:scale-125': zoomOnHover,
          })}
        />
      </div>
    </div>
  );
};

function matchValuesToContainerWidth(width?: string) {
  switch (width) {
    case '1280':
      return 'max-w-7xl';
    case '1440':
      return 'max-w-1440';
    case '1680':
      return 'max-w-1680';
    case '1920':
      return 'max-w-1920';
    case '2560':
      return 'max-w-2560';
    default:
      return '';
  }
}
