import type { ACFAnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import type { CropProps } from './cropped-image/cropped-image';
import { customLoader, CroppedImage } from './cropped-image/cropped-image';
import {
  makeRandomInt,
  isReducedMotion,
  useURLParamState,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { HeadingStyle } from './includes/heading-style';
import { CopyTable } from './copy-table';
import { CategoryFilters } from './includes/category-filters';
import {
  HandleAnimations,
  iconmapper,
  HandleWYSIWYGContent,
  useMediaQuery,
} from './functions/helper';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_Padder, GIS_TextAlignment } from './functions/global-instance-styles';
import { Close } from '@curated-property/icon-list';
import { ImageSlider } from './image-slider/image-slider';
import cx from 'classnames';
import contentStyles from './css/contentStyle.module.css';
import { useTranslation } from 'next-i18next';
import { AccordionAddOn } from './accordion-add-on/accordion-add-on';
import type {
  AccordionAddOnPanelProps,
  AccordionAddOnProps,
} from './accordion-add-on/accordion-add-on.types';
import { Icon as OscIcon } from '@dx-ui/osc-icon';

type IconKeyType = keyof typeof iconmapper;

interface TableData {
  title?: string;
  copy?: string;
}

interface ImageProps {
  sourceUrl?: string;
  altText?: string;
}

interface CategoryProps {
  name?: string;
  slug?: string;
}

export interface MultiColumnProps {
  header?: string;
  headerCopy?: string;
  headingValue?: string;
  columnCount?: number;
  imageSize?: string;
  tileFilters?: boolean;
  filterCategories?: Array<CategoryProps>;
  repeater:
    | {
        accordionPanel?: AccordionAddOnPanelProps[];
        title?: string;
        title_noTx?: string;
        copy?: string;
        iconList?: IconKeyType | string;
        iconLabel?: string;
        image?: ImageProps;
        images?: Array<ImageProps>;
        enableCropping?: boolean;
        cropType?: string;
        xPosition?: string;
        xPositionAdvanced?: string;
        yPosition?: string;
        yPositionAdvanced?: string;
        cropWidth?: string;
        cropHeight?: string;
        autoPosition?: boolean;
        category?: CategoryProps;
        tableData?: Array<TableData>;
        buttons?: Array<ACFAnchorProps>;
        tileImageLabel?: string;
        showHide?: boolean;
      }[]
    | undefined;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  buttons?: Array<ACFAnchorProps>;
  urlQueryParamName?: string;
}

export function MultiColumn({
  header,
  headerCopy,
  headingValue,
  columnCount,
  buttons,
  imageSize,
  tileFilters,
  filterCategories,
  repeater,
  globalStyles,
  instanceStyles,
  urlQueryParamName,
}: MultiColumnProps) {
  const { t } = useTranslation();
  repeater = repeater?.filter((item) => !item?.showHide);
  const { val: rawActiveCategory, changeParamValue: changeActiveCategory } = useURLParamState({
    paramOptions: filterCategories?.map((item) => item?.slug),
    defaultParamOption: '',
    urlQueryParamName: urlQueryParamName || '',
  });
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);

  let alignItems: string;

  switch (inlineStyles?.textAlignment) {
    case 'flex-start':
      alignItems = 'justify-start';
      break;
    case 'center':
      alignItems = 'justify-center';
      break;
    case 'flex-end':
      alignItems = 'justify-end';
      break;
    default:
      alignItems = 'flex-start';
  }

  let hasImages = false;
  repeater?.forEach((item) => {
    if (item.images || item.image) hasImages = true;
  });

  const colCount = columnCount || 4;

  let activeCategory = '';

  if (rawActiveCategory instanceof Array) {
    if (rawActiveCategory.length > 0) {
      activeCategory = rawActiveCategory[0];
    }
  } else {
    activeCategory = rawActiveCategory;
  }

  activeCategory = activeCategory === undefined ? '' : activeCategory;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : inlineStyles?.textAlignment === 'center'
      ? '-translate-y-12'
      : inlineStyles?.textAlignment === 'flex-end'
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
  });

  const accordionAddOnStyles = {
    accordionBorderColor: inlineStyles.accordionBorderColor,
    accordionIconColor: inlineStyles.accordionIconColor,
    accordionIconColorActive: inlineStyles.accordionIconColorActive,
    accordionPanelBackgroundColor: inlineStyles.accordionPanelBackgroundColor,
    accordionPanelLabelColor: inlineStyles.accordionPanelLabelColor,
    accordionRowLabelColor: inlineStyles.accordionRowLabelColor,
    accordionRowTextColor: inlineStyles.accordionRowTextColor,
  };

  const lgGridColCount = `lg:grid-cols-${colCount}`;

  return (
    <section
      data-testid="multiColumnTiles"
      className="cp-multiColTiles"
      style={{
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        textAlign: textAlignment,
      }}
    >
      <div
        className={paddingStyles}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage?.sourceUrl,
                inlineStyles?.componentBackgroundRepeat
              )})`
            : undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
        }}
      >
        <div ref={animations?.ref} className="container">
          {(header || headerCopy) && (
            <div className="mb-12 lg:mx-4">
              {header && (
                <HeadingStyle
                  text={header}
                  type={headingValue || 'h2'}
                  styledAs="h2"
                  className={cx('mb-4', animations?.one)}
                  textColorInline={inlineStyles?.headerTitleColour}
                />
              )}
              {headerCopy && (
                <div
                  data-testid="multiColumnTilesHeaderCopy"
                  className={cx('flex', alignItems, animations?.two)}
                >
                  <div
                    className={cx(
                      'w-full max-w-4xl text-base lg:text-lg',
                      contentStyles.paragraphStyle,
                      contentStyles.listStyle
                    )}
                    style={{ color: inlineStyles?.headerCopyColour }}
                    dangerouslySetInnerHTML={{
                      __html: HandleWYSIWYGContent(headerCopy, inlineStyles?.headerCopyColour),
                    }}
                  />
                </div>
              )}
              <div className={cx('mt-4', animations?.three)}>
                {buttons?.map((link, key) => {
                  return (
                    <AnchorLink
                      key={key}
                      url={link?.link?.url}
                      title={link?.link?.title}
                      target={link?.link?.target}
                      buttonStyle={link?.buttonStyle ?? 'primary-outline'}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {tileFilters && (
            <div
              className={animations?.four}
              style={{ backgroundColor: inlineStyles?.filterBackgroundColour }}
            >
              <CategoryFilters
                allCatsText={t('all')}
                activeCat={activeCategory}
                setActiveCat={changeActiveCategory}
                cats={filterCategories}
                styleOptions={{
                  filterActiveTextColour: inlineStyles?.filterActiveTextColour,
                  filterTextColour: inlineStyles?.filterTextColour,
                }}
              />
            </div>
          )}
          <div className={`${lgGridColCount} grid grid-cols-1 gap-4 md:grid-cols-2`}>
            {repeater?.map((item, key) => {
              if (item?.category?.slug === activeCategory || activeCategory === '') {
                const randInt = makeRandomInt();
                return (
                  <MultiColumnItem
                    accordionPanel={item?.accordionPanel}
                    accordionAddOnStyles={accordionAddOnStyles}
                    key={`multiColumnItem-${randInt}`}
                    ind={key}
                    headerExists={header ? true : false}
                    headingValue={headingValue}
                    title={inlineStyles?.headingsNoTranslate ? item?.title_noTx : item?.title}
                    copy={item?.copy}
                    iconLabel={item?.iconLabel}
                    iconList={item?.iconList}
                    tableData={item?.tableData}
                    image={item?.image}
                    images={item?.images}
                    imageSize={imageSize}
                    cropping={{
                      enableCropping: item?.enableCropping,
                      cropType: item?.cropType,
                      xPosition: item?.xPosition,
                      xPositionAdvanced: item?.xPositionAdvanced,
                      yPosition: item?.yPosition,
                      yPositionAdvanced: item?.yPositionAdvanced,
                      cropWidth: item?.cropWidth,
                      cropHeight: item?.cropHeight,
                      autoPosition: item?.autoPosition,
                    }}
                    buttons={item?.buttons}
                    inlineStyles={inlineStyles}
                    hasImages={hasImages}
                    colCount={colCount}
                    tileImageLabel={item?.tileImageLabel}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

interface ItemProps extends AccordionAddOnProps {
  ind: number;
  headerExists?: boolean;
  headingValue?: string;
  title?: string;
  copy?: string;
  iconList?: string;
  iconLabel?: string;
  tableData?: Array<TableData>;
  image?: ImageProps;
  images?: Array<ImageProps>;
  cropping?: CropProps;
  imageSize?: string;
  buttons?: any;
  inlineStyles?: any;
  hasImages?: boolean;
  colCount: number;
  tileImageLabel?: string;
}

export function MultiColumnItem({
  accordionPanel,
  accordionAddOnStyles,
  ind,
  headerExists,
  headingValue,
  title,
  copy,
  iconLabel,
  iconList,
  tableData,
  image,
  images,
  cropping,
  imageSize,
  buttons,
  inlineStyles,
  hasImages,
  colCount,
  tileImageLabel,
}: ItemProps) {
  const tableDataArrayMap: { title?: string; text?: string }[] = [];
  const imagesArrayMap: { url: string; alt: string }[] = [];
  tableData?.forEach((row) => {
    tableDataArrayMap.push({
      title: row?.title,
      text: row?.copy,
    });
  });
  images?.forEach((row) => {
    imagesArrayMap.push({
      url: row?.sourceUrl || '',
      alt: row?.altText || '',
    });
  });

  const sizeArray =
    colCount > 2 ? splitByX(imageSize || '') : increaseImageSize(splitByX(imageSize || ''), 2);

  let imageSourceUrl = image?.sourceUrl || '';
  const sizeRegex = /w_\d+,h_\d+\//;
  if (imageSourceUrl && imageSourceUrl.search(sizeRegex) >= 0) {
    imageSourceUrl = imageSourceUrl.replace(sizeRegex, '');
  }
  const iconMap = iconmapper();
  const Icon = iconMap[(iconList as keyof typeof iconMap) || ''];
  const zoomOnHover = inlineStyles?.zoomOnHover === 'show' && !isReducedMotion;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
  });

  const isMobile = useMediaQuery(768);

  // Tile Labels
  const imageLabel = tileImageLabel ? (
    <div
      className={cx(
        'cp-tile-label absolute end-0 top-[60px] z-10 w-fit',
        !inlineStyles?.tileImageLabelBackgroundColor && 'bg-primary brand-wa:bg-secondary',
        !inlineStyles?.tileImageLabelTextColor && 'brand-ch:text-black text-white'
      )}
      style={{
        backgroundColor: inlineStyles?.tileImageLabelBackgroundColor,
        color: inlineStyles?.tileImageLabelTextColor,
      }}
    >
      <div className={cx('flex items-center p-2 text-sm sm:p-4 sm:text-base')}>
        <span
          style={{
            color:
              inlineStyles?.tileImageLabelIconColor || inlineStyles?.tileImageLabelTextColor || '',
          }}
          className="cp-tile-icon-wrapper"
        >
          <OscIcon name="currency" size={isMobile ? 'sm' : 'md'} />
        </span>
        <span className="block ps-1.5">{tileImageLabel}</span>
      </div>
    </div>
  ) : (
    ''
  );

  return (
    <div
      ref={animations?.ref}
      data-element-id="multi-column-tile"
      className={cx(
        'bg-bg-alt flex h-full flex-col [&_ol]:my-4 [&_ol]:list-outside [&_ol]:list-decimal [&_ol]:pl-5 [&_ul]:my-4 [&_ul]:list-outside [&_ul]:list-disc [&_ul]:pl-3',
        animations?.one
      )}
      style={{
        backgroundColor: inlineStyles?.contentBackgroundColor || null,
        transitionDelay: `${ind + 2}00ms`,
      }}
    >
      <div>
        <div className="relative">
          {imageLabel}
          {images ? (
            <ImageSlider
              images={imagesArrayMap}
              controlLayout="compact"
              sectionHeading={title}
              imageSize={{
                w: sizeArray?.width,
                h: sizeArray?.height,
              }}
              styleOptions={{
                uiBackgroundColour: inlineStyles?.uiBackgroundColour,
                uiControlsColour: inlineStyles?.uiControlsColour,
                sliderAnimations: inlineStyles?.sliderAnimations,
                zoomOnHover,
              }}
            />
          ) : hasImages ? (
            <div className="relative overflow-hidden">
              {imageLabel}
              <CroppedImage
                src={imageSourceUrl}
                loader={() => {
                  return customLoader({
                    src: imageSourceUrl,
                    width: sizeArray?.width,
                    height: sizeArray?.height,
                    crop: cropping?.enableCropping || false,
                    cropType: cropping?.cropType || '',
                    cropHeight: cropping?.cropHeight || '',
                    cropWidth: cropping?.cropWidth || '',
                    xPosition: cropping?.xPosition || '',
                    xPositionAdvanced: cropping?.xPositionAdvanced || '',
                    yPosition: cropping?.yPosition || '',
                    yPositionAdvanced: cropping?.yPositionAdvanced || '',
                  });
                }}
                alt={image?.altText}
                width={sizeArray?.width}
                height={sizeArray?.height}
                objectFit="cover"
                layout="responsive"
                className={cx('block transition-all duration-500', {
                  'hover:scale-125': zoomOnHover,
                })}
              />
            </div>
          ) : null}
        </div>
        <div
          className="p-4"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage?.sourceUrl,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || 'top left',
          }}
        >
          {title && (
            <HeadingStyle
              text={title}
              type={
                !headerExists && headingValue
                  ? headingValue
                  : !headerExists && !headingValue
                  ? 'h2'
                  : headerExists && headingValue === 'h4'
                  ? 'h5'
                  : headerExists && headingValue === 'h3'
                  ? 'h4'
                  : 'h3'
              }
              styledAs="h3"
              className={cx({
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              })}
              textColorInline={inlineStyles?.titleColor}
            />
          )}
          {iconLabel && (
            <div className="inline-block">
              <div className="mt-1.5 flex items-center">
                <span>
                  {Icon ? (
                    <Icon className="size-8" fillColor={inlineStyles?.subtitleColor} />
                  ) : (
                    <Close className="size-8" fillColor={inlineStyles?.subtitleColor} />
                  )}
                </span>
                <span
                  className="pl-1 text-lg font-bold"
                  style={{ color: inlineStyles?.subtitleColor }}
                >
                  {iconLabel}
                </span>
              </div>
            </div>
          )}
          {copy || iconLabel ? (
            <div
              className={cx(
                'my-4 text-base lg:text-lg',
                contentStyles.paragraphStyle,
                contentStyles.listStyle
              )}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
              }}
              style={{
                color: inlineStyles?.textColor,
              }}
            />
          ) : null}

          {tableData && (
            <CopyTable repeater={tableDataArrayMap} textColor={inlineStyles?.textColor} />
          )}
        </div>
      </div>
      {buttons && (
        <div className="mt-2 px-4 pb-6">
          {buttons?.map((link: ACFAnchorProps, key: number) => {
            return (
              <div key={key} className="me-2 inline-block">
                <AnchorLink
                  url={link?.link?.url}
                  title={link?.link?.title}
                  target={link?.link?.target}
                  buttonStyle={link?.buttonStyle ?? 'primary'}
                  noHorizontalMargin={true}
                />
              </div>
            );
          })}
        </div>
      )}
      {accordionPanel && accordionPanel[0]?.panelLabel ? (
        <AccordionAddOn
          accordionPanel={accordionPanel}
          accordionAddOnStyles={accordionAddOnStyles}
          className="p-4"
        />
      ) : null}
    </div>
  );
}

function splitByX(sizes: string) {
  const sizeArray = sizes.split('x');
  return {
    width: parseInt(sizeArray[0]),
    height: parseInt(sizeArray[1]),
  };
}

/*
 - The image sizes have worked well for this component, until we added the option for 2 columns
 - Now we need to increase image size to prevent them from becoming blurry when the tiles are larger
*/

interface ImageSizeProps {
  width: number;
  height: number;
}

function increaseImageSize(sizeObject: ImageSizeProps, sizeIncrease: number) {
  return {
    width: sizeObject?.width * sizeIncrease,
    height: sizeObject?.height * sizeIncrease,
  };
}
