import { useRef, useState, useEffect, useCallback, useId } from 'react';
import { useEventListener } from 'usehooks-ts';
import { useAutoPlay } from './use-autoplay';
import { useTrackQuery } from '../hooks/use-track-query';
import { useFadeControls } from './use-fade-controls';
import { findCaption, findTranscript } from '../util/matchers';
import { getMetricsTrackName, getMetricsVideoName, trackEvent } from '../util/metrics';

import type { VideoTrack, TrackOption, BaseVideoTrack } from '../video-player.controls';
import { initialVolume, lowestVolume } from '../video-player.volume-slider';
import { useMenu } from './use-control-menu';
import { CUE_BUFFER } from '../util/cue';
import type { VideoEventDetail } from '../util/event-emitter';
import { dispatchVideoEvent, VIDEO_EVENTS, VIDEO_UPDATE_EVENT } from '../util/event-emitter';
import type { VideoProps } from '../video-player.player';

export type VideoPlayerProps = BaseVideoTrack &
  Pick<VideoProps, 'isAutoPlay' | 'captionData'> &
  Partial<{
    hasAudio: boolean;
    playerId: ReturnType<typeof useId>;
    audioTracks: BaseVideoTrack[];
    wrapperElement: React.RefObject<HTMLDivElement>;
    shouldControlsRemainVisible?: boolean;
  }>;

export type PlayerState = {
  isMuted: boolean;
  isPlaying: boolean;
  showCaptions: boolean;
  activeCaptionTrack: TrackOption | null;
  activeTranscript: TrackOption | null;
  activeVideoTrack: VideoTrack | null;
  volume: Tuple<1, number>;
};

const defaultPlayerState: PlayerState = {
  isMuted: true,
  isPlaying: true,
  showCaptions: false,
  activeCaptionTrack: null,
  activeTranscript: null,
  activeVideoTrack: null,
  volume: initialVolume,
};

/**
 * Hook to manage the state and behavior of the video player.
 */
export function useVideoPlayer(props: VideoPlayerProps) {
  const videoElement = useRef<HTMLVideoElement>(null);
  const internalPlayerId = useId();
  const playerId = props.playerId ?? internalPlayerId;
  const hasAudio = props.hasAudio ?? true;
  const hookProps = { ...props, videoElement };
  const [playerState, setPlayerState] = useState<PlayerState>(() => {
    try {
      const videoPlayerSettings: Partial<PlayerState> = JSON.parse(
        localStorage.getItem('videoPlayerSettings') || '{}'
      );
      return { ...defaultPlayerState, ...videoPlayerSettings };
    } catch {
      return defaultPlayerState;
    }
  });
  const optionsState = useTrackQuery(hookProps);
  const controlsState = useFadeControls({ ...hookProps, ...playerState });
  const settingsMenu = useMenu();
  const activeVideo = playerState.activeVideoTrack || optionsState[0];
  const videoName = getMetricsVideoName(props.videoName ?? '');

  const playVideo = useCallback(() => {
    void videoElement.current?.play()?.catch(() => null);
  }, []);

  const pauseVideo = useCallback(() => videoElement.current?.pause(), []);

  const muteVideo = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.muted = true;
      videoElement.current.volume = 0;
    }
  }, []);

  const unmuteVideo = useCallback(([volume]: PlayerState['volume']) => {
    if (videoElement.current) {
      videoElement.current.muted = false;
      videoElement.current.volume = volume / 100;
    }
  }, []);

  function togglePlayButton() {
    setPlayerState((playerState) => {
      const isPlaying = !playerState.isPlaying;
      if (isPlaying) {
        playVideo();
        if (hasAudio) {
          dispatchVideoEvent({ type: VIDEO_EVENTS.PAUSE_OTHERS, id: playerId });
        }
        trackEvent('user_play', videoName);
      } else {
        pauseVideo();
      }
      return { ...playerState, isPlaying };
    });
  }

  function persistSettings(state: Partial<PlayerState>) {
    localStorage.setItem('videoPlayerSettings', JSON.stringify(state));
  }

  function performAudioActions({ isMuted, isPlaying, volume }: PlayerState) {
    if (isMuted) {
      muteVideo();
    } else {
      unmuteVideo(volume);
      if (isPlaying) {
        dispatchVideoEvent({ type: VIDEO_EVENTS.PAUSE_OTHERS, id: playerId });
      }
    }
    persistSettings({ volume });
  }

  function updateVolume(volume: PlayerState['volume']) {
    setPlayerState((playerState) => {
      const isMuted = volume[0] <= 0;
      const state = { ...playerState, isMuted, volume };
      performAudioActions(state);
      return state;
    });
  }

  function toggleMuteButton() {
    setPlayerState((playerState) => {
      const isMuted = !playerState.isMuted;
      const volume = playerState.volume[0] <= 0 ? lowestVolume : playerState.volume;
      const state = { ...playerState, isMuted, volume };
      trackEvent('sound', videoName);
      performAudioActions(state);
      return state;
    });
  }

  function toggleCaptions() {
    setPlayerState((playerState) => {
      trackEvent('cc', videoName);
      const showCaptions = !playerState.showCaptions;
      return { ...playerState, showCaptions };
    });
  }

  function updateCaption(selectedCaption: PlayerState['activeCaptionTrack']) {
    setPlayerState((playerState) => {
      const isSingleCaption = (activeVideo?.captionTracks?.length ?? 1) === 1;
      const trackName = getMetricsTrackName(selectedCaption);
      const eventName = isSingleCaption ? 'cc' : (`sub_${trackName}` as const);
      trackEvent(eventName, videoName);
      return {
        ...playerState,
        showCaptions: Boolean(selectedCaption),
        activeCaptionTrack: selectedCaption || playerState.activeCaptionTrack,
      };
    });
  }

  function updateTranscript(selectedTranscript: PlayerState['activeTranscript']) {
    setPlayerState((playerState) => {
      const trackName = getMetricsTrackName(selectedTranscript);
      const eventName = `transcript_${trackName}` as const;
      trackEvent(eventName, videoName);
      return { ...playerState, activeTranscript: selectedTranscript };
    });
  }

  function updateAudioTrack(selectedVideoTrack: NonNullable<PlayerState['activeVideoTrack']>) {
    setPlayerState((playerState) => {
      if (playerState.activeVideoTrack?.videoUrl === selectedVideoTrack.videoUrl) {
        return playerState;
      }

      const activeCaptionTrack = playerState.activeCaptionTrack
        ? findCaption({ selectedVideoTrack, activeTrack: playerState.activeCaptionTrack })
        : null;

      const activeTranscript = playerState.activeTranscript
        ? findTranscript({ selectedVideoTrack, activeTrack: playerState.activeTranscript })
        : null;

      if (videoElement.current) {
        const isGroupMatch = activeVideo?.videoGroup === selectedVideoTrack.videoGroup;
        const currentTime = isGroupMatch ? videoElement.current.currentTime : 0;
        videoElement.current.src = selectedVideoTrack.videoUrl;
        videoElement.current.currentTime = currentTime;
      }

      const videoLabel = getMetricsVideoName(selectedVideoTrack.videoLabel || '');
      const eventName = `audiotrack_${videoLabel}` as const;
      trackEvent(eventName, videoName);
      playerState.isPlaying ? playVideo() : pauseVideo();

      return {
        ...playerState,
        activeTranscript,
        activeCaptionTrack,
        activeVideoTrack: selectedVideoTrack,
      };
    });
  }

  function closeTranscript() {
    updateTranscript(null);
    settingsMenu.popupButtonRef.current?.focus();
  }

  function onCueSelect(cue: VTTCue) {
    if (videoElement.current) {
      const adjustedTime = cue.startTime + CUE_BUFFER;
      videoElement.current.currentTime = adjustedTime;
    }
  }

  const isAutoPlay = useAutoPlay({
    isAutoPlay: props.isAutoPlay ?? defaultPlayerState.isPlaying,
  });

  useEffect(() => {
    setPlayerState((playerState) => {
      if (isAutoPlay) {
        muteVideo();
        playVideo();
      } else {
        unmuteVideo(playerState.volume);
        pauseVideo();
      }
      return {
        ...playerState,
        isMuted: isAutoPlay,
        isPlaying: isAutoPlay,
      };
    });
  }, [isAutoPlay, muteVideo, pauseVideo, playVideo, unmuteVideo]);

  useEffect(() => {
    if (playerState.showCaptions) {
      const inActiveCaptionTracks = (activeVideo?.captionTracks ?? []).filter(
        (option): option is HTMLTrackElement => option.src !== playerState.activeCaptionTrack?.src
      );

      if (inActiveCaptionTracks.length) {
        inActiveCaptionTracks.forEach((track) => {
          track.track.oncuechange = null;
        });
      }

      if (playerState.activeCaptionTrack) {
        playerState.activeCaptionTrack.track.oncuechange = () => {
          setPlayerState((playerState) => ({
            ...playerState,
            activeCaptionTrack: playerState.activeCaptionTrack,
          }));
        };
      }
    } else {
      if (playerState.activeCaptionTrack) {
        playerState.activeCaptionTrack.track.oncuechange = null;
      }
    }
  }, [activeVideo?.captionTracks, playerState.activeCaptionTrack, playerState.showCaptions]);

  useEventListener('metrics-ready', () => {
    if (isAutoPlay && videoName) {
      trackEvent('autoplay', videoName);
    }
  });

  useEventListener(VIDEO_UPDATE_EVENT, (event: CustomEvent<VideoEventDetail>) => {
    const isPauseOthersEvent = event.detail.type === VIDEO_EVENTS.PAUSE_OTHERS;
    const isPauseSelfEvent = event.detail.type === VIDEO_EVENTS.PAUSE_SELF;
    const isPauseEvent = isPauseOthersEvent || isPauseSelfEvent;

    if (hasAudio && playerState.isPlaying && isPauseEvent) {
      const isOtherVideo = event.detail.id !== playerId;
      const isSameVideo = event.detail.id === playerId;
      const shouldPauseAll = isPauseOthersEvent && isOtherVideo;
      const shouldPauseSelf = isPauseSelfEvent && isSameVideo;
      if (shouldPauseAll || shouldPauseSelf) {
        togglePlayButton();
      }
    }
  });

  return {
    videoProps: {
      videoElement,
    },
    videoCaptionProps: {
      showCaptions: playerState.showCaptions,
      activeCaptionTrack: playerState.activeCaptionTrack,
    },
    videoTranscriptProps: {
      activeTranscript: playerState.activeTranscript,
      closeTranscript,
      onCueSelect,
    },
    videoControlsProps: {
      controlsState,
      activeVideo,
      playerState,
      optionsState,
      togglePlayButton,
      toggleMuteButton,
      toggleCaptions,
      settingsMenu,
      updateCaption,
      updateTranscript,
      updateAudioTrack,
      updateVolume,
      videoElement,
      hasAudio,
    },
  };
}
