/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable no-console */

import type { expect as jestExpect } from '@jest/globals';

import type {
  beforeEach as vitestBeforeEach,
  afterEach as vitestAfterEach,
  expect as vitestExpect,
} from 'vitest';

import { log, type LogArgs } from './log';

const MAX_TEST_TIME = 3000;
const MAX_CI_LOGS = 100;
let droppedLogs = 0;
let logAcc: Array<Parameters<typeof log>> = [];
let startTime = new Date().getTime();

const testBeforeEach: undefined | typeof vitestBeforeEach = (globalThis as any).beforeEach;
const testAfterEach: undefined | typeof vitestAfterEach = (globalThis as any).afterEach;
const testExpect: undefined | Record<never, never> | typeof vitestExpect | typeof jestExpect = (
  globalThis as any
).expect;

export const useTestLogBuffer =
  testBeforeEach &&
  testAfterEach &&
  testExpect &&
  'getState' in testExpect &&
  'assertionCalls' in testExpect.getState();

// Can't depend on process.env.NODE_ENV as it's also set to TEST in some cypress tests.
//
// Cypress also included hooks with these names in the global namespace, so we have
// to check the actual behavior of the hooks to make sure we're in jest/vitest
//
// we recheck the existence of these items to apease tsc
if (useTestLogBuffer && testBeforeEach && testAfterEach && testExpect && 'getState' in testExpect) {
  testBeforeEach(() => {
    logAcc = [];
    droppedLogs = 0;
    startTime = new Date().getTime();
  });

  testAfterEach(() => {
    const matcherState = testExpect.getState();

    if ('numPassingAsserts' in matcherState) {
      // jest
      const { assertionCalls, numPassingAsserts } = matcherState;
      if (
        assertionCalls !== numPassingAsserts ||
        new Date().getTime() > startTime + MAX_TEST_TIME
      ) {
        for (const args of logAcc) {
          log(...args);
        }
      }
    } else {
      // vitest
      const { assertionCalls, expectedAssertionsNumber, isExpectingAssertions } = matcherState;
      if (
        (isExpectingAssertions && assertionCalls !== expectedAssertionsNumber) ||
        new Date().getTime() > startTime + MAX_TEST_TIME
      ) {
        for (const args of logAcc) {
          log(...args);
        }
      }
    }

    if (process.env['CI'] && logAcc.length === MAX_CI_LOGS) {
      console.log(`buffered ${MAX_CI_LOGS} logs during this test, dropped ${droppedLogs} logs to reduce memory usage.
 Please run this test locally to view a all logs`);
    }
  });
}

export function logToTestAccumulator(
  key: LogArgs[0],
  val: LogArgs[1],
  lvl: LogArgs[2],
  hint: LogArgs[3]
) {
  logAcc.push([key, val, lvl, hint]);

  while (process.env['CI'] && logAcc.length > MAX_CI_LOGS) {
    logAcc.shift();
    droppedLogs++;
  }
}
