import { logWarning } from '@dx-ui/framework-logger';
import type { Track } from '../video-player.controls';

/**
 * Asynchronously adds a track element to a video element for closed captions.
 *
 *  - This is done imperatively vs declaratively so the cues are available when the VTT is loaded.
 *  - The track element is hidden by default so the native captions do not display.
 */
export async function addTrackAsync(
  videoElement: HTMLVideoElement,
  track: Track
): Promise<HTMLTrackElement | null> {
  const trackElement = document.createElement('track');
  trackElement.id = getTrackId(track);
  trackElement.label = track.label;
  trackElement.srclang = track.language;
  trackElement.kind = 'metadata';
  trackElement.track.mode = 'hidden';
  trackElement.dataset.type = track.type;
  videoElement.append(trackElement);

  return new Promise((resolve) => {
    trackElement.onload = () => {
      resolve(trackElement);
    };
    trackElement.onerror = () => {
      logWarning(
        'OSCVideoPlayer',
        new Error('track onerror'),
        `Failed to fetch track ${track.url}`
      );
      videoElement.removeChild(trackElement);
      resolve(null);
    };
    trackElement.src = track.url;
  });
}

function getTrackId(track: Track) {
  return [track.language, track.label, track.url].filter(Boolean).join('-').toLowerCase();
}
