import type * as React from 'react';
import cx from 'classnames';
import { Image } from 'next/dist/client/image-component';
import { useEffect, useRef, useState } from 'react';
import type { Variant } from '@dx-ui/osc-brand-buttons';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import type { Link } from '@dx-ui/osc-link';
import { Caption } from '@dx-ui/osc-caption';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { HeadingLevel } from '@dx-ui/osc-heading-level';
import {
  ANALYTICS_GLOBAL_CLICK_EVENT,
  ANALYTICS_NO_VALUE,
  clearImageAnalyticsData,
  trackEvent,
} from '@dx-ui/config-metrics';
import { generatePatchworkGridMetrics } from './utils/patchwork-grid-analytics';

type PatchworkGridImage = {
  altText?: string;
  url: string;
  variants?: {
    '2x1': string;
  };
  captionData?: CaptionProps;
};

type RowImageType = {
  imageCount: number;
  image: PatchworkGridImage;
  index: number;
  isAnimated?: boolean;
  delay: number;
  shouldUseGetAspectRatio?: boolean;
  headline?: string;
  numImages: number;
  imageIndex: number;
  patchWorkHeadline?: string;
};

type RowHeadlineType = Pick<
  PatchWorkGridRowType,
  | 'link'
  | 'description'
  | 'headline'
  | 'brandComponentTheme'
  | 'rowHeadlineClassName'
  | 'customContent'
> &
  Pick<React.ComponentProps<typeof AnimateRevealItem>, 'isAnimated' | 'delay'>;

export type PatchWorkGridRowType = {
  /** Headline for Copy section in Row */
  headline?: string;
  /** Description for section in Row */
  description?: string;
  /** CTA for section in Row */
  link?: React.ComponentProps<typeof Link> & {
    experimentationConfiguration?: CmsExperimentationConfiguration;
    /** Optional link variant @see {@linkcode Variant} */
    variant?: Variant;
  };
  /** Flip the direction of the Image / Copy sections */
  isReverse?: boolean;
  /** An array of PatchworkGridImage objects */
  images?: PatchworkGridImage[];
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Add Tailwind classes to the row headline */
  rowHeadlineClassName?: string;
  /** Use getAspectRatioUrl for Images */
  shouldUseGetAspectRatio?: boolean;
  /** Enable scrolling animations */
  isAnimated?: boolean;
  /** Render custom content instead of heading / description / link */
  customContent?: React.ReactNode;
  /** Number of total PatchworkGrid Rows - used for analytics */
  numImages?: number;
  /** Starting index for images for that row - used by analytics to compute the image position */
  startingImageIndex?: number;
  /** The first non-empty headline in the row data - used for analytics */
  patchWorkHeadline?: string;
};

export function PatchworkGridRow({
  isReverse,
  brandComponentTheme,
  headline,
  description,
  link,
  images,
  isAnimated,
  rowHeadlineClassName,
  shouldUseGetAspectRatio,
  customContent,
  numImages = 0,
  startingImageIndex = 0,
  patchWorkHeadline,
}: PatchWorkGridRowType) {
  // If a headline is provided, the row can only display a single image. Strip out the other images.
  (headline || description || link) && images && images?.length > 1 && images.splice(1);

  const delay = isReverse ? [200, 100] : [100, 200];
  const numImagesInRow = images?.length || 0;

  const rowImages =
    (images && images?.length >= 1 && (headline || description || link)) ||
    (images && images?.length > 1)
      ? images
          .filter((image) => !!image)
          .map((image, index) => {
            const normalizedImageIndex = isReverse ? numImagesInRow - index - 1 : index;

            // The imageIndex should be adjusted to account for the startingImageIndex.
            const imageIndex = startingImageIndex + normalizedImageIndex;

            return RowImage({
              imageCount: images?.length,
              image,
              index,
              isAnimated,
              delay: delay[index] ?? 0,
              shouldUseGetAspectRatio,
              numImages,
              imageIndex,
              patchWorkHeadline,
            });
          })
      : [];

  const orderedElements = [
    ...rowImages,
    <RowHeadline
      link={link}
      description={description}
      headline={headline}
      brandComponentTheme={brandComponentTheme}
      isAnimated={isAnimated}
      customContent={customContent}
      delay={delay[1] ?? 0}
      rowHeadlineClassName={rowHeadlineClassName}
      key="headline"
    />,
  ];

  return isReverse ? orderedElements.reverse() : orderedElements;
}

const RowHeadline: React.FC<RowHeadlineType> = ({
  link,
  description,
  headline,
  brandComponentTheme,
  isAnimated,
  delay,
  rowHeadlineClassName = '',
  customContent,
}) => {
  if (!headline && !description && !link) return null;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      className={cx(
        'col-span-1 flex flex-col justify-center px-8 py-11 sm:col-span-2 sm:py-8 xl:px-32',
        {
          [rowHeadlineClassName]: !!rowHeadlineClassName,
        }
      )}
    >
      {customContent ? (
        customContent
      ) : (
        <AnimateRevealItem delay={delay} isAnimated={isAnimated}>
          {headline ? (
            <HeadingLevel
              headingLevelFallback={3}
              className={cx('heading-xl patchwork-grid-row-headline-text pb-4', {
                '!text-text-inverse': isDark,
                'patchwork-grid-row-headline-text-light': isLight,
              })}
            >
              {headline}
            </HeadingLevel>
          ) : null}

          {description ? (
            <BrandTextBody
              className={cx(
                'pb-4 text-base font-normal text-text sm:text-xl',
                'patchwork-grid-row-description-text',
                {
                  'text-text-inverse': isDark,
                  'patchwork-grid-row-description-text-light': isLight,
                }
              )}
              brandComponentTheme={brandComponentTheme}
            >
              {description}
            </BrandTextBody>
          ) : null}

          {link ? (
            <BrandLink
              url={link.url}
              label={link.label}
              isNewWindow={link.isNewWindow}
              showNewWindowIcon={link.isNewWindow}
              brandComponentTheme={brandComponentTheme}
              variant={link.variant}
              data-conductrics-goal={link.experimentationConfiguration?.goal}
              data-conductrics-value={link.experimentationConfiguration?.value}
              onClick={() => clearImageAnalyticsData()}
            />
          ) : null}
        </AnimateRevealItem>
      )}
    </div>
  );
};

const RowImage: React.FC<RowImageType> = ({
  imageCount,
  image,
  isAnimated,
  delay,
  index,
  shouldUseGetAspectRatio,
  numImages,
  imageIndex,
  patchWorkHeadline,
}) => {
  const imageRef = useRef<HTMLDivElement | null>(null);

  // If two images are provided to a row, the first image should display at a 2x1 aspect ratio.
  const imageSize = imageCount === 2 && index === 0 ? '2x1' : '1x1';

  // If two images are provided to a row, the first image should display the 2x1 variant, if it exists.
  const imageSource =
    imageCount === 2 && index === 0 ? image?.variants?.['2x1'] ?? image?.url : image?.url;

  const [imageSrc, setImageSrc] = useState(imageSource);

  useEffect(() => {
    const aspectRatioImage = getAspectRatioUrl({
      src: imageSource,
      width: imageRef.current ? imageRef.current.offsetWidth : 0,
    }).twoXimgUrl;

    setImageSrc(shouldUseGetAspectRatio && aspectRatioImage ? aspectRatioImage : imageSource);
  }, [imageSource, imageRef, shouldUseGetAspectRatio]);

  if (!imageSrc) {
    return null;
  }

  return (
    <figure
      className={cx('relative col-span-1 overflow-hidden', {
        'aspect-[2/1] sm:col-span-2 sm:aspect-auto': imageSize === '2x1',
        'aspect-square': imageSize === '1x1',
      })}
      key={index}
      ref={imageRef}
    >
      <AnimateRevealItem delay={delay} className="size-full" isAnimated={isAnimated}>
        <Image
          unoptimized={true}
          src={imageSrc}
          alt={image?.altText || ''}
          fill
          style={{ objectFit: 'cover' }}
          className="image-corner-radius"
        />
        {image.captionData && (
          <Caption
            caption={image?.captionData?.caption}
            captionLink={image?.captionData?.captionLink}
            metricsOnClick={() => {
              trackEvent(
                ANALYTICS_GLOBAL_CLICK_EVENT,
                generatePatchworkGridMetrics({
                  count: numImages,
                  position: imageIndex,
                  itemTitle: image?.captionData?.caption || ANALYTICS_NO_VALUE,
                  headline: patchWorkHeadline || ANALYTICS_NO_VALUE,
                })
              );
            }}
          />
        )}
      </AnimateRevealItem>
    </figure>
  );
};

export default PatchworkGridRow;
