import type { WpMenuItem, WpMenu } from '@curated-property/utils';
import { createFetch, isValidCtyhocn } from '@curated-property/utils';

import type {
  WeddingAvailabilityCalendarQuery,
  HtmlMenusQuery,
  RoomsPostsQuery,
  RecurringEventsQuery,
  WordpressPageInfoQuery,
  WordpressPageInfoQueryVariables,
  WordpressPageTranslationInfoQuery,
  WordpressPageTranslationInfoQueryVariables,
} from '../generated/wp';
import { PageIdType } from '../generated/wp';
import type {
  AssociatedHotelInfoQuery,
  AssociatedHotelInfoQueryVariables,
  Brand_Hotel_ShopAvailOptionsQuery as BrandFooterQuery,
  Brand_Hotel_ShopAvailOptionsQueryVariables as BrandFooterVariables,
  FrenchLegaleseQuery,
  FrenchLegaleseQueryVariables,
  HotelOfferListingQuery,
} from '../generated/dx-gql';

import { getServerSideClients } from '@dx-ui/framework-react-query';
import {
  HotelOfferListingDocument,
  FrenchLegaleseDocument,
  AssociatedHotelInfoDocument,
  Brand_Hotel_ShopAvailOptionsDocument,
} from '../queries/dx-gql/generated/react-query';

import {
  getLanguagePageIDsQuery,
  WACQuery,
  HTMLMenuQuery,
  RoomsPostQuery,
  getRecurringEventsQuery,
  WPPageQuery,
} from '../queries/wp';

import { dehydrate } from '@tanstack/react-query';

import type { HotelQuery, HotelQueryVariables } from '@curated-property/shared-components';
import {
  getRoomTypesQuery,
  isRelativeUrl,
  ROOM_TYPE_FEATURES,
} from '@curated-property/shared-components';
import type { PreviewData } from './preview';
import { getUnixTime, startOfMonth } from 'date-fns';
import type { OutgoingMessage } from 'node:http';
import type { logger as sharedLogger } from '@dx-shared/logger';

export type ExtendedHotelRoomType = NonNullable<HotelQuery['hotel']>['roomTypes'][number] & {
  brandName?: string | undefined;
  hotelCtyhocn?: string | undefined;
};

export function slugMakeGetStaticProps({
  wpApiEndpoint,
  dxGql,
  ctyhocn,
  associatedHotelsDefaultCtyhocn,
  revalidate = 60,
  menuItemPrefix = '',
  logger,
  language,
}: {
  wpApiEndpoint: string;
  dxGql: { endpoint: string };
  ctyhocn: string;
  associatedHotelsDefaultCtyhocn?: string | null;
  revalidate?: number | false;
  menuItemPrefix?: string;
  logger?: typeof sharedLogger;
  language: string;
}) {
  type AllComponents = NonNullable<
    NonNullable<WordpressPageInfoQuery['page']>['acfFlexible']
  >['flexibleComponents'];

  const queryClientProps = getQueryClientProps();
  const { queryClient } = getServerSideClients({
    ...queryClientProps,
    language,
  });

  const fetchDxGQLApi = createFetch(dxGql.endpoint, {
    addOpertionName: true,
    logger,
  });
  const fetchWpApi = createFetch(wpApiEndpoint, { logger });

  async function getDxGqlInfo(language = 'en') {
    const authHeader = process.env.NEXT_PUBLIC_DX_GQL_AUTH;
    return fetchDxGQLApi<BrandFooterQuery, BrandFooterVariables>(
      Brand_Hotel_ShopAvailOptionsDocument,
      {
        variables: {
          language,
          ctyhocn,
        },
        authHeader,
      }
    );
  }

  async function getOffersCuratedData(
    language = 'en',
    components: AllComponents,
    defaultCtyhocn: string | null = null
  ) {
    const authHeader = process.env.NEXT_PUBLIC_DX_GQL_AUTH;
    if (
      components?.find(
        (item) => item?.__typename === 'Page_Acfflexible_FlexibleComponents_OffersCurated'
      )
    ) {
      return fetchDxGQLApi<HotelOfferListingQuery>(HotelOfferListingDocument, {
        variables: {
          language,
          ctyhocn: defaultCtyhocn || ctyhocn,
        },
        authHeader,
      });
    } else {
      return null;
    }
  }

  type AllMenus = WordpressPageInfoQuery['mainNav'] &
    WordpressPageInfoQuery['iconNav'] &
    WordpressPageInfoQuery['footerNav'] & {
      menuItems?: WpMenu['menuItems'];
    };

  function prefixWPmenu(result: { menu: AllMenus | undefined }) {
    if (!result?.menu) {
      return result;
    }

    const recursiveChildren = (
      item: WpMenuItem['node'],
      depth: number
    ): WpMenu | undefined | null => {
      const theDepth = depth - 1;
      if (theDepth !== 0) {
        return item?.childItems
          ? {
              ...item?.childItems,
              edges: item?.childItems?.edges?.map((child) => ({
                ...child,
                node: {
                  ...child.node,
                  path: isRelativeUrl(child?.node?.path)
                    ? menuItemPrefix + child?.node?.path
                    : child?.node?.path,
                  childItems: recursiveChildren(child?.node, theDepth),
                },
              })),
            }
          : { ...item?.childItems };
      } else {
        return null;
      }
    };
    const menuItems = result?.menu?.edges?.map((item: WpMenuItem | undefined) => {
      return {
        ...item,
        node: {
          ...item?.node,
          path: isRelativeUrl(item?.node?.path)
            ? menuItemPrefix + item?.node?.path
            : item?.node?.path,
          childItems: recursiveChildren(item?.node, 3),
        },
      };
    });

    if (!menuItems) {
      return result;
    }
    return {
      ...result,
      menu: {
        ...result.menu,
        menuItems: {
          ...result.menu?.menuItems,
          edges: menuItems,
        },
      },
    };
  }

  async function fetchAssociatedHotelInfo(associatedHotels: string[]) {
    const authHeader = process.env.NEXT_PUBLIC_DX_GQL_AUTH;
    const dataArray = [];
    let i = 0;
    if (associatedHotels?.length > 0) {
      while (i < associatedHotels?.length) {
        const data = await fetchDxGQLApi<
          AssociatedHotelInfoQuery,
          AssociatedHotelInfoQueryVariables
        >(AssociatedHotelInfoDocument, {
          variables: {
            ctyhocn: associatedHotels[i],
            language: 'en',
          },
          authHeader,
        });
        dataArray.push(data);
        i++;
      }
    }
    return dataArray;
  }

  async function getLanguagePageIDs(uri: string) {
    const data = await fetchWpApi<
      WordpressPageTranslationInfoQuery,
      WordpressPageTranslationInfoQueryVariables
    >(getLanguagePageIDsQuery, {
      variables: {
        id: uri,
        idType: PageIdType.Uri,
        asPreview: false,
      },
    });
    return data?.page;
  }

  async function getEntireWPPage(
    params: { hotelSlug?: string; slug?: string[] | undefined } = {},
    preview = false,
    previewData: PreviewData | undefined,
    locale = '',
    wpmlStatus = false
  ) {
    const uri = params?.slug === undefined ? '/' : `/${params?.slug.join('/')}/`;
    const lang = wpmlStatus ? (locale !== 'default' ? locale : 'en') : 'en';

    let variables: WordpressPageInfoQueryVariables = {
      id: uri,
      idType: PageIdType.Uri,
      asPreview: false,
      language: lang,
    };

    if (lang !== 'en') {
      const langData = await getLanguagePageIDs(uri);

      let id = langData?.databaseId || 0;

      langData?.translations?.forEach((trans) => {
        if (trans?.locale?.startsWith(lang)) {
          id = parseInt(trans.id);
        }
      });

      variables = {
        id: id.toString() || '',
        idType: PageIdType.DatabaseId,
        asPreview: false,
        language: lang,
      };
    }

    if (preview) {
      variables = {
        id: previewData?.page?.id?.toString() || '',
        idType: PageIdType.DatabaseId,
        asPreview: true,
        language: lang,
      };
    }

    return fetchWpApi<WordpressPageInfoQuery>(WPPageQuery, {
      variables,
      authHeader: preview ? `Bearer ${previewData?.authHeader}` : undefined,
    });
  }

  function getWeddingAvailiblityBookings() {
    return fetchWpApi<WeddingAvailabilityCalendarQuery>(WACQuery, {
      variables: {
        minTimestamp: getUnixTime(startOfMonth(new Date())),
      },
    });
  }

  function getHTMLMenus() {
    return fetchWpApi<HtmlMenusQuery>(HTMLMenuQuery, {});
  }

  function getRoomsPosts() {
    return fetchWpApi<RoomsPostsQuery>(RoomsPostQuery, {});
  }

  function transformWPPages(result: { pages: WordpressPageInfoQuery['allWPPages'] }) {
    if (result.pages && !result?.pages?.nodes?.[0]?.uri?.includes(menuItemPrefix)) {
      const prefixed = result?.pages?.nodes?.map((node) => ({
        ...node,
        uri: menuItemPrefix + node?.uri,
      }));
      result.pages.nodes = prefixed;
    }
    return result;
  }
  interface HotelQueryVariablesEnhanced extends HotelQueryVariables {
    enhance: boolean;
  }

  async function maybeGetRoomsAndSuites(
    wpPageInfo: WordpressPageInfoQuery,
    language: string,
    typenameMatch: NonNullable<NonNullable<AllComponents>[0]>['__typename'],
    associatedHotels: string[]
  ) {
    const hasRoomsComponent = wpPageInfo?.page?.acfFlexible?.flexibleComponents?.some(
      // check if the component contain the text RoomTypes (catches both Room Tiles and Room Tiles Lite components)
      (c) => c?.__typename.includes(typenameMatch)
    );
    if (!hasRoomsComponent) {
      return null;
    }
    const authHeader = process.env.NEXT_PUBLIC_DX_GQL_AUTH;
    // todo: transform roomType here
    // but if this is availbility based we might go directly to shop avail and do this entirely client side??
    const roomList = [];
    if (associatedHotels?.length > 0) {
      for (let i = 0; i < associatedHotels?.length; i++) {
        const dxGqlResult = await fetchDxGQLApi<HotelQuery, HotelQueryVariablesEnhanced>(
          getRoomTypesQuery,
          {
            variables: {
              ctyhocn: associatedHotels[i].toUpperCase(),
              language,
              roomTypeFeatures: ROOM_TYPE_FEATURES,
              enhance: true,
            },
            authHeader,
          }
        );
        const brandName = dxGqlResult?.hotel?.brand?.name;
        const roomTypesResult = dxGqlResult?.hotel?.roomTypes;
        roomTypesResult?.forEach((room: ExtendedHotelRoomType) => {
          room.brandName = brandName;
          room.hotelCtyhocn = associatedHotels[i];
        });
        roomList.push(roomTypesResult);
      }
      return roomList?.reduce((acc, val) => acc?.concat(val ?? []), []) || null;
    } else {
      // No associated hotels, run regular query
      const dxGqlResult = await fetchDxGQLApi<HotelQuery, HotelQueryVariablesEnhanced>(
        getRoomTypesQuery,
        {
          variables: {
            ctyhocn,
            language,
            roomTypeFeatures: ROOM_TYPE_FEATURES,
            enhance: true,
          },
          authHeader,
        }
      );
      return dxGqlResult?.hotel?.roomTypes || null;
    }
  }

  function getRecurringEvents() {
    return fetchWpApi<RecurringEventsQuery>(getRecurringEventsQuery);
  }

  function getAdditionalCtyhocns(allWPPageInfo: WordpressPageInfoQuery) {
    const ctyhocns = [];
    if (allWPPageInfo?.page?.metaDataFields?.ctyhocnOverride) {
      const ctyhocn = allWPPageInfo?.page?.metaDataFields?.ctyhocnOverride?.toUpperCase();
      if (isValidCtyhocn.test(ctyhocn)) {
        ctyhocns.push(ctyhocn);
      }
    }
    type ElementOf<T> = T extends (infer U)[] ? U : never;
    type AnyComponent = ElementOf<AllComponents>;
    allWPPageInfo?.page?.acfFlexible?.flexibleComponents?.forEach((item: AnyComponent) => {
      if (item?.__typename === 'Page_Acfflexible_FlexibleComponents_HotelAmenities') {
        const ctyhocn = item?.ctyhocn?.toUpperCase();
        if (isValidCtyhocn.test(ctyhocn ?? '')) {
          ctyhocns.push(ctyhocn);
        }
      }
    });
    return [...new Set(ctyhocns)];
  }

  async function getFrenchTravelAndTourismMediatorLegalese(ctyhocn: string) {
    return fetchDxGQLApi<FrenchLegaleseQuery, FrenchLegaleseQueryVariables>(
      FrenchLegaleseDocument,
      {
        variables: {
          ctyhocn: ctyhocn?.toUpperCase(),
          language: 'en',
        },
      }
    );
  }

  async function getAdditionalRoomData(
    locale: string,
    allWPPageInfo: WordpressPageInfoQuery,
    additionalCtyhocns: string[]
  ) {
    const data: { [key: string]: ExtendedHotelRoomType[] | undefined | null } = {};
    const pageCtyhocnOverride = allWPPageInfo?.page?.metaDataFields?.ctyhocnOverride ?? '';
    const addCtyhocnRoomData = async (ctyhocn: string) => {
      if (ctyhocn && additionalCtyhocns.includes(ctyhocn?.toUpperCase())) {
        data[ctyhocn.toUpperCase()] = await maybeGetRoomsAndSuites(
          allWPPageInfo,
          locale || 'en',
          'Page_Acfflexible_FlexibleComponents_RoomTypes',
          [ctyhocn?.toUpperCase()]
        );
      }
    };
    await addCtyhocnRoomData(pageCtyhocnOverride);
    await Promise?.all(
      allWPPageInfo?.page?.acfFlexible?.flexibleComponents?.map(async (item) => {
        if (
          item?.__typename === 'Page_Acfflexible_FlexibleComponents_RoomTypes' ||
          item?.__typename === 'Page_Acfflexible_FlexibleComponents_RoomTypesLite'
        ) {
          await addCtyhocnRoomData(item?.ctyhocn ?? '');
        }
      }) ?? []
    );
    return data;
  }

  async function getAdditionalWPData(components: AllComponents) {
    const data = {} as {
      WeddingAvailabilityCalendar?: {
        bookings?: NonNullable<WeddingAvailabilityCalendarQuery['weddingbookings']>['edges'];
        venues?: NonNullable<
          NonNullable<WeddingAvailabilityCalendarQuery['themeSettings']>['SettingsThemeSettings']
        >['weddingVenues'];
        timeSlots?: NonNullable<
          NonNullable<WeddingAvailabilityCalendarQuery['themeSettings']>['SettingsThemeSettings']
        >['venuesTimeSlots'];
      };
      RecurringEvents?: {
        recurringevents?: NonNullable<RecurringEventsQuery['recurringevents']>['edges'];
        calendars?: NonNullable<RecurringEventsQuery['calendars']>['edges'];
      };
      HTMLMenu?: {
        HTMLMenu?: NonNullable<HtmlMenusQuery['htmlMenus']>['edges'];
      };
      RoomsPosts?: {
        RoomsPosts?: NonNullable<RoomsPostsQuery['rooms']>['edges'];
      };
    };
    if (
      components?.find(
        (item) =>
          item?.__typename === 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar'
      )
    ) {
      const allData = await getWeddingAvailiblityBookings();
      data.WeddingAvailabilityCalendar = {
        bookings: allData?.weddingbookings?.edges,
        venues: allData?.themeSettings?.SettingsThemeSettings?.weddingVenues,
        timeSlots: allData?.themeSettings?.SettingsThemeSettings?.venuesTimeSlots,
      };
    }
    if (
      components?.find(
        (item) =>
          item?.__typename ===
          'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid'
      )
    ) {
      const allData = await getRecurringEvents();
      data.RecurringEvents = {
        recurringevents: allData?.recurringevents?.edges,
        calendars: allData?.calendars?.edges,
      };
    }
    if (
      components?.find(
        (item) => item?.__typename === 'Page_Acfflexible_FlexibleComponents_HtmlMenu'
      )
    ) {
      const allData = await getHTMLMenus();
      data.HTMLMenu = {
        HTMLMenu: allData?.htmlMenus?.edges,
      };
    }
    if (
      components?.find(
        (item) => item?.__typename === 'Page_Acfflexible_FlexibleComponents_RoomTypes'
      )
    ) {
      const allData = await getRoomsPosts();
      data.RoomsPosts = {
        RoomsPosts: allData?.rooms?.edges,
      };
    }
    return data;
  }

  return async function getStaticProps({
    locale,
    params,
    preview,
    previewData,
    wpmlStatus,
  }: {
    locale: string;
    params?: {
      hotelSlug?: string;
      slug?: string[];
    };
    preview?: boolean;
    previewData?: PreviewData;
    wpmlStatus?: boolean;
  }) {
    try {
      const dxGqlInfo = await getDxGqlInfo();
      dxGqlInfo?.hotel?.policy?.additionalPolicies.push(
        (await getFrenchTravelAndTourismMediatorLegalese(ctyhocn))?.hotel?.policy
          ?.additionalPolicies[0] || null
      );
      const allWPPageInfo = await getEntireWPPage(params, preview, previewData, locale, wpmlStatus);

      // no dxgql hotel result, 404 please.
      if (!dxGqlInfo?.hotel) {
        return {
          notFound: true,
        };
      }

      if (allWPPageInfo?.['mainNav'] === undefined) {
        logger?.error(
          {
            name: 'allWPPageInfo',
            message: 'Wordpress data is returning undefined.',
          },
          'mainNav is undefined. It is possible that: \n 1) You are not connected to the VPN. \n 2) The site you are trying to connect to does not exist. \n 3) An error has occurred in the CMS. \n 4) The CMS may be returning data that doesnt match exactly what the UI is expecting \n 5) The Princess is in another castle 🏰'
        );
        return {
          notFound: true,
        };
      }

      const menuInfo = prefixWPmenu({ menu: allWPPageInfo['mainNav'] });
      const iconMenuInfo = prefixWPmenu({ menu: allWPPageInfo['iconNav'] });
      const footerMenuInfo = prefixWPmenu({ menu: allWPPageInfo['footerNav'] });
      const langMenuStyles = allWPPageInfo['languageNavStyles'];

      const additionalWPData = await getAdditionalWPData(
        allWPPageInfo?.page?.acfFlexible?.flexibleComponents
      );

      const offersCuratedData = await getOffersCuratedData(
        locale,
        allWPPageInfo?.page?.acfFlexible?.flexibleComponents,
        associatedHotelsDefaultCtyhocn
      );

      const additionalCtyhocns = getAdditionalCtyhocns(allWPPageInfo);
      const props = {
        isPreview: preview || false,
        propertyAlerts: { hotel: dxGqlInfo?.hotel || [] },
        dxGqlInfo,
        menuInfo,
        iconMenuInfo,
        langMenuStyles,
        footerMenuInfo,
        wpPageInfo: {
          page: allWPPageInfo.page,
          ctyhocnOverride: allWPPageInfo?.page?.metaDataFields?.ctyhocnOverride,
        },
        roomInfo: await maybeGetRoomsAndSuites(
          allWPPageInfo,
          locale || 'en',
          'Page_Acfflexible_FlexibleComponents_RoomTypes',
          dxGqlInfo?.hotel?.associatedHotels
        ),
        additionalCtyhocns,
        additionalWPData,
        additionalRoomData:
          allWPPageInfo?.page &&
          (await getAdditionalRoomData(locale, allWPPageInfo, additionalCtyhocns)),
        wpAllPages: transformWPPages({
          pages: allWPPageInfo?.allWPPages,
        }),
        wpThemeSettings: allWPPageInfo,
        offersCuratedData: offersCuratedData || null,
        dxGql,
        wpApiEndpoint,
        ctyhocn,
        defaultCtyhocn: associatedHotelsDefaultCtyhocn || '',
        additionalHotelInfo: (await fetchAssociatedHotelInfo(additionalCtyhocns)) || [],
        associatedHotelInfo:
          (await fetchAssociatedHotelInfo(dxGqlInfo?.hotel?.associatedHotels)) || [],
        // any queries that are run here will re-used client side
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
        // pass through only client side env vars
        queryClientProps: getQueryClientProps(),
      };
      return { props, revalidate };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error in slugGetStaticProps', error);
      return { notFound: true };
    }
  };
}

export function getQueryClientProps(response: OutgoingMessage | null = null) {
  return {
    appId: process.env.DX_AUTH_API_CUSTOMER_APP_ID,
    appName: 'dx-curated-ui',
    gqlEndpoints: {
      browser: process.env.NEXT_PUBLIC_CLIENT_ENDPOINT,
      server: process.env.DX_GQL_SERVER_ENDPOINT,
    },
    authEndpoints: {
      browser: process.env.DX_AUTH_API_CLIENT_URI,
      server: process.env.DX_AUTH_API_SERVER_URI,
    },
    response,
  };
}
