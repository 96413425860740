import type { ImageProps } from 'next/legacy/image';
import Image from 'next/legacy/image';

/**
 * @param url string from wordpress
 * @param width number || string
 * @param height number || string
 *
 * Cloudinary can do so much more, we can add more when needed.
 * https://cloudinary.com/documentation/image_transformations
 */

interface CustomLoaderProps {
  src?: string;
  width?: string | number;
  height?: string | number;
  quality?: string | number;
  crop?: boolean;
  cropType?: string;
  cropHeight?: string;
  cropWidth?: string;
  xPosition?: string;
  xPositionAdvanced?: string;
  yPosition?: string;
  yPositionAdvanced?: string;
  objectPosition?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectPosition'];
  autoPosition?: boolean;
  faceDetection?: boolean;
  scale?: string | number;
}

// use these in other places instead of defining the same types each time
export interface CropProps {
  enableCropping?: boolean;
  orientation?: string;
  cropType?: string;
  xPosition?: string;
  xPositionAdvanced?: string;
  yPosition?: string;
  yPositionAdvanced?: string;
  cropWidth?: string;
  cropHeight?: string;
  autoPosition?: boolean;
}

export const customLoader = ({
  src,
  crop,
  scale,
  width,
  height,
  quality,
  cropType,
  cropWidth,
  yPosition,
  xPosition,
  cropHeight,
  faceDetection,
  objectPosition,
  xPositionAdvanced,
  yPositionAdvanced,
}: CustomLoaderProps) => {
  // default the URL to c_fill with supplied width and height
  const cloudinaryParams =
    width || height ? `/c_fill,w_${width},h_${height},q_${quality || 80},f_auto` : '';
  //if source is empty return the no image placeholder
  if (!src)
    return `https://assets.hiltonstatic.com/images${cloudinaryParams}/v1583957952/no-image/no-image.png`;

  let cMode = '';

  // if legacy object position fields are in use, set Object position
  if (objectPosition === 'center center') {
    objectPosition = ',g_center';
  } else if (objectPosition && typeof objectPosition == 'string') {
    const vert = objectPosition?.split(' ')[1];
    const horiz = objectPosition?.split(' ')[0];
    // set north / east / south / west
    const prefix = vert === 'top' ? 'north' : vert === 'bottom' ? 'south' : '';
    const suffix = horiz === 'left' ? 'west' : horiz === 'right' ? 'east' : '';
    objectPosition = prefix && suffix ? prefix + '_' + suffix : prefix + suffix;
    objectPosition = ',g_' + objectPosition;
  }

  // if the new image crop check field is enabled
  if (crop) {
    cMode = cropType ? 'c_' + cropType : 'c_fill';
    // reset width and height to the supplied cropWidth and cropHeight
    width = cropWidth || width;
    height = cropHeight || height;
    // set north / east / south / west
    if (xPosition === 'center' && yPosition === 'center') {
      objectPosition = ',g_center';
    } else {
      if (xPositionAdvanced || yPositionAdvanced) {
        const prefix = yPositionAdvanced ? ',y_' + yPositionAdvanced : '';
        const suffix = xPositionAdvanced ? ',x_' + xPositionAdvanced : '';
        objectPosition = prefix || suffix ? prefix + suffix : '';
      } else if (xPosition || yPosition) {
        const prefix = yPosition === 'top' ? 'north' : yPosition === 'bottom' ? 'south' : '';
        const suffix = xPosition === 'left' ? 'west' : xPosition === 'right' ? 'east' : '';
        objectPosition = prefix && suffix ? ',g_' + prefix + '_' + suffix : ',g_' + prefix + suffix;
        if (objectPosition === ',g_') {
          objectPosition = ',g_center';
        }
      } else {
        objectPosition = ',g_auto';
      }
    }
  } else {
    cMode = 'c_fill';
    if (!objectPosition) {
      if (faceDetection) {
        objectPosition = ',g_face';
      } else {
        objectPosition = ',g_auto';
      }
    }
  }

  const baseUrls =
    'https://assets.hiltonstatic.com/images/,https://assets.hiltonstatic.com/hilton-asset-cache/image/upload/'.split(
      ','
    );

  if (!src.startsWith(baseUrls[0]) && !src.startsWith(baseUrls[1])) return '';

  for (let i = 0; i <= baseUrls.length; i++) {
    if (src.startsWith(baseUrls[i])) {
      const urlSplit = src.split(baseUrls[i]);
      const urlNoParams = removeTransforms(urlSplit[1]);
      // since the width and height are being overridden for image crop settings,
      // scale allows you to set the width of the actual image to prevent massive, unoptimized images
      const cTransformations = `${cMode},w_${width || 2560}${height ? `,h_${height}` : ''},q_${
        quality || 80
      }${scale && scale < width ? `/c_scale,w_${scale}` : ''},f_auto${objectPosition}/`;
      const fullCloudinaryUrl = baseUrls[i] + cTransformations + urlNoParams;
      return fullCloudinaryUrl;
    }
  }
  return '';
};

export function CroppedImage({
  src,
  height,
  width,
  objectPosition,
  quality,
  ...rest
}: ImageProps & { src: string }) {
  // let srcUrl = customLoader({ src, width, height });
  const cloudinaryParams =
    width || height ? `/c_fill,w_${width},h_${height},q_60,f_auto,g_auto` : '';
  if (!src) {
    src = `https://assets.hiltonstatic.com/images${cloudinaryParams}/v1583957952/no-image/no-image.png`;
  }
  return (
    <Image
      loader={() => {
        return customLoader({
          src,
          width,
          height,
          objectPosition,
          quality,
        });
      }}
      src={src}
      width={width}
      height={height}
      objectPosition={objectPosition}
      data-testid="cropped-img"
      {...rest}
    />
  );
}

function removeTransforms(url: string) {
  return url?.replace(/^((c_fill|w_-?[0-9]{1,5}|h_-?[0-9]{1,5}|q_[0-9]{1,3}|f_auto)(,|\/))+/gm, '');
}
