import type { TFunction } from 'i18next';
import type { HeroImageFlexProps } from '../hero-image.types';
import type { WordpressPageInfoQuery } from 'apps/dx-curated-ui/generated/wp';

/* istanbul ignore next */
export function heroPropMapper(
  componentData: HeroImageFlexProps,
  globalData: NonNullable<
    NonNullable<WordpressPageInfoQuery['componentStyles']>['globalComponentSettings']
  >['globalComponentSettings'],
  t: TFunction,
  oscBookButtonStyle: string
) {
  return {
    imgSrc: componentData?.image?.sourceUrl || '',
    imgAlt: componentData?.image?.altText || '',
    imgPositioning: componentData?.imagePositioning || '',
    title: componentData?.title || '',
    subtitle: componentData?.subtitle || '',
    titleSize: componentData?.titleSize || 'Normal',
    subtitleSize: componentData?.subtitleSize || 'Normal',
    buttons: componentData.buttons,
    imageCarouselChoice: componentData?.imageCarouselChoice ?? false,
    imageCarousel: componentData?.imageCarousel || [],
    videoAutoplay: false,
    videoChoice: componentData?.videoChoice ?? false,
    videoMuteChoice: componentData?.videoMute ?? false,
    videoCtaPause: componentData?.videoCtaPause || t('pauseVideo'),
    videoCtaPlay: componentData?.videoCtaPlay || t('playVideo'),
    videoUrl: componentData?.videoUrl?.mediaItemUrl,
    videoQualityOverride: componentData?.videoQualityOverride,
    videoQualitySlider: componentData?.videoQualitySlider,
    logoSrc: componentData?.logoImage?.sourceUrl || '',
    logoSize: componentData?.logoImage?.mediaDetails,
    logoAlt: componentData?.logoImage?.altText || '',
    logoOverlayColour: componentData?.logoOverlayColour || '',
    logoOverlayOpacity: componentData?.logoOverlayOpacity,
    logoWidth: componentData?.logoWidth,
    logoHeight: componentData?.logoHeight,
    bookingWidgetChoice: componentData?.bookingWidgetChoice,
    bookingWidgetPosition: componentData?.bookingWidgetPosition,
    bookingWidgetMobile: componentData?.bookingWidgetMobile,
    instanceStyles: componentData?.mastheadComponentSettings,
    enableCropping: componentData?.enableCropping,
    cropType: componentData?.cropType || '',
    xPosition: componentData?.xPosition || '',
    xPositionAdvanced: componentData?.xPositionAdvanced || '',
    yPosition: componentData?.yPosition || '',
    yPositionAdvanced: componentData?.yPositionAdvanced || '',
    cropWidth: componentData?.cropWidth || '',
    cropHeight: componentData?.cropHeight || '',
    autoPosition: componentData?.autoPosition,
    mobileCroppingXPosition: componentData?.mobileCroppingXPosition || '',
    mobileCroppingXPositionAdvanced: componentData?.mobileCroppingXPositionAdvanced || '',
    mobileCroppingYPosition: componentData?.mobileCroppingYPosition || '',
    mobileCroppingYPositionAdvanced: componentData?.mobileCroppingYPositionAdvanced || '',
    mobileCroppingCropWidth: componentData?.mobileCroppingCropWidth || '',
    mobileCroppingCropHeight: componentData?.mobileCroppingCropHeight || '',
    mobileCroppingAutoPosition: componentData?.mobileCroppingAutoPosition,
    globalStyles: globalData?.mastheadComponentSettings,
    oscBookButtonStyle: oscBookButtonStyle || '',
    captionTracks: componentData?.captionTracks,
    transcriptTracks: componentData?.transcriptTracks,
    audioTracks: componentData?.audioTracks,
  };
}
