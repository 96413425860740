import type { FlexComponents } from 'apps/dx-curated-ui';
import type { WrappedHeadingProps } from './heading-wrap';

export function useHeadingLevel(compIdx: number, compsArr?: FlexComponents) {
  let headingLevel: WrappedHeadingProps['header'] = 'h2';

  if (compIdx === 0) {
    headingLevel = 'h1';
  } else if (compIdx) {
    const compsBefore = compsArr?.slice(0, compIdx);
    const h1Comps = ['addressBar', 'hotelTitle', 'introduction', 'retailFlyout', 'retailRail'];

    const hasH1CompBefore = compsBefore?.some((c) =>
      h1Comps.some(
        (comp) =>
          c?.__typename?.includes(`${comp.charAt(0).toUpperCase()}${comp.slice(1)}`) &&
          !c?.[`${comp}Settings`]?.showHide &&
          !c?.[`${comp}ComponentSettings`]?.showHide
      )
    );
    if (!hasH1CompBefore && compsBefore?.length) headingLevel = 'h1';
  }

  return headingLevel;
}
