/* eslint-disable complexity */
import type { ChangeEvent } from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { ShopForm, ShopFormDates, ShopFormRooms, ShopFormSpecialRates } from '@dx-ui/osc-shop-form';
import { FormConnector } from '@dx-ui/osc-form';
import type { ShopFormRoom } from '@dx-ui/osc-rooms';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { format, addDays } from 'date-fns';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { Caret, CloseCircle } from '@curated-property/icon-list';
import {
  FormChangeMonitor,
  SharedContext,
  getMinLengthOfStay,
  parseBookingDate,
} from '@curated-property/utils';
import { sendReward } from '@dx-ui/framework-conductrics';
import type { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { parseDeepLinkParams } from './parse-deep-link-params';
import { useSearchParams } from 'next/navigation';

export interface GroupBooking {
  url: string;
  isNewWindow?: boolean;
}

interface OscProps {
  ctyhocn?: string;
  defaultArrivalDate?: string;
  gmtHours?: number;
  brandCode?: string;
  currency?: string | 'USD';
  associatedHotels?: {
    hotel?: {
      name?: string;
      ctyhocn?: string;
      localization?: { gmtHours?: number; gmtHoursFmt?: number };
      shopAvailOptions?: {
        maxNumOccupants?: number;
        ageBasedPricing?: boolean;
        adultAge?: number;
        maxNumChildren?: number;
      };
    };
  }[];
  parentElement?: string;
  oscBookButtonStyle?: string;
  isInModal?: boolean;
  maxRooms?: number;
  groupBooking?: GroupBooking;
  hideGroupLink?: boolean;
  page?: NonNullable<Parameters<typeof bookUriBuilder>[0]['page']>;
}

const OscComposableSearchForm: React.FC<OscProps> = ({
  brandCode: brandCodeProp,
  ctyhocn,
  ...oscProps
}) => {
  const sharedContext = useContext(SharedContext);
  const [searchCtyhocn, setSearchCtyhocn] = useState(ctyhocn);
  const [isPropertyGroup, setIsPropertyGroup] = useState(false);
  const [selectEntered, setSelectEntered] = useState('');
  const [showResetMessage, setShowResetMessage] = useState(false);
  const language = useRouter().locale || 'en';
  const readOnlyParams = useSearchParams();
  const { t } = useTranslation();

  const brandCode = brandCodeProp || 'HI';
  const associatedHotels = oscProps?.associatedHotels?.length || 0;

  const shopFormDeepLinkProps = parseDeepLinkParams(
    new URLSearchParams(readOnlyParams),
    brandCode,
    getMinLengthOfStay(searchCtyhocn)
  );

  const gmtHours =
    oscProps?.gmtHours ??
    (associatedHotels > 0
      ? oscProps?.associatedHotels?.[0]?.hotel?.localization?.gmtHours
      : undefined);

  const arrivalDate =
    shopFormDeepLinkProps?.dates?.arrivalDate?.toISOString() ?? oscProps?.defaultArrivalDate;

  const startDate = parseBookingDate(gmtHours, arrivalDate);

  let nextDayDate: Date | string = addDays(startDate, getMinLengthOfStay(searchCtyhocn));
  const nowDate: Date | string = format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  nextDayDate = format(nextDayDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

  const handleFormSubmit: React.ComponentProps<typeof ShopForm>['onSubmit'] = ({ url }) => {
    if (oscProps?.isInModal) {
      sendReward('EngageCheckRatesCTAOnModal');
    } else {
      sendReward('userClickedBookButtonCurated');
    }

    if (isPropertyGroup === true) {
      const ctyhocnRegex = /ctyhocn=[A-Z]*&/gm;
      url = url.replace(ctyhocnRegex, `ctyhocn=${searchCtyhocn?.toUpperCase()}&`);
    }

    window.open(`https://www.hilton.com${url}`, '_blank', 'noopener noreferrer');
  };

  const bookButtonLogic = useCallback(() => {
    const bookBtn = oscProps?.parentElement
      ? document?.querySelector(
          `${oscProps?.parentElement} .osc-search-content-wrapper button[type="submit"]`
        )
      : document?.querySelector(`.osc-search-content-wrapper button[type="submit"]`);
    if (associatedHotels > 0) {
      if (selectEntered === '') {
        bookBtn?.setAttribute('disabled', 'true');
      } else {
        bookBtn?.removeAttribute('disabled');
      }
    }
  }, [oscProps, associatedHotels, selectEntered]);

  const addClassnamesToButtons = () => {
    document.querySelectorAll('.shop-form-btn').forEach((el) => {
      el.classList.add('btn', 'btn-lg', 'btn-text-outline');
    });
  };

  useEffect(() => {
    if (associatedHotels > 0) {
      setIsPropertyGroup(true);
    }
    bookButtonLogic();
  }, [ctyhocn, selectEntered, associatedHotels, bookButtonLogic]);

  useEffect(() => {
    setTimeout(() => {
      bookButtonLogic();
      addClassnamesToButtons();
    }, 50);
  });

  const getSelectedProperty = (ctyhocn: string): OscProps['associatedHotels'][0] =>
    oscProps?.associatedHotels?.find((h) => h?.hotel?.ctyhocn === ctyhocn);

  const selectedProperty = getSelectedProperty(searchCtyhocn);

  const adultAge =
    selectedProperty?.hotel?.shopAvailOptions?.adultAge ??
    sharedContext?.dxGql?.hotel?.shopAvailOptions?.adultAge ??
    undefined;

  const ageRange =
    (selectedProperty?.hotel?.shopAvailOptions?.ageBasedPricing ||
      sharedContext?.dxGql?.hotel?.shopAvailOptions?.ageBasedPricing) &&
    adultAge
      ? {
          max: adultAge,
          min: 0,
        }
      : undefined;
  const agesRequired = !!ageRange;
  const adultsOnly =
    selectedProperty?.hotel?.shopAvailOptions?.maxNumChildren === 0 ||
    sharedContext?.dxGql?.hotel?.shopAvailOptions?.maxNumChildren === 0;
  const maxOccupants =
    selectedProperty?.hotel?.shopAvailOptions?.maxNumOccupants ??
    sharedContext?.dxGql?.hotel?.shopAvailOptions?.maxNumOccupants;

  const rooms =
    shopFormDeepLinkProps?.rooms && shopFormDeepLinkProps.rooms?.length > 0
      ? shopFormDeepLinkProps.rooms
      : [
          {
            adults: sharedContext?.allInclusive ? 2 : 1,
            children: [],
          },
        ];

  // when we don't have deep linked date params, do we default to flex dates or no flex dates?
  const dates =
    (shopFormDeepLinkProps?.dates?.arrivalDate && shopFormDeepLinkProps?.dates?.departureDate) ||
    shopFormDeepLinkProps?.dates?.datesFlex
      ? shopFormDeepLinkProps.dates
      : {
          arrivalDate: new Date(nowDate),
          datesFlex: false,
          departureDate: new Date(nextDayDate),
        };

  const displayCurrency =
    (shopFormDeepLinkProps?.additionalQs?.displayCurrency as string) ?? oscProps?.currency;

  const PropertySwitch = ({ formState }: { formState: UseFormReturn }) => {
    const roomsState = formState?.getValues()?.rooms;

    const handlePropertyChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setSearchCtyhocn(e.target.value);
      setSelectEntered(e.target.value);

      const curProperty = getSelectedProperty(e.target.value);
      const curNumOccupants =
        roomsState?.reduce((total: number, room: ShopFormRoom) => {
          return total + room?.adults + (room?.children?.length || 0);
        }, 0) || 0;

      if (
        JSON.stringify(roomsState) !== JSON.stringify(rooms) &&
        curNumOccupants > curProperty?.hotel?.shopAvailOptions?.maxNumOccupants
      ) {
        formState?.reset({ ...formState?.getValues(), rooms }, { keepDefaultValues: true });
        setShowResetMessage(true);
      } else if (showResetMessage) setShowResetMessage(false);
    };

    return (
      <fieldset data-testid="hotelSelector" className="w-full shrink">
        <OscComposableWidgetLabel text="Resorts" for="oscHotelSelect" />
        <div className="relative">
          <select
            className="bg-bg mr-1 w-full min-w-[200px] shrink rounded border border-solid px-3 py-1 pr-5 text-sm font-bold text-black"
            onChange={handlePropertyChange}
            name="oscHotelSelect"
            id="oscHotelSelect"
            data-testid="hotelSelectDropdown"
            data-osc-product="osc-hotel-select"
            value={searchCtyhocn}
            required
          >
            <option value="" selected>
              Select a Resort
            </option>
            {oscProps?.associatedHotels?.map((i, e) => {
              return (
                <option value={i?.hotel?.ctyhocn} key={`option${e}`}>
                  {i?.hotel?.name}
                </option>
              );
            })}
          </select>
          <Caret className="pointer-events-none absolute inset-y-0 right-[12px] my-auto w-2" />
        </div>
      </fieldset>
    );
  };

  return (
    <FormChangeMonitor onAny={() => sendReward('editCuratedSearchWidget')}>
      <ShopForm
        key={btoa(encodeURI(JSON.stringify(shopFormDeepLinkProps)))}
        itemRef=""
        additionalQSParameters={{
          displayCurrency,
          requestedRatesOnly: false,
          specPlan: [],
        }}
        cta={t('oscComposableSearch.bookLabel')}
        defaultValues={{
          rooms,
          brandCode,
          query: searchCtyhocn,
          dates,
          // @todo: fix
          numAttendees: 0,
          specialRates: shopFormDeepLinkProps.specialRates,
        }}
        targetOHWPage="book"
        submitOpensNewTab={true}
        language={language}
        onSubmit={handleFormSubmit}
        className={isPropertyGroup ? 'is-property-group' : ''}
        wrapperClassName={cx(
          'osc-search-content-wrapper flex flex-wrap space-y-4 lg:flex-nowrap lg:space-x-4 lg:space-y-0 lg:px-4 lg:rtl:space-x-reverse [&>div:last-child]:max-w-full',
          isPropertyGroup
            ? 'items-start justify-between md:justify-center lg:justify-between'
            : 'items-center justify-between'
        )}
        buttonClassName={cx(
          'btn btn-lg md:btn-base lg:btn-lg w-full',
          !oscProps?.oscBookButtonStyle ? ` btn-primary ` : ` btn-${oscProps?.oscBookButtonStyle} `
        )}
      >
        {isPropertyGroup && (
          <FormConnector>
            {(formState) => {
              return <PropertySwitch formState={formState} />;
            }}
          </FormConnector>
        )}
        <fieldset
          className={cx(
            'flex flex-col items-center justify-center lg:justify-start',
            !isPropertyGroup ? 'w-full' : 'm-auto mt-4 md:mx-1'
          )}
        >
          {isPropertyGroup && <OscComposableWidgetLabel text="Stay Dates" className="pl-3" />}
          <ShopFormDates
            language={language}
            flexDatesLabel={t('oscComposableSearch.shopByPrice')}
          />
        </fieldset>
        <div className="flex w-full gap-2 lg:gap-5">
          <fieldset className="flex-1">
            {isPropertyGroup && <OscComposableWidgetLabel text="Rooms & Guests" />}
            <ShopFormRooms
              ageRange={ageRange}
              agesRequired={agesRequired}
              adultAge={adultAge}
              isAdultsOnly={adultsOnly}
              maxOccupants={maxOccupants}
              maxRooms={oscProps?.maxRooms}
              occupancyLimitMessage={t('occupancy.occupancyLimitMessage')}
              tenPlusLinkProps={oscProps?.groupBooking}
              hideGroupLink={oscProps?.hideGroupLink}
            />
          </fieldset>
          <fieldset className="flex-1">
            {isPropertyGroup && <OscComposableWidgetLabel text="Rates & Codes" />}
            <ShopFormSpecialRates />
          </fieldset>
        </div>
      </ShopForm>
      {showResetMessage && (
        <div
          className={cx('bg-primary text-bg 2xl:mt-5.5 mt-3 flex px-5 py-2 lg:mx-4', {
            '2xl:mx-0 2xl:w-full': !oscProps?.isInModal,
          })}
        >
          <CloseCircle
            className="fill-primary mr-2 w-5 min-w-5"
            backgroundColor="currentColor"
            fillColor="primary"
          />
          <span>{t('oscComposableSearch.resetRooms', { count: maxOccupants })}</span>
        </div>
      )}
    </FormChangeMonitor>
  );
};

interface LabelProps {
  for?: string;
  text?: string;
  className?: string;
}

function OscComposableWidgetLabel(props: LabelProps) {
  return (
    <label htmlFor={props?.for} className={`block h-6 pb-2 text-sm font-bold ${props?.className}`}>
      {props?.text}
    </label>
  );
}

export default OscComposableSearchForm;
