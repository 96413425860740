import { lonnmnd, orlemqq } from '../../../component-overrides';
import { getStaticPaths, getStaticProps } from '../../../utils/static-paths-and-props-functions';
import type { PageProps } from '../../../mapping/default-props-to-components';
import { defaultComponentMapping } from '../../../mapping/default-props-to-components';
import type { WordpressPageInfoQuery } from '../../../generated/wp';
import type { HeroImageFlexProps } from 'libs/curated/shared-components/src/lib/hero-image/hero-image.types';
import type { SpacerProps } from 'libs/curated/shared-components/src/lib/spacer';

const hotelOverrides = {
  lonnmnd: lonnmnd.default,
  orlemqq: orlemqq.default,
};

export default function Slug(props) {
  const ctyhocnKey = props.ctyhocn.toLowerCase();
  const thisHotelComponentOverrides = hotelOverrides[ctyhocnKey] || {};
  const Comp = makeSlugComponent(thisHotelComponentOverrides);
  return <Comp {...props} googleMapsKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY} />;
}

/** Moved from Shared-Pages */
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
export type ExtractUnion<U, T> = Extract<ArrayElement<U>, T>;
type FlexComponent = { __typename: string | undefined } | undefined;
export type SlugConfig<
  CT extends FlexComponent[] | undefined,
  PageProps,
  T extends string = CT[0]['__typename']
> = {
  [P in T]: (
    props: ExtractUnion<CT, { __typename: P }>,
    pageProps: PageProps,
    key: number
  ) => JSX.Element;
};

export type SlugProps = PageProps & {
  googleMapsKey: string;
  isPreview: boolean;
};

export function makeSlugComponent<WP extends WordpressPageInfoQuery>(
  overrides?: Partial<
    SlugConfig<NonNullable<NonNullable<WP['page']>['acfFlexible']>['flexibleComponents'], PageProps>
  >
) {
  const config = { ...defaultComponentMapping, ...overrides };

  return function Slug(props: SlugProps) {
    // todo this path must be known to work
    const flexFields = props?.wpPageInfo?.page?.acfFlexible?.flexibleComponents || [];

    // Adds spacer at top of page if Hero isn't used
    if (flexFields?.length) {
      let idx = 0;
      let firstComp: FlexComponent & HeroImageFlexProps & SpacerProps = flexFields?.[idx];
      while (
        (firstComp?.__typename === 'Page_Acfflexible_FlexibleComponents_RetailFlyout' ||
          firstComp?.__typename === 'Page_Acfflexible_FlexibleComponents_RetailRail') &&
        idx < flexFields?.length - 1
      ) {
        idx++;
        firstComp = flexFields?.[idx];
      }
      if (
        (firstComp?.__typename !== 'Page_Acfflexible_FlexibleComponents_Masthead' ||
          firstComp?.mastheadComponentSettings?.showHide) &&
        (firstComp?.__typename !== 'Page_Acfflexible_FlexibleComponents_Spacer' ||
          firstComp?.spacerComponentSettings?.showHide)
      ) {
        const spacer = {
          __typename: 'Page_Acfflexible_FlexibleComponents_Spacer' as const,
          fieldGroupName: 'Page_Acfflexible_FlexibleComponents_Spacer',
        };
        flexFields.unshift(spacer);
      }
    }

    const contents = (
      <div id="main">
        {flexFields?.map((item: { __typename: string }, index) => {
          return config?.[item?.__typename]?.(item, props, index) as JSX.Element;
        })}
      </div>
    );

    return (
      <>
        {contents}
        {props.isPreview && (
          <div className="fixed bottom-0 px-4 py-2">
            <a
              href="/api/exit-preview"
              className="inline-block rounded-sm border border-bg bg-primary p-4 font-bold text-bg shadow-lg"
            >
              Exit Preview Mode
            </a>
          </div>
        )}
      </>
    );
  };
}

export { getStaticPaths, getStaticProps };
