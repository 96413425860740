export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ActivitiesIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the ActivitiesToRevisionConnection connection */
export type ActivitiesToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  Pg = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CalendarIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the CalendarToRevisionConnection connection */
export type CalendarToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** The status of the comment object. */
export enum CommentStatusEnum {
  /** Comments with the Approved status */
  Approve = 'APPROVE',
  /** Comments with the Unapproved status */
  Hold = 'HOLD',
  /** Comments with the Spam status */
  Spam = 'SPAM',
  /** Comments with the Trash status */
  Trash = 'TRASH',
}

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = 'COMMENT_AGENT',
  /** Order by approval status of the comment. */
  CommentApproved = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  CommentAuthor = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  CommentContent = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  CommentDate = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  CommentId = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = 'COMMENT_IN',
  /** Order by the comment karma score. */
  CommentKarma = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  CommentParent = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  CommentPostId = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = 'COMMENT_TYPE',
  /** Order by the user ID. */
  UserId = 'USER_ID',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  Activities = 'ACTIVITIES',
  /** The Type of Content object */
  Attachment = 'ATTACHMENT',
  /** The Type of Content object */
  Calendar = 'CALENDAR',
  /** The Type of Content object */
  GraphqlDocument = 'GRAPHQL_DOCUMENT',
  /** The Type of Content object */
  HtmlMenu = 'HTML_MENU',
  /** The Type of Content object */
  MediaBlocks = 'MEDIA_BLOCKS',
  /** The Type of Content object */
  Page = 'PAGE',
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Recurringevents = 'RECURRINGEVENTS',
  /** The Type of Content object */
  Rooms = 'ROOMS',
  /** The Type of Content object */
  Specialoffers = 'SPECIALOFFERS',
  /** The Type of Content object */
  Weddingbookings = 'WEDDINGBOOKINGS',
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the content type. */
  Name = 'NAME',
}

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the GraphqlDocumentGroup taxonomy. */
export enum ContentTypesOfGraphqlDocumentGroupEnum {
  /** The Type of Content object */
  GraphqlDocument = 'GRAPHQL_DOCUMENT',
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Specialoffers = 'SPECIALOFFERS',
}

/** Input for the createActivities mutation. */
export type CreateActivitiesInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createCalendar mutation. */
export type CreateCalendarInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createCategory mutation. */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the category object */
  description?: Maybe<Scalars['String']>;
  /** The name of the category object to mutate */
  name: Scalars['String'];
  /** The ID of the category that should be set as the parent */
  parentId?: Maybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createComment mutation. */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved?: Maybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: Maybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: Maybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: Maybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** Parent comment ID of current comment. */
  parent?: Maybe<Scalars['ID']>;
  /** The approval status of the comment */
  status?: Maybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: Maybe<Scalars['String']>;
};

/** Input for the createGraphqlDocumentGroup mutation. */
export type CreateGraphqlDocumentGroupInput = {
  /** The slug that the graphql_document_group will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the graphql_document_group object */
  description?: Maybe<Scalars['String']>;
  /** The name of the graphql_document_group object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createGraphqlDocument mutation. */
export type CreateGraphqlDocumentInput = {
  /** Alias names for saved GraphQL query documents */
  alias?: Maybe<Array<Scalars['String']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** Description for the saved GraphQL document */
  description?: Maybe<Scalars['String']>;
  /** Allow, deny or default access grant for specific query */
  grant?: Maybe<Scalars['String']>;
  /** Set connections between the graphqlDocument and graphqlDocumentGroups */
  graphqlDocumentGroups?: Maybe<GraphqlDocumentGraphqlDocumentGroupsInput>;
  /** HTTP Cache-Control max-age directive for a saved GraphQL document */
  maxAgeHeader?: Maybe<Scalars['Int']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createHtml_menu mutation. */
export type CreateHtml_MenuInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createMediaItem mutation. */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: Maybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: Maybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: Maybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: Maybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: Maybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: Maybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: Maybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: Maybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: Maybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: Maybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: Maybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createMedia_block mutation. */
export type CreateMedia_BlockInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createPage mutation. */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createPostFormat mutation. */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: Maybe<Scalars['String']>;
  /** The name of the post_format object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createPost mutation. */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: Maybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The ping status for the object */
  pingStatus?: Maybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: Maybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: Maybe<PostTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for the createRecurringevent mutation. */
export type CreateRecurringeventInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createRoom mutation. */
export type CreateRoomInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createSpecialoffer mutation. */
export type CreateSpecialofferInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the specialoffer and tags */
  tags?: Maybe<SpecialofferTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createTag mutation. */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: Maybe<Scalars['String']>;
  /** The name of the post_tag object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createUser mutation. */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: Maybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: Maybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: Maybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** User's locale. */
  locale?: Maybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: Maybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: Maybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: Maybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: Maybe<Scalars['String']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: Maybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: Maybe<Scalars['String']>;
};

/** Input for the createWeddingbooking mutation. */
export type CreateWeddingbookingInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day?: Maybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: Maybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: Maybe<Scalars['Int']>;
};

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after?: Maybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: Maybe<DateInput>;
  /** Column to query against */
  column?: Maybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: Maybe<Scalars['String']>;
  /** Day of the month (from 1 to 31) */
  day?: Maybe<Scalars['Int']>;
  /** Hour (from 0 to 23) */
  hour?: Maybe<Scalars['Int']>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: Maybe<Scalars['Boolean']>;
  /** Minute (from 0 to 59) */
  minute?: Maybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: Maybe<Scalars['Int']>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: Maybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: Maybe<Scalars['Int']>;
  /** Week of the year (from 0 to 53) */
  week?: Maybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: Maybe<Scalars['Int']>;
};

/** Input for the deleteActivities mutation. */
export type DeleteActivitiesInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the activities to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteCalendar mutation. */
export type DeleteCalendarInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the calendar to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteCategory mutation. */
export type DeleteCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the category to delete */
  id: Scalars['ID'];
};

/** Input for the deleteComment mutation. */
export type DeleteCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The deleted comment ID */
  id: Scalars['ID'];
};

/** Input for the deleteGraphqlDocumentGroup mutation. */
export type DeleteGraphqlDocumentGroupInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the graphqlDocumentGroup to delete */
  id: Scalars['ID'];
};

/** Input for the deleteGraphqlDocument mutation. */
export type DeleteGraphqlDocumentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the graphqlDocument to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteHtml_menu mutation. */
export type DeleteHtml_MenuInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the html_menu to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteMediaItem mutation. */
export type DeleteMediaItemInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the mediaItem to delete */
  id: Scalars['ID'];
};

/** Input for the deleteMedia_block mutation. */
export type DeleteMedia_BlockInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the media_block to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deletePage mutation. */
export type DeletePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the page to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deletePostFormat mutation. */
export type DeletePostFormatInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the postFormat to delete */
  id: Scalars['ID'];
};

/** Input for the deletePost mutation. */
export type DeletePostInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the post to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteRecurringevent mutation. */
export type DeleteRecurringeventInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the recurringevent to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteRoom mutation. */
export type DeleteRoomInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the room to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteSpecialoffer mutation. */
export type DeleteSpecialofferInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the specialoffer to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Input for the deleteTag mutation. */
export type DeleteTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the tag to delete */
  id: Scalars['ID'];
};

/** Input for the deleteUser mutation. */
export type DeleteUserInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the user you want to delete */
  id: Scalars['ID'];
  /** Reassign posts and links to new User ID. */
  reassignId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteWeddingbooking mutation. */
export type DeleteWeddingbookingInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the weddingbooking to delete */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
};

/** Set relationships between the graphqlDocument to graphqlDocumentGroups */
export type GraphqlDocumentGraphqlDocumentGroupsInput = {
  /** If true, this will append the graphqlDocumentGroup to existing related graphqlDocumentGroups. If false, this will replace existing relationships. Default true. */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<GraphqlDocumentGraphqlDocumentGroupsNodeInput>>>;
};

/** List of graphqlDocumentGroups to connect the graphqlDocument to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type GraphqlDocumentGraphqlDocumentGroupsNodeInput = {
  /** The description of the graphqlDocumentGroup. This field is used to set a description of the graphqlDocumentGroup if a new one is created during the mutation. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the graphqlDocumentGroup. If present, this will be used to connect to the graphqlDocument. If no existing graphqlDocumentGroup exists with this ID, no connection will be made. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the graphqlDocumentGroup. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: Maybe<Scalars['String']>;
  /** The slug of the graphqlDocumentGroup. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentGroupIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the GraphqlDocumentGroupToContentNodeConnection connection */
export type GraphqlDocumentGroupToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfGraphqlDocumentGroupEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the GraphqlDocumentGroupToGraphqlDocumentConnection connection */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the GraphqlDocumentToGraphqlDocumentGroupConnection connection */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the GraphqlDocumentToTermNodeConnection connection */
export type GraphqlDocumentToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Html_MenuIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the Html_menuToRevisionConnection connection */
export type Html_MenuToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the login mutation. */
export type LoginInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The plain-text password for the user logging in. */
  password: Scalars['String'];
  /** The username used for login. Typically a unique or email address depending on specific configuration */
  username: Scalars['String'];
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a media item by its source url */
  SourceUrl = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  Large = 'LARGE',
  /** MediaItem with the medium size */
  Medium = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MediumLarge = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  Thumbnail = 'THUMBNAIL',
  /** MediaItem with the venue-carousel-desktop size */
  VenueCarouselDesktop = 'VENUE_CAROUSEL_DESKTOP',
  /** MediaItem with the venue-carousel-mobile size */
  VenueCarouselMobile = 'VENUE_CAROUSEL_MOBILE',
  /** MediaItem with the venue-carousel-mobile-small size */
  VenueCarouselMobileSmall = 'VENUE_CAROUSEL_MOBILE_SMALL',
  /** MediaItem with the 360x348 size */
  '360X348' = '_360X348',
  /** MediaItem with the 540x300 size */
  '540X300' = '_540X300',
  /** MediaItem with the 540x390 size */
  '540X390' = '_540X390',
  /** MediaItem with the 540x485 size */
  '540X485' = '_540X485',
  /** MediaItem with the 720x348 size */
  '720X348' = '_720X348',
  /** MediaItem with the 720x505 size */
  '720X505' = '_720X505',
  /** MediaItem with the 720x1010 size */
  '720X1010' = '_720X1010',
  /** MediaItem with the 723x487 size */
  '723X487' = '_723X487',
  /** MediaItem with the 723x760 size */
  '723X760' = '_723X760',
  /** MediaItem with the 785x590 size */
  '785X590' = '_785X590',
  /** MediaItem with the 800x450 size */
  '800X450' = '_800X450',
  /** MediaItem with the 840x525 size */
  '840X525' = '_840X525',
  /** MediaItem with the 840x760 size */
  '840X760' = '_840X760',
  /** MediaItem with the 840x910 size */
  '840X910' = '_840X910',
  /** MediaItem with the 880x580 size */
  '880X580' = '_880X580',
  /** MediaItem with the 1023x520 size */
  '1023X520' = '_1023X520',
  /** MediaItem with the 1080x710 size */
  '1080X710' = '_1080X710',
  /** MediaItem with the 1536x1536 size */
  '1536X1536' = '_1536X1536',
  /** MediaItem with the 1920x800 size */
  '1920X800' = '_1920X800',
  /** MediaItem with the 1920x1080 size */
  '1920X1080' = '_1920X1080',
  /** MediaItem with the 2048x2048 size */
  '2048X2048' = '_2048X2048',
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Media_BlockIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the Media_blockToRevisionConnection connection */
export type Media_BlockToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: Maybe<Scalars['ID']>;
};

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Put the menu in the footer-navigation location */
  FooterNavigation = 'FOOTER_NAVIGATION',
  /** Put the menu in the language-navigation location */
  LanguageNavigation = 'LANGUAGE_NAVIGATION',
  /** Put the menu in the main-navigation location */
  MainNavigation = 'MAIN_NAVIGATION',
  /** Put the menu in the mini-navigation location */
  MiniNavigation = 'MINI_NAVIGATION',
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a menu node by the slug of menu location to which it is assigned */
  Location = 'LOCATION',
  /** Identify a menu node by its name */
  Name = 'NAME',
  /** Identify a menu node by its slug */
  Slug = 'SLUG',
}

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: Maybe<Scalars['ID']>;
};

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** application/java mime type. */
  ApplicationJava = 'APPLICATION_JAVA',
  /** application/msword mime type. */
  ApplicationMsword = 'APPLICATION_MSWORD',
  /** application/octet-stream mime type. */
  ApplicationOctetStream = 'APPLICATION_OCTET_STREAM',
  /** application/onenote mime type. */
  ApplicationOnenote = 'APPLICATION_ONENOTE',
  /** application/oxps mime type. */
  ApplicationOxps = 'APPLICATION_OXPS',
  /** application/pdf mime type. */
  ApplicationPdf = 'APPLICATION_PDF',
  /** application/rar mime type. */
  ApplicationRar = 'APPLICATION_RAR',
  /** application/rtf mime type. */
  ApplicationRtf = 'APPLICATION_RTF',
  /** application/ttaf+xml mime type. */
  ApplicationTtafXml = 'APPLICATION_TTAF_XML',
  /** application/vnd.apple.keynote mime type. */
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** application/vnd.apple.numbers mime type. */
  ApplicationVndAppleNumbers = 'APPLICATION_VND_APPLE_NUMBERS',
  /** application/vnd.apple.pages mime type. */
  ApplicationVndApplePages = 'APPLICATION_VND_APPLE_PAGES',
  /** application/vnd.ms-access mime type. */
  ApplicationVndMsAccess = 'APPLICATION_VND_MS_ACCESS',
  /** application/vnd.ms-excel mime type. */
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  /** application/vnd.ms-excel.addin.macroEnabled.12 mime type. */
  ApplicationVndMsExcelAddinMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.binary.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** application/vnd.ms-excel.template.macroEnabled.12 mime type. */
  ApplicationVndMsExcelTemplateMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-powerpoint mime type. */
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  /** application/vnd.ms-powerpoint.addin.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointAddinMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.presentation.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slideshow.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slide.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.template.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointTemplateMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-project mime type. */
  ApplicationVndMsProject = 'APPLICATION_VND_MS_PROJECT',
  /** application/vnd.ms-word.document.macroEnabled.12 mime type. */
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** application/vnd.ms-word.template.macroEnabled.12 mime type. */
  ApplicationVndMsWordTemplateMacroenabled_12 = 'APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12',
  /** application/vnd.ms-write mime type. */
  ApplicationVndMsWrite = 'APPLICATION_VND_MS_WRITE',
  /** application/vnd.ms-xpsdocument mime type. */
  ApplicationVndMsXpsdocument = 'APPLICATION_VND_MS_XPSDOCUMENT',
  /** application/vnd.oasis.opendocument.chart mime type. */
  ApplicationVndOasisOpendocumentChart = 'APPLICATION_VND_OASIS_OPENDOCUMENT_CHART',
  /** application/vnd.oasis.opendocument.database mime type. */
  ApplicationVndOasisOpendocumentDatabase = 'APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE',
  /** application/vnd.oasis.opendocument.formula mime type. */
  ApplicationVndOasisOpendocumentFormula = 'APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA',
  /** application/vnd.oasis.opendocument.graphics mime type. */
  ApplicationVndOasisOpendocumentGraphics = 'APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS',
  /** application/vnd.oasis.opendocument.presentation mime type. */
  ApplicationVndOasisOpendocumentPresentation = 'APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION',
  /** application/vnd.oasis.opendocument.spreadsheet mime type. */
  ApplicationVndOasisOpendocumentSpreadsheet = 'APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET',
  /** application/vnd.oasis.opendocument.text mime type. */
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** application/vnd.openxmlformats-officedocument.presentationml.presentation mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** application/vnd.openxmlformats-officedocument.presentationml.slide mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlide = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE',
  /** application/vnd.openxmlformats-officedocument.presentationml.slideshow mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** application/vnd.openxmlformats-officedocument.presentationml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.sheet mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.document mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE',
  /** application/wordperfect mime type. */
  ApplicationWordperfect = 'APPLICATION_WORDPERFECT',
  /** application/x-7z-compressed mime type. */
  ApplicationX_7ZCompressed = 'APPLICATION_X_7Z_COMPRESSED',
  /** application/x-gzip mime type. */
  ApplicationXGzip = 'APPLICATION_X_GZIP',
  /** application/x-tar mime type. */
  ApplicationXTar = 'APPLICATION_X_TAR',
  /** application/zip mime type. */
  ApplicationZip = 'APPLICATION_ZIP',
  /** audio/aac mime type. */
  AudioAac = 'AUDIO_AAC',
  /** audio/flac mime type. */
  AudioFlac = 'AUDIO_FLAC',
  /** audio/midi mime type. */
  AudioMidi = 'AUDIO_MIDI',
  /** audio/mpeg mime type. */
  AudioMpeg = 'AUDIO_MPEG',
  /** audio/ogg mime type. */
  AudioOgg = 'AUDIO_OGG',
  /** audio/wav mime type. */
  AudioWav = 'AUDIO_WAV',
  /** audio/x-matroska mime type. */
  AudioXMatroska = 'AUDIO_X_MATROSKA',
  /** audio/x-ms-wax mime type. */
  AudioXMsWax = 'AUDIO_X_MS_WAX',
  /** audio/x-ms-wma mime type. */
  AudioXMsWma = 'AUDIO_X_MS_WMA',
  /** audio/x-realaudio mime type. */
  AudioXRealaudio = 'AUDIO_X_REALAUDIO',
  /** image/avif mime type. */
  ImageAvif = 'IMAGE_AVIF',
  /** image/bmp mime type. */
  ImageBmp = 'IMAGE_BMP',
  /** image/gif mime type. */
  ImageGif = 'IMAGE_GIF',
  /** image/heic mime type. */
  ImageHeic = 'IMAGE_HEIC',
  /** image/heic-sequence mime type. */
  ImageHeicSequence = 'IMAGE_HEIC_SEQUENCE',
  /** image/heif mime type. */
  ImageHeif = 'IMAGE_HEIF',
  /** image/heif-sequence mime type. */
  ImageHeifSequence = 'IMAGE_HEIF_SEQUENCE',
  /** image/jpeg mime type. */
  ImageJpeg = 'IMAGE_JPEG',
  /** image/png mime type. */
  ImagePng = 'IMAGE_PNG',
  /** image/svg+xml mime type. */
  ImageSvgXml = 'IMAGE_SVG_XML',
  /** image/tiff mime type. */
  ImageTiff = 'IMAGE_TIFF',
  /** image/webp mime type. */
  ImageWebp = 'IMAGE_WEBP',
  /** image/x-icon mime type. */
  ImageXIcon = 'IMAGE_X_ICON',
  /** text/calendar mime type. */
  TextCalendar = 'TEXT_CALENDAR',
  /** text/css mime type. */
  TextCss = 'TEXT_CSS',
  /** text/csv mime type. */
  TextCsv = 'TEXT_CSV',
  /** text/plain mime type. */
  TextPlain = 'TEXT_PLAIN',
  /** text/richtext mime type. */
  TextRichtext = 'TEXT_RICHTEXT',
  /** text/tab-separated-values mime type. */
  TextTabSeparatedValues = 'TEXT_TAB_SEPARATED_VALUES',
  /** text/vtt mime type. */
  TextVtt = 'TEXT_VTT',
  /** video/3gpp mime type. */
  Video_3Gpp = 'VIDEO_3GPP',
  /** video/3gpp2 mime type. */
  Video_3Gpp2 = 'VIDEO_3GPP2',
  /** video/avi mime type. */
  VideoAvi = 'VIDEO_AVI',
  /** video/divx mime type. */
  VideoDivx = 'VIDEO_DIVX',
  /** video/mp4 mime type. */
  VideoMp4 = 'VIDEO_MP4',
  /** video/mpeg mime type. */
  VideoMpeg = 'VIDEO_MPEG',
  /** video/ogg mime type. */
  VideoOgg = 'VIDEO_OGG',
  /** video/quicktime mime type. */
  VideoQuicktime = 'VIDEO_QUICKTIME',
  /** video/webm mime type. */
  VideoWebm = 'VIDEO_WEBM',
  /** video/x-flv mime type. */
  VideoXFlv = 'VIDEO_X_FLV',
  /** video/x-matroska mime type. */
  VideoXMatroska = 'VIDEO_X_MATROSKA',
  /** video/x-ms-asf mime type. */
  VideoXMsAsf = 'VIDEO_X_MS_ASF',
  /** video/x-ms-wm mime type. */
  VideoXMsWm = 'VIDEO_X_MS_WM',
  /** video/x-ms-wmv mime type. */
  VideoXMsWmv = 'VIDEO_X_MS_WMV',
  /** video/x-ms-wmx mime type. */
  VideoXMsWmx = 'VIDEO_X_MS_WMX',
}

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = 'ASC',
  /** Sort the query result set in a descending order */
  Desc = 'DESC',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the PageToCommentConnection connection */
export type PageToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the PageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  Active = 'ACTIVE',
  /** The plugin is a drop-in plugin. */
  DropIn = 'DROP_IN',
  /** The plugin is currently inactive. */
  Inactive = 'INACTIVE',
  /** The plugin is a must-use plugin. */
  MustUse = 'MUST_USE',
  /** The plugin is technically active but was paused while loading. */
  Paused = 'PAUSED',
  /** The plugin was active recently. */
  RecentlyActive = 'RECENTLY_ACTIVE',
  /** The plugin has an upgrade available. */
  Upgrade = 'UPGRADE',
}

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<PostCategoriesNodeInput>>>;
};

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: Maybe<Scalars['String']>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  Raw = 'RAW',
  /** Provide the field value as rendered by WordPress. Default. */
  Rendered = 'RENDERED',
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = 'DATE',
  /** The most recent modification date of the comment. */
  Modified = 'MODIFIED',
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  CommentCount = 'COMMENT_COUNT',
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by title */
  Title = 'TITLE',
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
};

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<PostPostFormatsNodeInput>>>;
};

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostPostFormatsNodeInput = {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: Maybe<Scalars['String']>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: Maybe<Scalars['String']>;
};

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the draft status */
  Draft = 'DRAFT',
  /** Objects with the future status */
  Future = 'FUTURE',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the pending status */
  Pending = 'PENDING',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the publish status */
  Publish = 'PUBLISH',
  /** Objects with the request-completed status */
  RequestCompleted = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  RequestConfirmed = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  RequestFailed = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  RequestPending = 'REQUEST_PENDING',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<PostTagsNodeInput>>>;
};

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: Maybe<Scalars['String']>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the PostToCommentConnection connection */
export type PostToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the PostToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RecurringeventIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Input for the refreshJwtAuthToken mutation. */
export type RefreshJwtAuthTokenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A valid, previously issued JWT refresh token. If valid a new Auth token will be provided. If invalid, expired, revoked or otherwise invalid, a new AuthToken will not be provided. */
  jwtRefreshToken: Scalars['String'];
};

/** Input for the registerUser mutation. */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: Maybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: Maybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: Maybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** User's locale. */
  locale?: Maybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: Maybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: Maybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: Maybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: Maybe<Scalars['String']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: Maybe<Scalars['String']>;
  /** A string that contains the user's username. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: Maybe<Scalars['String']>;
};

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = 'OR',
}

/** Input for the resetUserPassword mutation. */
export type ResetUserPasswordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Password reset key */
  key?: Maybe<Scalars['String']>;
  /** The user's login (username). */
  login?: Maybe<Scalars['String']>;
  /** The new password. */
  password?: Maybe<Scalars['String']>;
};

/** Input for the restoreComment mutation. */
export type RestoreCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the comment to be restored */
  id: Scalars['ID'];
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RoomIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the RoomToRevisionConnection connection */
export type RoomToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToActivitiesConnection connection */
export type RootQueryToActivitiesConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToCalendarConnection connection */
export type RootQueryToCalendarConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToGraphqlDocumentConnection connection */
export type RootQueryToGraphqlDocumentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToGraphqlDocumentGroupConnection connection */
export type RootQueryToGraphqlDocumentGroupConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToHtml_menuConnection connection */
export type RootQueryToHtml_MenuConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMedia_blockConnection connection */
export type RootQueryToMedia_BlockConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** filters the menus by language */
  language?: Maybe<Scalars['String']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** filters the menu items by language */
  language?: Maybe<Scalars['String']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToPluginConnection connection */
export type RootQueryToPluginConnectionWhereArgs = {
  /** Show plugin based on a keyword search. */
  search?: Maybe<Scalars['String']>;
  /** Retrieve plugins where plugin status is in an array. */
  stati?: Maybe<Array<Maybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status?: Maybe<PluginStatusEnum>;
};

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToRecurringeventConnection connection */
export type RootQueryToRecurringeventConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToRevisionsConnection connection */
export type RootQueryToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToRoomConnection connection */
export type RootQueryToRoomConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToSpecialofferConnection connection */
export type RootQueryToSpecialofferConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** Array of userIds to exclude. */
  exclude?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The user login. */
  login?: Maybe<Scalars['String']>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user nicename. */
  nicename?: Maybe<Scalars['String']>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: Maybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: Maybe<Array<Maybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: Maybe<Array<Maybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: Maybe<Scalars['String']>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: Maybe<Array<Maybe<UsersConnectionSearchColumnEnum>>>;
};

/** Arguments for filtering the RootQueryToWeddingbookingConnection connection */
export type RootQueryToWeddingbookingConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The maximum timestamp to include */
  maxTimestamp?: Maybe<Scalars['Float']>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** The minimum timestamp to include */
  minTimestamp?: Maybe<Scalars['Float']>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** The exact timestamp to include */
  timestamp?: Maybe<Scalars['Float']>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Location in the document where the script to be loaded */
export enum ScriptLoadingGroupLocationEnum {
  /** A script to be loaded in document at right before the closing `<body>` tag */
  Footer = 'FOOTER',
  /** A script to be loaded in document `<head>` tag */
  Header = 'HEADER',
}

/** The strategy to use when loading the script */
export enum ScriptLoadingStrategyEnum {
  /** Use the script `async` attribute */
  Async = 'ASYNC',
  /** Use the script `defer` attribute */
  Defer = 'DEFER',
}

/** Input for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string that contains the user's username or email address. */
  username: Scalars['String'];
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SpecialofferIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the specialoffer to tags */
export type SpecialofferTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<SpecialofferTagsNodeInput>>>;
};

/** List of tags to connect the specialoffer to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type SpecialofferTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the tag. If present, this will be used to connect to the specialoffer. If no existing tag exists with this ID, no connection will be made. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: Maybe<Scalars['String']>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the SpecialofferToRevisionConnection connection */
export type SpecialofferToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the SpecialofferToTagConnection connection */
export type SpecialofferToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the SpecialofferToTermNodeConnection connection */
export type SpecialofferToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: Maybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the TagToSpecialofferConnection connection */
export type TagToSpecialofferConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  Category = 'CATEGORY',
  /** Taxonomy enum graphql_document_group */
  Graphqldocumentgroup = 'GRAPHQLDOCUMENTGROUP',
  /** Taxonomy enum post_format */
  Postformat = 'POSTFORMAT',
  /** Taxonomy enum post_tag */
  Tag = 'TAG',
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the taxonomy */
  Name = 'NAME',
}

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = 'COUNT',
  /** Order the connection by description. */
  Description = 'DESCRIPTION',
  /** Order the connection by name. */
  Name = 'NAME',
  /** Order the connection by slug. */
  Slug = 'SLUG',
  /** Order the connection by term group. */
  TermGroup = 'TERM_GROUP',
  /** Order the connection by term id. */
  TermId = 'TERM_ID',
  /** Order the connection by term order. */
  TermOrder = 'TERM_ORDER',
}

/** Input for the updateActivities mutation. */
export type UpdateActivitiesInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the activities object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateCalendar mutation. */
export type UpdateCalendarInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the calendar object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateCategory mutation. */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the category object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the category object to update */
  id: Scalars['ID'];
  /** The name of the category object to mutate */
  name?: Maybe<Scalars['String']>;
  /** The ID of the category that should be set as the parent */
  parentId?: Maybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updateComment mutation. */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved?: Maybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: Maybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: Maybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: Maybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the comment being updated. */
  id: Scalars['ID'];
  /** Parent comment ID of current comment. */
  parent?: Maybe<Scalars['ID']>;
  /** The approval status of the comment */
  status?: Maybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: Maybe<Scalars['String']>;
};

/** Input for the updateGraphqlDocumentGroup mutation. */
export type UpdateGraphqlDocumentGroupInput = {
  /** The slug that the graphql_document_group will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the graphql_document_group object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the graphqlDocumentGroup object to update */
  id: Scalars['ID'];
  /** The name of the graphql_document_group object to mutate */
  name?: Maybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updateGraphqlDocument mutation. */
export type UpdateGraphqlDocumentInput = {
  /** Alias names for saved GraphQL query documents */
  alias?: Maybe<Array<Scalars['String']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** Description for the saved GraphQL document */
  description?: Maybe<Scalars['String']>;
  /** Allow, deny or default access grant for specific query */
  grant?: Maybe<Scalars['String']>;
  /** Set connections between the graphqlDocument and graphqlDocumentGroups */
  graphqlDocumentGroups?: Maybe<GraphqlDocumentGraphqlDocumentGroupsInput>;
  /** The ID of the graphqlDocument object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** HTTP Cache-Control max-age directive for a saved GraphQL document */
  maxAgeHeader?: Maybe<Scalars['Int']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateHtml_menu mutation. */
export type UpdateHtml_MenuInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the html_menu object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateMediaItem mutation. */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: Maybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: Maybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: Maybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: Maybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: Maybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: Maybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: Maybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: Maybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars['ID'];
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: Maybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: Maybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: Maybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateMedia_block mutation. */
export type UpdateMedia_BlockInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the media_block object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updatePage mutation. */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the page object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updatePostFormat mutation. */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the postFormat object to update */
  id: Scalars['ID'];
  /** The name of the post_format object to mutate */
  name?: Maybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updatePost mutation. */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: Maybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: Maybe<Scalars['String']>;
  /** The ID of the post object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The ping status for the object */
  pingStatus?: Maybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: Maybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: Maybe<PostTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for the updateRecurringevent mutation. */
export type UpdateRecurringeventInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the recurringevent object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateRoom mutation. */
export type UpdateRoomInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the room object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateSettings mutation. */
export type UpdateSettingsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: Maybe<Scalars['String']>;
  /** Site tagline. */
  generalSettingsDescription?: Maybe<Scalars['String']>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail?: Maybe<Scalars['String']>;
  /** WordPress locale code. */
  generalSettingsLanguage?: Maybe<Scalars['String']>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: Maybe<Scalars['String']>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: Maybe<Scalars['String']>;
  /** Site title. */
  generalSettingsTitle?: Maybe<Scalars['String']>;
  /** Site URL. */
  generalSettingsUrl?: Maybe<Scalars['String']>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts?: Maybe<Scalars['Int']>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront?: Maybe<Scalars['Int']>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']>;
  /** What to show on the front page */
  readingSettingsShowOnFront?: Maybe<Scalars['String']>;
  /** Default post category. */
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']>;
};

/** Input for the updateSpecialoffer mutation. */
export type UpdateSpecialofferInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the specialoffer object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the specialoffer and tags */
  tags?: Maybe<SpecialofferTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateTag mutation. */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the tag object to update */
  id: Scalars['ID'];
  /** The name of the post_tag object to mutate */
  name?: Maybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updateUser mutation. */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim?: Maybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: Maybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: Maybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: Maybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The ID of the user */
  id: Scalars['ID'];
  /** User's Jabber account. */
  jabber?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** User's locale. */
  locale?: Maybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: Maybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: Maybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: Maybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: Maybe<Scalars['String']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: Maybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: Maybe<Scalars['String']>;
};

/** Input for the updateWeddingbooking mutation. */
export type UpdateWeddingbookingInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: Maybe<Scalars['String']>;
  /** The ID of the weddingbooking object */
  id: Scalars['ID'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: Maybe<Scalars['Boolean']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The Email of the User */
  Email = 'EMAIL',
  /** The hashed Global ID */
  Id = 'ID',
  /** The slug of the User */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
  /** The username the User uses to login with */
  Username = 'USERNAME',
}

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  Author = 'AUTHOR',
  /** User role with specific capabilities */
  Contributor = 'CONTRIBUTOR',
  /** User role with specific capabilities */
  Editor = 'EDITOR',
  /** User role with specific capabilities */
  HotelUser = 'HOTEL_USER',
  /** User role with specific capabilities */
  Subscriber = 'SUBSCRIBER',
}

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** One or more Comment Statuses to limit results by */
  statusIn?: Maybe<Array<Maybe<CommentStatusEnum>>>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the UserToRevisionsConnection connection */
export type UserToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = 'DISPLAY_NAME',
  /** Order by email address */
  Email = 'EMAIL',
  /** Order by login */
  Login = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = 'LOGIN_IN',
  /** Order by nice name */
  NiceName = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = 'NICE_NAME_IN',
  /** Order by registration date */
  Registered = 'REGISTERED',
  /** Order by URL */
  Url = 'URL',
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
};

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = 'EMAIL',
  /** The globally unique ID. */
  Id = 'ID',
  /** The username the User uses to login with. */
  Login = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = 'NICENAME',
  /** The URL of the user's website. */
  Url = 'URL',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum WeddingbookingIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the WeddingbookingToRevisionConnection connection */
export type WeddingbookingToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

export type NavFragmentFragment = {
  __typename?: 'RootQuery';
  mainNav?: Maybe<{
    __typename?: 'RootQueryToMenuItemConnection';
    edges: Array<{
      __typename?: 'RootQueryToMenuItemConnectionEdge';
      node: {
        __typename?: 'MenuItem';
        label?: Maybe<string>;
        databaseId: number;
        path?: Maybe<string>;
        url?: Maybe<string>;
        label_noTx?: Maybe<string>;
        NoTranslate?: Maybe<{ __typename?: 'MenuItem_Notranslate'; noTx?: Maybe<boolean> }>;
        connectedNode?: Maybe<{
          __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
          node:
            | { __typename?: 'Activities'; databaseId: number }
            | { __typename?: 'Calendar'; databaseId: number }
            | { __typename?: 'Category'; databaseId: number }
            | { __typename?: 'Html_menu'; databaseId: number }
            | { __typename?: 'Media_block'; databaseId: number }
            | { __typename?: 'Page'; databaseId: number }
            | { __typename?: 'Post'; databaseId: number }
            | { __typename?: 'Recurringevent'; databaseId: number }
            | { __typename?: 'Room'; databaseId: number }
            | { __typename?: 'Specialoffer'; databaseId: number }
            | { __typename?: 'Tag'; databaseId: number }
            | { __typename?: 'Weddingbooking'; databaseId: number };
        }>;
        childItems?: Maybe<{
          __typename?: 'MenuItemToMenuItemConnection';
          edges: Array<{
            __typename?: 'MenuItemToMenuItemConnectionEdge';
            node: {
              __typename?: 'MenuItem';
              databaseId: number;
              label?: Maybe<string>;
              path?: Maybe<string>;
              url?: Maybe<string>;
              label_noTx?: Maybe<string>;
              NoTranslate?: Maybe<{ __typename?: 'MenuItem_Notranslate'; noTx?: Maybe<boolean> }>;
              connectedNode?: Maybe<{
                __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
                node:
                  | { __typename?: 'Activities'; databaseId: number }
                  | { __typename?: 'Calendar'; databaseId: number }
                  | { __typename?: 'Category'; databaseId: number }
                  | { __typename?: 'Html_menu'; databaseId: number }
                  | { __typename?: 'Media_block'; databaseId: number }
                  | { __typename?: 'Page'; databaseId: number }
                  | { __typename?: 'Post'; databaseId: number }
                  | { __typename?: 'Recurringevent'; databaseId: number }
                  | { __typename?: 'Room'; databaseId: number }
                  | { __typename?: 'Specialoffer'; databaseId: number }
                  | { __typename?: 'Tag'; databaseId: number }
                  | { __typename?: 'Weddingbooking'; databaseId: number };
              }>;
              childItems?: Maybe<{
                __typename?: 'MenuItemToMenuItemConnection';
                edges: Array<{
                  __typename?: 'MenuItemToMenuItemConnectionEdge';
                  node: {
                    __typename?: 'MenuItem';
                    databaseId: number;
                    label?: Maybe<string>;
                    path?: Maybe<string>;
                    url?: Maybe<string>;
                    label_noTx?: Maybe<string>;
                    NoTranslate?: Maybe<{
                      __typename?: 'MenuItem_Notranslate';
                      noTx?: Maybe<boolean>;
                    }>;
                    connectedNode?: Maybe<{
                      __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
                      node:
                        | { __typename?: 'Activities'; databaseId: number }
                        | { __typename?: 'Calendar'; databaseId: number }
                        | { __typename?: 'Category'; databaseId: number }
                        | { __typename?: 'Html_menu'; databaseId: number }
                        | { __typename?: 'Media_block'; databaseId: number }
                        | { __typename?: 'Page'; databaseId: number }
                        | { __typename?: 'Post'; databaseId: number }
                        | { __typename?: 'Recurringevent'; databaseId: number }
                        | { __typename?: 'Room'; databaseId: number }
                        | { __typename?: 'Specialoffer'; databaseId: number }
                        | { __typename?: 'Tag'; databaseId: number }
                        | { __typename?: 'Weddingbooking'; databaseId: number };
                    }>;
                  };
                }>;
              }>;
            };
          }>;
        }>;
      };
    }>;
  }>;
  iconNav?: Maybe<{
    __typename?: 'RootQueryToMenuItemConnection';
    edges: Array<{
      __typename?: 'RootQueryToMenuItemConnectionEdge';
      node: {
        __typename?: 'MenuItem';
        label?: Maybe<string>;
        databaseId: number;
        path?: Maybe<string>;
        iconSelect?: Maybe<{ __typename?: 'MenuItem_Iconselect'; iconSelect?: Maybe<string> }>;
        menu?: Maybe<{
          __typename?: 'MenuItemToMenuConnectionEdge';
          node: { __typename?: 'Menu'; locations?: Maybe<Array<Maybe<MenuLocationEnum>>> };
        }>;
      };
    }>;
  }>;
  languageNavStyles?: Maybe<{
    __typename?: 'Menu';
    name?: Maybe<string>;
    languageSelector?: Maybe<{
      __typename?: 'Menu_Languageselector';
      languageNavAccentColour?: Maybe<string>;
      languageNavTextColour?: Maybe<string>;
    }>;
  }>;
  footerNav?: Maybe<{
    __typename?: 'RootQueryToMenuItemConnection';
    edges: Array<{
      __typename?: 'RootQueryToMenuItemConnectionEdge';
      node: {
        __typename?: 'MenuItem';
        label?: Maybe<string>;
        databaseId: number;
        path?: Maybe<string>;
        menu?: Maybe<{
          __typename?: 'MenuItemToMenuConnectionEdge';
          node: {
            __typename?: 'Menu';
            locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
            count?: Maybe<number>;
          };
        }>;
      };
    }>;
  }>;
};

export type AllWpMenusQueryVariables = Exact<{
  language?: Maybe<Scalars['String']>;
}>;

export type AllWpMenusQuery = { __typename?: 'RootQuery' } & NavFragmentFragment;

export type WordpressPagesQueryVariables = Exact<{ [key: string]: never }>;

export type WordpressPagesQuery = {
  __typename?: 'RootQuery';
  pages?: Maybe<{
    __typename?: 'RootQueryToPageConnection';
    nodes: Array<{ __typename?: 'Page'; uri?: Maybe<string>; title?: Maybe<string> }>;
  }>;
};

export type WpSettingsFragment = {
  __typename?: 'RootQuery';
  allSettings?: Maybe<{
    __typename?: 'Settings';
    generalSettingsTitle?: Maybe<string>;
    generalSettingsDescription?: Maybe<string>;
  }>;
  themeSettings?: Maybe<{
    __typename?: 'ThemeSettings';
    acfVersion?: Maybe<string>;
    SettingsThemeSettings?: Maybe<{
      __typename?: 'ThemeSettings_Settingsthemesettings';
      brandTheme?: Maybe<string>;
      useHhrBrandTheme?: Maybe<boolean>;
      customEventCategories?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ThemeSettings_Settingsthemesettings_customEventCategories';
            customCategoryNames?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            customCategoryNames_noTx?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  propertyInfo?: Maybe<{
    __typename?: 'PropertyInfo';
    propertyInfo?: Maybe<{
      __typename?: 'PropertyInfo_Propertyinfo';
      liveUrl?: Maybe<string>;
      brand?: Maybe<string>;
      ctyhocn?: Maybe<string>;
      faxNumber?: Maybe<string>;
      openingHours?: Maybe<string>;
      phoneNumber?: Maybe<string>;
      address?: Maybe<{
        __typename?: 'PropertyInfo_Propertyinfo_Address';
        addressCountry?: Maybe<string>;
        addressLocality?: Maybe<string>;
        addressRegion?: Maybe<string>;
        postalCode?: Maybe<string>;
        streetAddress?: Maybe<string>;
      }>;
      geo?: Maybe<{
        __typename?: 'PropertyInfo_Propertyinfo_Geo';
        latitude?: Maybe<string>;
        longitude?: Maybe<string>;
      }>;
    }>;
  }>;
  socialLink?: Maybe<{
    __typename?: 'SocialLink';
    pageSlug?: Maybe<string>;
    pageTitle?: Maybe<string>;
    socialLinks?: Maybe<{
      __typename?: 'SocialLink_Sociallinks';
      fieldGroupName?: Maybe<string>;
      facebookLink?: Maybe<string>;
      instagramLink?: Maybe<string>;
      twitterLink?: Maybe<string>;
      linkedinLink?: Maybe<string>;
    }>;
  }>;
  headerSettings?: Maybe<{
    __typename?: 'HeaderSettings';
    pageTitle?: Maybe<string>;
    SettingsHeaderSettings?: Maybe<{
      __typename?: 'HeaderSettings_Settingsheadersettings';
      fieldGroupName?: Maybe<string>;
      backgroundColour?: Maybe<string>;
      backgroundColourActive?: Maybe<string>;
      backgroundColourGradient?: Maybe<string>;
      backgroundBlur?: Maybe<number>;
      backgroundBlurActive?: Maybe<number>;
      textColour?: Maybe<string>;
      textColourActive?: Maybe<string>;
      dividerColour?: Maybe<string>;
      dividerColourActive?: Maybe<string>;
      bookingWidgetButtonStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_BookingWidgetButtonStyles';
        fieldGroupName?: Maybe<string>;
        buttonStyle?: Maybe<string>;
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
        borderColour?: Maybe<string>;
        borderColourHover?: Maybe<string>;
        hideBookingWidget?: Maybe<boolean>;
      }>;
      iconNavStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_IconNavStyles';
        fieldGroupName?: Maybe<string>;
        iconColour?: Maybe<string>;
        iconColourActive?: Maybe<string>;
        separatorColour?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourActive?: Maybe<string>;
        underline?: Maybe<boolean>;
      }>;
      mainNavStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_MainNavStyles';
        fieldGroupName?: Maybe<string>;
        fontCase?: Maybe<string>;
        fontSize?: Maybe<string>;
        fontWeight?: Maybe<string>;
        menuItemActiveBackgroundColour?: Maybe<string>;
        menuItemActiveState?: Maybe<string>;
        menuItemActiveTextColour?: Maybe<string>;
        menuItemHoverColour?: Maybe<string>;
        menuItemPadding?: Maybe<string>;
        menuItemUnderlineColour?: Maybe<string>;
        menuItemUnderlineToggle?: Maybe<boolean>;
        submenuBackgroundColour?: Maybe<string>;
      }>;
      honorsSignInStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_HonorsSignInStyles';
        backgroundColour?: Maybe<string>;
        menuLinkBackgroundHighlightColour?: Maybe<string>;
        menuLinkTextHighlightColour?: Maybe<string>;
        textColour?: Maybe<string>;
      }>;
      languageSwitchButtonStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchButtonStyles';
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
      languageSwitchDropdownStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchDropdownStyles';
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        dividerColour?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
      languageSwitchTitleStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchTitleStyles';
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourActive?: Maybe<string>;
        textColourActiveHover?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
      languageSwitchButtonStylesActive?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchButtonStylesActive';
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
    }>;
  }>;
  logoSettings?: Maybe<{
    __typename?: 'LogoSettings';
    SettingsLogoSettings?: Maybe<{
      __typename?: 'LogoSettings_Settingslogosettings';
      brandLogoClasses?: Maybe<string>;
      brandLogoClassesFooter?: Maybe<string>;
      brandLogoColourActive?: Maybe<string>;
      brandLogoColourFooter?: Maybe<string>;
      brandLogoColourInactive?: Maybe<string>;
      hotelLogoClasses?: Maybe<string>;
      hotelLogoClassesFooter?: Maybe<string>;
      hotelLogoColourActive?: Maybe<string>;
      hotelLogoColourFooter?: Maybe<string>;
      hotelLogoColourInactive?: Maybe<string>;
      hotelLogo?: Maybe<{
        __typename?: 'MediaItem';
        altText?: Maybe<string>;
        sourceUrl?: Maybe<string>;
      }>;
      brandLogo?: Maybe<{
        __typename?: 'MediaItem';
        altText?: Maybe<string>;
        sourceUrl?: Maybe<string>;
      }>;
      additionalLogos?: Maybe<
        Array<
          Maybe<{
            __typename?: 'LogoSettings_Settingslogosettings_additionalLogos';
            margin?: Maybe<string>;
            maxWidth?: Maybe<string>;
            tailwindClasses?: Maybe<string>;
            image?: Maybe<{
              __typename?: 'MediaItem';
              altText?: Maybe<string>;
              sourceUrl?: Maybe<string>;
            }>;
          }>
        >
      >;
    }>;
  }>;
  translationSettings?: Maybe<{
    __typename?: 'TranslationSettings';
    SettingsTranslations?: Maybe<{
      __typename?: 'TranslationSettings_Settingstranslations';
      languageSelectorOverrides?: Maybe<
        Array<
          Maybe<{
            __typename?: 'TranslationSettings_Settingstranslations_languageSelectorOverrides';
            languageCode?: Maybe<string>;
            reservationsOnly?: Maybe<boolean>;
            urlOverride?: Maybe<string>;
          }>
        >
      >;
      translations?: Maybe<
        Array<
          Maybe<{
            __typename?: 'TranslationSettings_Settingstranslations_translations';
            translationString?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  buttonSettings?: Maybe<{
    __typename?: 'ButtonSettings';
    buttonsSettings?: Maybe<{
      __typename?: 'ButtonSettings_Buttonssettings';
      buttonStyles?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ButtonSettings_Buttonssettings_buttonStyles';
            backgroundColour?: Maybe<string>;
            backgroundColourHover?: Maybe<string>;
            borderColour?: Maybe<string>;
            borderColourHover?: Maybe<string>;
            borderRadius?: Maybe<string>;
            buttonType?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            fontFamily?: Maybe<string>;
            hoverStyle?: Maybe<string>;
            textColour?: Maybe<string>;
            textColourHover?: Maybe<string>;
            textTransform?: Maybe<string>;
            buttonHeight?: Maybe<string>;
            buttonPadding?: Maybe<string>;
            fontSize?: Maybe<string>;
            fontWeight?: Maybe<string>;
            pageRestrictions?: Maybe<string>;
            lineHeight?: Maybe<string>;
          }>
        >
      >;
      waldorfButtonStyles?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ButtonSettings_Buttonssettings_waldorfButtonStyles';
            buttonType?: Maybe<string>;
            textColour?: Maybe<string>;
            textColourHover?: Maybe<string>;
            underlineColour?: Maybe<string>;
            underlineColourHover?: Maybe<string>;
            pageRestrictions?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  footerSettings?: Maybe<{
    __typename?: 'FooterSettings';
    SettingsFooterSettings?: Maybe<{
      __typename?: 'FooterSettings_Settingsfootersettings';
      footer?: Maybe<{
        __typename?: 'FooterSettings_Settingsfootersettings_Footer';
        backgroundColour?: Maybe<string>;
        disclaimerText?: Maybe<string>;
        textColour?: Maybe<string>;
        dividerColour?: Maybe<string>;
        backgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
      }>;
      breadcrumbs?: Maybe<{
        __typename?: 'FooterSettings_Settingsfootersettings_Breadcrumbs';
        activeTextColour?: Maybe<string>;
        backgroundColour?: Maybe<string>;
        textColour?: Maybe<string>;
        delimiter?: Maybe<string>;
        delimiterColour?: Maybe<string>;
        delimiterPadding?: Maybe<string>;
      }>;
      copyright?: Maybe<{
        __typename?: 'FooterSettings_Settingsfootersettings_Copyright';
        backgroundColour?: Maybe<string>;
        enable?: Maybe<boolean>;
        textColour?: Maybe<string>;
      }>;
    }>;
  }>;
  roomSettings?: Maybe<{
    __typename?: 'RoomSettings';
    pageTitle?: Maybe<string>;
    SettingsRoomSettings?: Maybe<{
      __typename?: 'RoomSettings_Settingsroomsettings';
      roomFilters1Title?: Maybe<string>;
      roomFilters2Title?: Maybe<string>;
      roomFilters3Title?: Maybe<string>;
    }>;
  }>;
  alertBarSettings?: Maybe<{
    __typename?: 'AlertBarSettings';
    alertBarSettings?: Maybe<{
      __typename?: 'AlertBarSettings_Alertbarsettings';
      backgroundColor?: Maybe<string>;
      linkColor?: Maybe<string>;
      textColor?: Maybe<string>;
    }>;
  }>;
  interactiveMapSettings?: Maybe<{
    __typename?: 'InteractiveMapSettings';
    interactiveMapSettings?: Maybe<{
      __typename?: 'InteractiveMapSettings_Interactivemapsettings';
      mapCategories?: Maybe<
        Array<
          Maybe<{
            __typename?: 'InteractiveMapSettings_Interactivemapsettings_mapCategories';
            categoryName?: Maybe<string>;
            iconList?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  shopFormSettings?: Maybe<{
    __typename?: 'ShopFormSettings';
    pageSlug?: Maybe<string>;
    pageTitle?: Maybe<string>;
    shopFormSettings?: Maybe<{
      __typename?: 'ShopFormSettings_Shopformsettings';
      fieldGroupName?: Maybe<string>;
      attachToHeader?: Maybe<boolean>;
      checkboxBackgroundColor?: Maybe<string>;
      checkboxInactiveBackgroundColor?: Maybe<string>;
      checkboxOutlineCheckmarkColor?: Maybe<string>;
      dateNumberAndRoomIconColor?: Maybe<string>;
      dateTextColor?: Maybe<string>;
      dayOfTheWeekColor?: Maybe<string>;
      dividingLineColor?: Maybe<string>;
      horizontalDividingLineColor?: Maybe<string>;
      inputBorderColor?: Maybe<string>;
      mainTextColor?: Maybe<string>;
      modalBackgroundColor?: Maybe<string>;
      modalDividingLineColor?: Maybe<string>;
      modalHyperlinkColor?: Maybe<string>;
      modalHyperlinkColorCopy?: Maybe<string>;
      modalTextColor?: Maybe<string>;
      monthColor?: Maybe<string>;
      oscBookButtonBackgroundColor?: Maybe<string>;
      oscBookButtonBackgroundColorOnHover?: Maybe<string>;
      oscBookButtonBorderColor?: Maybe<string>;
      oscBookButtonBorderColorOnHover?: Maybe<string>;
      oscBookButtonTextColor?: Maybe<string>;
      oscBookButtonTextColorOnHover?: Maybe<string>;
      searchAndFilterIcons?: Maybe<string>;
      searchButtonBackgroundColor?: Maybe<string>;
      searchButtonBackgroundColorOnHover?: Maybe<string>;
      searchButtonBorderColor?: Maybe<string>;
      searchButtonBorderColorOnHover?: Maybe<string>;
      searchButtonTextColor?: Maybe<string>;
      searchButtonTextColorOnHover?: Maybe<string>;
      selectedBookDatePickerDateBackground?: Maybe<string>;
      selectedBookDatePickerDateText?: Maybe<string>;
      selectedBookPickerDateRangeBackground?: Maybe<string>;
      selectedBookPickerDateRangeText?: Maybe<string>;
      shopFormBgColor?: Maybe<string>;
      shopFormBackgroundBlur?: Maybe<number>;
      specialRatesNumberBackground?: Maybe<string>;
      specialRatesTextBackground?: Maybe<string>;
      activeHorizontalDividingLineColor?: Maybe<string>;
      pastDatesText?: Maybe<string>;
      todaysDateText?: Maybe<string>;
      todaysDateBackground?: Maybe<string>;
      bookButtonStyleFromGlobal?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_BookButtonStyleFromGlobal';
        fieldGroupName?: Maybe<string>;
        shopFormBookGlobalButtonStyleButtonStyle?: Maybe<string>;
      }>;
      searchButtonStyleFromGlobal?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_SearchButtonStyleFromGlobal';
        fieldGroupName?: Maybe<string>;
        globalButtonStyleSettingButtonStyle?: Maybe<string>;
      }>;
      activeShopFormStyles?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_ActiveShopFormStyles';
        dateTextColor?: Maybe<string>;
        dayOfTheWeekColor?: Maybe<string>;
        dividingLineColor?: Maybe<string>;
        monthColor?: Maybe<string>;
      }>;
      activeBookButtons?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_ActiveBookButtons';
        backgroundColor?: Maybe<string>;
        backgroundColorOnHover?: Maybe<string>;
        borderColor?: Maybe<string>;
        borderColorOnHover?: Maybe<string>;
        textColor?: Maybe<string>;
        textColorOnHover?: Maybe<string>;
      }>;
      activeSearchButtons?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_ActiveSearchButtons';
        backgroundColor?: Maybe<string>;
        backgroundColorOnHover?: Maybe<string>;
        borderColor?: Maybe<string>;
        borderColorOnHover?: Maybe<string>;
        textColor?: Maybe<string>;
        textColorOnHover?: Maybe<string>;
      }>;
      modalCancel?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_ModalCancel';
        backgroundColor?: Maybe<string>;
        backgroundColorOnHover?: Maybe<string>;
        borderColor?: Maybe<string>;
        borderColorOnHover?: Maybe<string>;
        textColor?: Maybe<string>;
        textColorOnHover?: Maybe<string>;
      }>;
      modalDone?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_ModalDone';
        backgroundColor?: Maybe<string>;
        backgroundColorOnHover?: Maybe<string>;
        borderColor?: Maybe<string>;
        borderColorOnHover?: Maybe<string>;
        textColor?: Maybe<string>;
        textColorOnHover?: Maybe<string>;
      }>;
    }>;
  }>;
  componentStyles?: Maybe<{
    __typename?: 'ComponentStyles';
    globalComponentSettings?: Maybe<{
      __typename?: 'ComponentStyles_Globalcomponentsettings';
      globalComponentSettings?: Maybe<{
        __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings';
        addressBarSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AddressBarSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          borderTopColour?: Maybe<string>;
          borderBottomColour?: Maybe<string>;
          seperatorColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AddressBarSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AddressBarSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        mastheadComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MastheadComponentSettings';
          titleColor?: Maybe<string>;
          textColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          overlayYPosition?: Maybe<string>;
          overlayXPosition?: Maybe<string>;
          overlayMobilePosition?: Maybe<string>;
          overlayBackgroundOpacity?: Maybe<number>;
          overlayBackgroundColour?: Maybe<string>;
          overlayMobileBackgroundColour?: Maybe<string>;
          mobileTitleColour?: Maybe<string>;
          mobileSubtitleColour?: Maybe<string>;
          overlayBlur?: Maybe<number>;
          overlayFullWidth?: Maybe<string>;
          mastheadHeight?: Maybe<string>;
          mastheadGradient?: Maybe<string>;
          videoCtaBackgroundColor?: Maybe<string>;
          videoCtaBorderColor?: Maybe<string>;
          videoCtaMuteColor?: Maybe<string>;
          videoCtaPlaypauseColor?: Maybe<string>;
          videoCtaUnmuteColor?: Maybe<string>;
          videoMastheadCtaPosition?: Maybe<string>;
          videoCtaShadow?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          logoXPosition?: Maybe<string>;
          logoYPosition?: Maybe<string>;
          heroCarouselArrowColour?: Maybe<string>;
          heroCarouselBackgroundColour?: Maybe<string>;
          heroCarouselTextColour?: Maybe<string>;
          heroCarouselCounterColour?: Maybe<string>;
          heroCarouselDividerColour?: Maybe<string>;
          heroCarouselRoundedCorners?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          mastheadSpacer?: Maybe<boolean>;
          mastheadSpacerColour?: Maybe<string>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MastheadComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MastheadComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        halfAndHalfComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HalfAndHalfComponentSettings';
          fieldGroupName?: Maybe<string>;
          accordionBorderColor?: Maybe<string>;
          accordionIconColor?: Maybe<string>;
          accordionIconColorActive?: Maybe<string>;
          accordionPanelBackgroundColor?: Maybe<string>;
          accordionPanelLabelColor?: Maybe<string>;
          accordionRowLabelColor?: Maybe<string>;
          accordionRowTextColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HalfAndHalfComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HalfAndHalfComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        singleItemSliderComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SingleItemSliderComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SingleItemSliderComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SingleItemSliderComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        fullWidthImageComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FullWidthImageComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FullWidthImageComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FullWidthImageComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        introductionComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IntroductionComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IntroductionComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IntroductionComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        hotelTitleComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelTitleComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelTitleComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelTitleComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        locationGoogleMapComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LocationGoogleMapComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LocationGoogleMapComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LocationGoogleMapComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        imageRevealComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageRevealComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageRevealComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageRevealComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoColumnIconListSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnIconListSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnIconListSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnIconListSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoOneMediaCopySettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoOneMediaCopySettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoOneMediaCopySettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoOneMediaCopySettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoTwoMediaCopySettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoTwoMediaCopySettings';
          fieldGroupName?: Maybe<string>;
          horizontalDirection?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoTwoMediaCopySettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoTwoMediaCopySettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        offersCuratedComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffersCuratedComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffersCuratedComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffersCuratedComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        multiItemCarouselComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiItemCarouselComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          headerTitleColour?: Maybe<string>;
          headerCopyColour?: Maybe<string>;
          uiPrimaryColour?: Maybe<string>;
          uiSecondaryColour?: Maybe<string>;
          titleSize?: Maybe<string>;
          tileTitleBackgroundColour?: Maybe<string>;
          headingStyle?: Maybe<string>;
          tileTitleAlignment?: Maybe<string>;
          tileTitleLayout?: Maybe<string>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiItemCarouselComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiItemCarouselComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        socialMediaFeedSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SocialMediaFeedSettings';
          fieldGroupName?: Maybe<string>;
          socialMediaIconColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SocialMediaFeedSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SocialMediaFeedSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoColumnCompareComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnCompareComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnCompareComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnCompareComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        multiColumnComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          accordionBorderColor?: Maybe<string>;
          accordionIconColor?: Maybe<string>;
          accordionIconColorActive?: Maybe<string>;
          accordionPanelBackgroundColor?: Maybe<string>;
          accordionPanelLabelColor?: Maybe<string>;
          accordionRowLabelColor?: Maybe<string>;
          accordionRowTextColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          filterActiveTextColour?: Maybe<string>;
          filterBackgroundColour?: Maybe<string>;
          filterTextColour?: Maybe<string>;
          uiBackgroundColour?: Maybe<string>;
          uiControlsColour?: Maybe<string>;
          headerTitleColour?: Maybe<string>;
          headerCopyColour?: Maybe<string>;
          tileImageLabelBackgroundColor?: Maybe<string>;
          tileImageLabelIconColor?: Maybe<string>;
          tileImageLabelTextColor?: Maybe<string>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        spacerComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SpacerComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SpacerComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SpacerComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        retailFlyoutComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailFlyoutComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailFlyoutComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailFlyoutComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        retailRailComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailRailComponentSettings';
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          closeIconColour?: Maybe<string>;
          closeTextColour?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
        }>;
        galleryWallComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GalleryWallComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GalleryWallComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GalleryWallComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        imageGalleryComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageGalleryComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageGalleryComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageGalleryComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        mediaAndCopyOverlayComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MediaAndCopyOverlayComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          mediaAndCopyPadding?: Maybe<string>;
          horizontalDirection?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MediaAndCopyOverlayComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MediaAndCopyOverlayComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        iconBlockComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        htmlMenuSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HtmlMenuSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HtmlMenuSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HtmlMenuSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        iconBlockCorePlusComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockCorePlusComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockCorePlusComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockCorePlusComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        keyInfoGridSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_KeyInfoGridSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_KeyInfoGridSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_KeyInfoGridSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        roomsComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RoomsComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RoomsComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RoomsComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        hotelPoliciesComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelPoliciesComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelPoliciesComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelPoliciesComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        resortMapV2Settings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ResortMapV2Settings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          mapControlsColor?: Maybe<string>;
          mapControlsBackgroundColor?: Maybe<string>;
          tabBackgroundColor?: Maybe<string>;
          tabTextColor?: Maybe<string>;
          selectedTabBackgroundColor?: Maybe<string>;
          selectedTabTextColor?: Maybe<string>;
          mobileFilterDropdownBackgroundColor?: Maybe<string>;
          mapControlsPlacement?: Maybe<string>;
          mobileFilterDropdownTextColor?: Maybe<string>;
          pinAccentColor?: Maybe<string>;
          pinColor?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          titleColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          textColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ResortMapV2Settings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ResortMapV2Settings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        comparisonTableSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ComparisonTableSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ComparisonTableSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ComparisonTableSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        onPageMenuSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OnPageMenuSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OnPageMenuSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OnPageMenuSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        fourStaggeredTilesComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FourStaggeredTilesComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FourStaggeredTilesComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FourStaggeredTilesComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        restaurantsComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RestaurantsComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          hideTileBorder?: Maybe<boolean>;
          cuisineTypeTextColour?: Maybe<string>;
          cuisineTypeIconColour?: Maybe<string>;
          tileBorderColour?: Maybe<string>;
          topSectionBackgroundColour?: Maybe<string>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RestaurantsComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RestaurantsComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        accordionComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AccordionComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AccordionComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AccordionComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        meetingCapacitySummaryComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacitySummaryComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacitySummaryComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacitySummaryComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        meetingGroupComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingGroupComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingGroupComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingGroupComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        meetingCapacityChartsSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacityChartsSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacityChartsSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacityChartsSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        eventsCalendarSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EventsCalendarSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
        }>;
        gallerySliderComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GallerySliderComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GallerySliderComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GallerySliderComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        informationBarComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_InformationBarComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_InformationBarComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_InformationBarComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        videoMediaCopySettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_VideoMediaCopySettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_VideoMediaCopySettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_VideoMediaCopySettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        multiColumnListComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnListComponentSettings';
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnListComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnListComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        patchworkGridSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_PatchworkGridSettings';
          imageCaptionBackgroundColor?: Maybe<string>;
          imageCaptionTextColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_PatchworkGridSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_PatchworkGridSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        editorialSnippetComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EditorialSnippetComponentSettings';
          componentLabel?: Maybe<string>;
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EditorialSnippetComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EditorialSnippetComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        offsetComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffsetComponentSettings';
          imageCaptionTextColor?: Maybe<string>;
          imageCaptionBackgroundColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffsetComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffsetComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        logoGridComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LogoGridComponentSettings';
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LogoGridComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LogoGridComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
      }>;
    }>;
  }>;
  modalSettings?: Maybe<{
    __typename?: 'ModalSettings';
    modalSettings?: Maybe<{
      __typename?: 'ModalSettings_Modalsettings';
      modalBackgroundOpacity?: Maybe<number>;
      modalBackgroundColour?: Maybe<string>;
      modalCloseButtonBackgroundColour?: Maybe<string>;
      modalCloseButtonIconColour?: Maybe<string>;
      contentBackgroundColour?: Maybe<string>;
      contentTextColour?: Maybe<string>;
      hideBoxShadow?: Maybe<boolean>;
    }>;
  }>;
};

export type RecurringEventsQueryVariables = Exact<{ [key: string]: never }>;

export type RecurringEventsQuery = {
  __typename?: 'RootQuery';
  recurringevents?: Maybe<{
    __typename?: 'RootQueryToRecurringeventConnection';
    edges: Array<{
      __typename?: 'RootQueryToRecurringeventConnectionEdge';
      node: {
        __typename?: 'Recurringevent';
        title?: Maybe<string>;
        id: string;
        databaseId: number;
        slug?: Maybe<string>;
        RegularlyScheduledEvents?: Maybe<{
          __typename?: 'Recurringevent_Regularlyscheduledevents';
          attractionEventLocation?: Maybe<string>;
          category?: Maybe<string>;
          description?: Maybe<string>;
          distanceFromResort?: Maybe<string>;
          fieldGroupName?: Maybe<string>;
          locationAtResort?: Maybe<string>;
          onSiteOrOffSite?: Maybe<string>;
          eventDisplayEndDate?: Maybe<string>;
          eventDisplayStartDate?: Maybe<string>;
          allDays?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_AllDays';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            startTime?: Maybe<string>;
            heldDaily?: Maybe<boolean>;
            fieldGroupName?: Maybe<string>;
          }>;
          fridaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_FridaySchedule';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            startTime?: Maybe<string>;
          }>;
          image?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
            altText?: Maybe<string>;
            title?: Maybe<string>;
            databaseId: number;
          }>;
          buttons?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_buttons';
                buttonStyle?: Maybe<string>;
                link?: Maybe<{
                  __typename?: 'AcfLink';
                  target?: Maybe<string>;
                  title?: Maybe<string>;
                  url?: Maybe<string>;
                }>;
              }>
            >
          >;
          mondaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_MondaySchedule';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            startTime?: Maybe<string>;
          }>;
          saturdaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_SaturdaySchedule';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            startTime?: Maybe<string>;
          }>;
          sundaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_SundaySchedule';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            startTime?: Maybe<string>;
          }>;
          thursdaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_ThursdaySchedule';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            startTime?: Maybe<string>;
          }>;
          tuesdaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_TuesdaySchedule';
            allDay?: Maybe<boolean>;
            endTime?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            startTime?: Maybe<string>;
          }>;
          wednesdaySchedule?: Maybe<{
            __typename?: 'Recurringevent_Regularlyscheduledevents_WednesdaySchedule';
            startTime?: Maybe<string>;
            scheduled?: Maybe<boolean>;
            fieldGroupName?: Maybe<string>;
            endTime?: Maybe<string>;
            allDay?: Maybe<boolean>;
          }>;
        }>;
      };
    }>;
  }>;
  calendars?: Maybe<{
    __typename?: 'RootQueryToCalendarConnection';
    edges: Array<{
      __typename?: 'RootQueryToCalendarConnectionEdge';
      node: {
        __typename?: 'Calendar';
        title?: Maybe<string>;
        calendarId: number;
        calendarOfEvents?: Maybe<{
          __typename?: 'Calendar_Calendarofevents';
          attractionEventLocation?: Maybe<string>;
          category?: Maybe<string>;
          description?: Maybe<string>;
          distanceFromResort?: Maybe<string>;
          endDate?: Maybe<string>;
          endTime?: Maybe<string>;
          fieldGroupName?: Maybe<string>;
          locationAtResort?: Maybe<string>;
          onSiteOrOffSite?: Maybe<string>;
          startDate?: Maybe<string>;
          startTime?: Maybe<string>;
          image?: Maybe<{
            __typename?: 'MediaItem';
            altText?: Maybe<string>;
            sourceUrl?: Maybe<string>;
            databaseId: number;
          }>;
          buttons?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Calendar_Calendarofevents_buttons';
                buttonStyle?: Maybe<string>;
                link?: Maybe<{
                  __typename?: 'AcfLink';
                  target?: Maybe<string>;
                  title?: Maybe<string>;
                  url?: Maybe<string>;
                }>;
              }>
            >
          >;
        }>;
      };
    }>;
  }>;
};

export type WordpressThemeSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type WordpressThemeSettingsQuery = { __typename?: 'RootQuery' } & WpSettingsFragment;

export type WordpressPageTranslationInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  idType: PageIdType;
  asPreview?: Maybe<Scalars['Boolean']>;
}>;

export type WordpressPageTranslationInfoQuery = {
  __typename?: 'RootQuery';
  page?: Maybe<{
    __typename?: 'Page';
    databaseId: number;
    translations?: Maybe<
      Array<Maybe<{ __typename?: 'Translation'; locale?: Maybe<string>; id: string }>>
    >;
  }>;
};

export type PageFieldsFragment = {
  __typename?: 'Page';
  slug?: Maybe<string>;
  title?: Maybe<string>;
  pageId: number;
  isFrontPage: boolean;
  parent?: Maybe<{
    __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
    node:
      | { __typename?: 'Activities'; slug?: Maybe<string> }
      | { __typename?: 'Calendar'; slug?: Maybe<string> }
      | { __typename?: 'GraphqlDocument'; slug?: Maybe<string> }
      | { __typename?: 'Html_menu'; slug?: Maybe<string> }
      | { __typename?: 'MediaItem'; slug?: Maybe<string> }
      | { __typename?: 'Media_block'; slug?: Maybe<string> }
      | {
          __typename?: 'Page';
          title?: Maybe<string>;
          slug?: Maybe<string>;
          parent?: Maybe<{
            __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
            node:
              | { __typename?: 'Activities'; slug?: Maybe<string> }
              | { __typename?: 'Calendar'; slug?: Maybe<string> }
              | { __typename?: 'GraphqlDocument'; slug?: Maybe<string> }
              | { __typename?: 'Html_menu'; slug?: Maybe<string> }
              | { __typename?: 'MediaItem'; slug?: Maybe<string> }
              | { __typename?: 'Media_block'; slug?: Maybe<string> }
              | { __typename?: 'Page'; title?: Maybe<string>; slug?: Maybe<string> }
              | { __typename?: 'Post'; slug?: Maybe<string> }
              | { __typename?: 'Recurringevent'; slug?: Maybe<string> }
              | { __typename?: 'Room'; slug?: Maybe<string> }
              | { __typename?: 'Specialoffer'; slug?: Maybe<string> }
              | { __typename?: 'Weddingbooking'; slug?: Maybe<string> };
          }>;
        }
      | { __typename?: 'Post'; slug?: Maybe<string> }
      | { __typename?: 'Recurringevent'; slug?: Maybe<string> }
      | { __typename?: 'Room'; slug?: Maybe<string> }
      | { __typename?: 'Specialoffer'; slug?: Maybe<string> }
      | { __typename?: 'Weddingbooking'; slug?: Maybe<string> };
  }>;
  metaDataFields?: Maybe<{
    __typename?: 'Page_Metadatafields';
    fieldGroupName?: Maybe<string>;
    metaTitle?: Maybe<string>;
    metaDescription?: Maybe<string>;
    adobeDigitaldataOverwriteList?: Maybe<string>;
    adobeDigitaldataOverwriteText?: Maybe<string>;
    adobeDigitaldataOverwriteBrand?: Maybe<string>;
    adobeDigitaldataOverwriteCtyhocn?: Maybe<string>;
    adobeDigitaldataPageDetail1?: Maybe<string>;
    adobeDigitaldataPageDetail2?: Maybe<string>;
    adobeDigitaldataPageDetail3?: Maybe<string>;
    openGraphDescription?: Maybe<string>;
    openGraphTitle?: Maybe<string>;
    ctyhocnOverride?: Maybe<string>;
    openGraphImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
  }>;
  acfFlexible?: Maybe<{
    __typename?: 'Page_Acfflexible';
    fieldGroupName?: Maybe<string>;
    flexibleComponents?: Maybe<
      Array<
        Maybe<
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Accordion';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_AddressBar';
            } & Accordion_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
            } & Accordion_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Cvent';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
            } & Accordion_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid';
            } & Accordion_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
            } & Accordion_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
            } & Accordion_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
            } & Accordion_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
            } & Accordion_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
            } & Accordion_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
            } & Accordion_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
            } & Accordion_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
            } & Accordion_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
            } & Accordion_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_IconBlock';
            } & Accordion_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
            } & Accordion_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
            } & Accordion_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
            } & Accordion_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_InformationBar';
            } & Accordion_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Introduction';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
            } & Accordion_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
            } & Accordion_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
            } & Accordion_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Masthead';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
            } & Accordion_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
            } & Accordion_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Offset';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Offset_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
            } & Accordion_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
            } & Accordion_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_ResortMap';
            } & Accordion_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
            } & Accordion_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Restaurants';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
            } & Accordion_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_RetailRail';
            } & Accordion_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
            } & Accordion_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
            } & Accordion_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
            } & Accordion_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
            } & Accordion_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_Spacer';
            } & Accordion_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
            } & Accordion_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
            } & Accordion_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
            } & Accordion_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
            } & Accordion_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
            } & Accordion_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment)
          | ({
              __typename: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
            } & Accordion_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              ComparisonTable_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              ContactBar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              CopyBlock_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              Cvent_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              EditorialSnippet_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              FullWidthImage_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              GallerySlider_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              GoogleMap_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              HeroImage_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              HotelPolicies_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              HotelTitle_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              HtmlMenu_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              IconGridCorePlus_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              IconGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              ImageGalleryWall_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              ImageGallery_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              ImageReveal_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              InformationBar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              KeyInfoGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              LogoGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MediaAndCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MeetingCapacity_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MeetingsGroup_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MultiColumnList_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              MultiColumnTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              OffersCurated_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              Offset_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              PatchworkGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              ResortMap_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              Restaurants_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              RetailFlyout_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              RetailRail_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              RoomTilesLite_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              RoomsTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              SocialMediaFeed_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              Spacer_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              TabbedTableData_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              TwoColumnCompare_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              TwoColumnIconList_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              VideoMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment &
              WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment)
        >
      >
    >;
  }>;
};

export type AllWpPagesFragment = {
  __typename?: 'RootQuery';
  allWPPages?: Maybe<{
    __typename?: 'RootQueryToPageConnection';
    nodes: Array<{
      __typename?: 'Page';
      uri?: Maybe<string>;
      title?: Maybe<string>;
      id: string;
      slug?: Maybe<string>;
      acfFlexible?: Maybe<{
        __typename?: 'Page_Acfflexible';
        flexibleComponents?: Maybe<
          Array<
            Maybe<
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet' }
              | {
                  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid';
                }
              | {
                  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
                }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid' }
              | {
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
                  image?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
                  imageCarousel?: Maybe<
                    Array<Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>>
                  >;
                }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_Offset' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy' }
              | { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' }
            >
          >
        >;
      }>;
      parent?: Maybe<{
        __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
        node:
          | { __typename?: 'Activities'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Calendar'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'GraphqlDocument'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Html_menu'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'MediaItem'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Media_block'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Page'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Post'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Recurringevent'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Room'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Specialoffer'; uri?: Maybe<string>; slug?: Maybe<string> }
          | { __typename?: 'Weddingbooking'; uri?: Maybe<string>; slug?: Maybe<string> };
      }>;
    }>;
  }>;
};

export type WeddingAvailabilityCalendarQueryVariables = Exact<{
  minTimestamp?: Maybe<Scalars['Float']>;
}>;

export type WeddingAvailabilityCalendarQuery = {
  __typename?: 'RootQuery';
  weddingbookings?: Maybe<{
    __typename?: 'RootQueryToWeddingbookingConnection';
    edges: Array<{
      __typename?: 'RootQueryToWeddingbookingConnectionEdge';
      node: {
        __typename?: 'Weddingbooking';
        id: string;
        title?: Maybe<string>;
        weddingCalendar?: Maybe<{
          __typename?: 'Weddingbooking_Weddingcalendar';
          ceremonyTime?: Maybe<string>;
          date?: Maybe<string>;
          fieldGroupName?: Maybe<string>;
          receptionVenue?: Maybe<string>;
          status?: Maybe<string>;
          timestamp?: Maybe<string>;
        }>;
      };
    }>;
  }>;
  themeSettings?: Maybe<{
    __typename?: 'ThemeSettings';
    SettingsThemeSettings?: Maybe<{
      __typename?: 'ThemeSettings_Settingsthemesettings';
      venuesTimeSlots?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ThemeSettings_Settingsthemesettings_venuesTimeSlots';
            timeSlot?: Maybe<string>;
          }>
        >
      >;
      weddingVenues?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ThemeSettings_Settingsthemesettings_weddingVenues';
            venue?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
};

export type RoomsPostsQueryVariables = Exact<{ [key: string]: never }>;

export type RoomsPostsQuery = {
  __typename?: 'RootQuery';
  rooms?: Maybe<{
    __typename?: 'RootQueryToRoomConnection';
    edges: Array<{
      __typename?: 'RootQueryToRoomConnectionEdge';
      node: {
        __typename?: 'Room';
        title?: Maybe<string>;
        roomsContent?: Maybe<{
          __typename?: 'Room_Roomscontent';
          roomTypeCode?: Maybe<string>;
          view?: Maybe<string>;
          balconyDetail?: Maybe<string>;
          customView?: Maybe<string>;
          customBalcony?: Maybe<string>;
          squareFootage?: Maybe<number>;
          outdoorFeatures?: Maybe<Array<Maybe<string>>>;
          roomFeatures?: Maybe<Array<Maybe<string>>>;
          roomType?: Maybe<Array<Maybe<string>>>;
          bathroomAmenities?: Maybe<Array<Maybe<string>>>;
          locations?: Maybe<Array<Maybe<string>>>;
          iconWidth?: Maybe<number>;
          view_noTx?: Maybe<string>;
          balconyDetail_noTx?: Maybe<string>;
          customView_noTx?: Maybe<string>;
          customBalcony_noTx?: Maybe<string>;
          image?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
            altText?: Maybe<string>;
          }>;
          icon?: Maybe<{
            __typename?: 'MediaItem';
            altText?: Maybe<string>;
            sourceUrl?: Maybe<string>;
          }>;
          carouselImages?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Room_Roomscontent_carouselImages';
                image?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                  altText?: Maybe<string>;
                  mediaDetails?: Maybe<{
                    __typename?: 'MediaDetails';
                    height?: Maybe<number>;
                    width?: Maybe<number>;
                  }>;
                }>;
              }>
            >
          >;
          showCustomFilter1?: Maybe<{
            __typename?: 'Room_Roomscontent_ShowCustomFilter1';
            customFilter1Select?: Maybe<string>;
            showCustomFilter1?: Maybe<boolean>;
          }>;
          showCustomFilter2?: Maybe<{
            __typename?: 'Room_Roomscontent_ShowCustomFilter2';
            customFilter2Select?: Maybe<string>;
            showCustomFilter2?: Maybe<boolean>;
          }>;
          showCustomFilter3?: Maybe<{
            __typename?: 'Room_Roomscontent_ShowCustomFilter3';
            customFilter3Select?: Maybe<string>;
            showCustomFilter3?: Maybe<boolean>;
          }>;
          balconyIconGroup?: Maybe<{
            __typename?: 'Room_Roomscontent_BalconyIconGroup';
            iconList?: Maybe<string>;
            iconList_noTx?: Maybe<string>;
          }>;
          viewIconGroup?: Maybe<{
            __typename?: 'Room_Roomscontent_ViewIconGroup';
            iconList?: Maybe<string>;
            iconList_noTx?: Maybe<string>;
          }>;
          squareFootageIconGroup?: Maybe<{
            __typename?: 'Room_Roomscontent_SquareFootageIconGroup';
            iconList?: Maybe<string>;
            iconList_noTx?: Maybe<string>;
          }>;
        }>;
      };
    }>;
  }>;
};

export type HtmlMenusQueryVariables = Exact<{ [key: string]: never }>;

export type HtmlMenusQuery = {
  __typename?: 'RootQuery';
  htmlMenus?: Maybe<{
    __typename?: 'RootQueryToHtml_menuConnection';
    edges: Array<{
      __typename?: 'RootQueryToHtml_menuConnectionEdge';
      node: {
        __typename?: 'Html_menu';
        id: string;
        title?: Maybe<string>;
        htmlMenuContent?: Maybe<{
          __typename?: 'Html_menu_Htmlmenucontent';
          menuRepeater?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Html_menu_Htmlmenucontent_menuRepeater';
                fieldGroupName?: Maybe<string>;
                tabTitle?: Maybe<string>;
                tabTitle_noTx?: Maybe<string>;
                menuSections?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'Html_menu_Htmlmenucontent_menuRepeater_menuSections';
                      fieldGroupName?: Maybe<string>;
                      itemDescriptionColour?: Maybe<string>;
                      itemDisclaimerColour?: Maybe<string>;
                      itemPriceColour?: Maybe<string>;
                      itemTitleColour?: Maybe<string>;
                      sectionAlignment?: Maybe<string>;
                      sectionDisclaimerColour?: Maybe<string>;
                      sectionBorderColour?: Maybe<string>;
                      sectionBackgroundColour?: Maybe<string>;
                      sectionDisclaimer?: Maybe<boolean>;
                      sectionDisclaimerLine1?: Maybe<string>;
                      sectionDisclaimerLine2?: Maybe<string>;
                      spotlightBorderColour?: Maybe<string>;
                      sectionLayout?: Maybe<string>;
                      sectionSubtitle?: Maybe<string>;
                      sectionTitle?: Maybe<string>;
                      sectionDisclaimerLine1_noTx?: Maybe<string>;
                      sectionDisclaimerLine2_noTx?: Maybe<string>;
                      sectionSubtitle_noTx?: Maybe<string>;
                      sectionTitle_noTx?: Maybe<string>;
                      sectionItems?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'Html_menu_Htmlmenucontent_menuRepeater_menuSections_sectionItems';
                            fieldGroupName?: Maybe<string>;
                            itemDisclaimer?: Maybe<string>;
                            itemLine1?: Maybe<string>;
                            itemLine2?: Maybe<string>;
                            itemPrice?: Maybe<string>;
                            highlightItem?: Maybe<boolean>;
                            itemDisclaimer_noTx?: Maybe<string>;
                            itemLine1_noTx?: Maybe<string>;
                            itemLine2_noTx?: Maybe<string>;
                            itemPrice_noTx?: Maybe<string>;
                            itemPrices?: Maybe<
                              Array<
                                Maybe<{
                                  __typename?: 'Html_menu_Htmlmenucontent_menuRepeater_menuSections_sectionItems_itemPrices';
                                  itemPrice?: Maybe<string>;
                                  itemPrice_noTx?: Maybe<string>;
                                }>
                              >
                            >;
                          }>
                        >
                      >;
                    }>
                  >
                >;
              }>
            >
          >;
        }>;
      };
    }>;
  }>;
};

export type WordpressPageInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  idType: PageIdType;
  asPreview?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
}>;

export type WordpressPageInfoQuery = {
  __typename?: 'RootQuery';
  allSettings?: Maybe<{
    __typename?: 'Settings';
    generalSettingsUrl?: Maybe<string>;
    generalSettingsTitle?: Maybe<string>;
    generalSettingsDescription?: Maybe<string>;
  }>;
  page?: Maybe<{ __typename?: 'Page' } & PageFieldsFragment>;
} & WpSettingsFragment &
  NavFragmentFragment &
  AllWpPagesFragment;

type Accordion_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
  content?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  heading?: Maybe<string>;
  subtitle?: Maybe<string>;
  panel?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_panel';
        fieldGroupName?: Maybe<string>;
        panelLabel?: Maybe<string>;
        panelRepeater?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_panel_panelRepeater';
              fieldGroupName?: Maybe<string>;
              rowText?: Maybe<string>;
              rowLabel?: Maybe<string>;
            }>
          >
        >;
      }>
    >
  >;
  accordionComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_AccordionComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    iconIndicatorColour?: Maybe<string>;
    iconIndicatorColourExpanded?: Maybe<string>;
    openPanelBackgroundColour?: Maybe<string>;
    borderColour?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_AccordionComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_AccordionComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type Accordion_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type Accordion_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type Accordion_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type Accordion_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type Accordion_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type Accordion_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type Accordion_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type Accordion_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type Accordion_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type Accordion_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type Accordion_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type Accordion_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type Accordion_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type Accordion_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type Accordion_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type Accordion_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type Accordion_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type Accordion_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type Accordion_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type Accordion_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type Accordion_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type Accordion_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type Accordion_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type Accordion_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type Accordion_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type Accordion_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type Accordion_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type Accordion_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type Accordion_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type Accordion_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type Accordion_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type Accordion_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type Accordion_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type Accordion_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type Accordion_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type Accordion_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type Accordion_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type Accordion_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type Accordion_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type Accordion_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type Accordion_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type Accordion_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type Accordion_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type Accordion_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type Accordion_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type AccordionFragment =
  | Accordion_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | Accordion_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
  fieldGroupName?: Maybe<string>;
  introduction?: Maybe<string>;
  title?: Maybe<string>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater';
        fieldGroupName?: Maybe<string>;
        sectionRows?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_sectionRows';
              fieldGroupName?: Maybe<string>;
              rowTitle?: Maybe<string>;
              subrows?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_sectionRows_subrows';
                    fieldGroupName?: Maybe<string>;
                    column?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_sectionRows_subrows_column';
                          fieldGroupName?: Maybe<string>;
                          item?: Maybe<string>;
                        }>
                      >
                    >;
                  }>
                >
              >;
            }>
          >
        >;
        tableHeading?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_tableHeading';
              fieldGroupName?: Maybe<string>;
              text?: Maybe<string>;
              backgroundColour?: Maybe<string>;
              textColour?: Maybe<string>;
              headerUrl?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
      }>
    >
  >;
  comparisonTableSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_ComparisonTableSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_ComparisonTableSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_ComparisonTableSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type ComparisonTable_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type ComparisonTable_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type ComparisonTable_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type ComparisonTableFragment =
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | ComparisonTable_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type ContactBar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
  addressLine1?: Maybe<string>;
  addressLine2?: Maybe<string>;
  checkInText?: Maybe<string>;
  arrivalTitleMobile?: Maybe<string>;
  arrivalTitle?: Maybe<string>;
  addressUrl?: Maybe<string>;
  addressTitleMobile?: Maybe<string>;
  addressTitle?: Maybe<string>;
  addressText?: Maybe<string>;
  checkInTitle?: Maybe<string>;
  checkOutText?: Maybe<string>;
  checkOutTitle?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  line2Icon?: Maybe<string>;
  line2Link?: Maybe<string>;
  line2Text?: Maybe<string>;
  line3Icon?: Maybe<string>;
  line3Link?: Maybe<string>;
  line3Text?: Maybe<string>;
  telephoneIcon?: Maybe<string>;
  telephoneLine2?: Maybe<string>;
  telephoneLine1?: Maybe<string>;
  telephoneLink?: Maybe<string>;
  telephoneNumber?: Maybe<string>;
  telephoneTitle?: Maybe<string>;
  telephoneTitleMobile?: Maybe<string>;
  tripAdvisorTitle?: Maybe<string>;
  tripAdvisorTitleMobile?: Maybe<string>;
  tripAdvisorToggle?: Maybe<boolean>;
  hideArrivalDeparture?: Maybe<boolean>;
  hideAddress?: Maybe<boolean>;
  hideContactInfo?: Maybe<boolean>;
  addressBarSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar_AddressBarSettings';
    borderBottomColour?: Maybe<string>;
    borderTopColour?: Maybe<string>;
    seperatorColor?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    fieldGroupName?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar_AddressBarSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar_AddressBarSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type ContactBar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type ContactBar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type ContactBar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type ContactBarFragment =
  | ContactBar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | ContactBar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type CopyBlock_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type CopyBlock_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
  copy?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  subtitle?: Maybe<string>;
  title?: Maybe<string>;
  combinedTitleSubtitle?: Maybe<string>;
  titleSize?: Maybe<string>;
  subtitleSize?: Maybe<string>;
  pullFromCorePlus?: Maybe<boolean>;
  pullQuote?: Maybe<boolean>;
  imageSize?: Maybe<string>;
  subtitle_noTx?: Maybe<string>;
  title_noTx?: Maybe<string>;
  image?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string>; altText?: Maybe<string> }>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  introductionComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_IntroductionComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    layoutDirection?: Maybe<string>;
    padding?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_IntroductionComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_IntroductionComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type CopyBlock_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type CopyBlockFragment =
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | CopyBlock_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type Cvent_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type Cvent_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type Cvent_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type Cvent_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
  cventCode?: Maybe<string>;
  cventComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent_CventComponentSettings';
    fieldGroupName?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent_CventComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent_CventComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type Cvent_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type Cvent_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type Cvent_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type Cvent_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type Cvent_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type Cvent_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type Cvent_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type Cvent_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type Cvent_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type Cvent_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type Cvent_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type Cvent_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type Cvent_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type Cvent_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type Cvent_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type Cvent_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type Cvent_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type Cvent_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type Cvent_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type Cvent_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type Cvent_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type Cvent_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type Cvent_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type Cvent_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type Cvent_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type Cvent_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type Cvent_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type Cvent_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type Cvent_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type Cvent_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type Cvent_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type Cvent_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type Cvent_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type Cvent_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type Cvent_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type Cvent_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type Cvent_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type Cvent_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type Cvent_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type Cvent_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type Cvent_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type Cvent_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type CventFragment =
  | Cvent_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | Cvent_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
  fieldGroupName?: Maybe<string>;
  subtitle?: Maybe<string>;
  heading?: Maybe<string>;
  description?: Maybe<string>;
  iconList?: Maybe<string>;
  cropImageToCircle?: Maybe<boolean>;
  link?: Maybe<{
    __typename?: 'AcfLink';
    target?: Maybe<string>;
    title?: Maybe<string>;
    url?: Maybe<string>;
  }>;
  image?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
    slug?: Maybe<string>;
    title?: Maybe<string>;
  }>;
  editorialSnippetComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet_EditorialSnippetComponentSettings';
    componentLabel?: Maybe<string>;
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet_EditorialSnippetComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet_EditorialSnippetComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type EditorialSnippet_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type EditorialSnippetFragment =
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | EditorialSnippet_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  {
    __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid';
    fieldGroupName?: Maybe<string>;
    eventsCalendarComponentSettings?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_EventsCalendarComponentSettings';
      fieldGroupName?: Maybe<string>;
      eventsCalendarSettings?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_EventsCalendarComponentSettings_EventsCalendarSettings';
        fieldGroupName?: Maybe<string>;
        showHide?: Maybe<boolean>;
      }>;
    }>;
  };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Offset' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy' };

type EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' };

export type EventsCalendarRegularAndSpecialEventsGridFragment =
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | EventsCalendarRegularAndSpecialEventsGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type FullWidthImage_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type FullWidthImage_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
  copy?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  styles?: Maybe<string>;
  title?: Maybe<string>;
  flipImageHorizontally?: Maybe<boolean>;
  subtitle?: Maybe<string>;
  enableCarousel?: Maybe<boolean>;
  enableCropping?: Maybe<boolean>;
  cropType?: Maybe<string>;
  cropHeight?: Maybe<string>;
  cropWidth?: Maybe<string>;
  xPosition?: Maybe<string>;
  xPositionAdvanced?: Maybe<string>;
  yPosition?: Maybe<string>;
  yPositionAdvanced?: Maybe<string>;
  autoPosition?: Maybe<boolean>;
  title_noTx?: Maybe<string>;
  subtitle_noTx?: Maybe<string>;
  image?: Maybe<{ __typename?: 'MediaItem'; altText?: Maybe<string>; sourceUrl?: Maybe<string> }>;
  mediaBlock?: Maybe<{ __typename?: 'Media_block'; id: string }>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  carouselItems?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_carouselItems';
        styles?: Maybe<string>;
        title?: Maybe<string>;
        subtitle?: Maybe<string>;
        copy?: Maybe<string>;
        showHide?: Maybe<boolean>;
        title_noTx?: Maybe<string>;
        subtitle_noTx?: Maybe<string>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_carouselItems_buttons';
              buttonStyle?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
      }>
    >
  >;
  fullWidthImageComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_FullWidthImageComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_FullWidthImageComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_FullWidthImageComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type FullWidthImage_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type FullWidthImageFragment =
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | FullWidthImage_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type GallerySlider_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type GallerySlider_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
  columnCount?: Maybe<number>;
  fieldGroupName?: Maybe<string>;
  tileHeight?: Maybe<string>;
  tilePadding?: Maybe<number>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_repeater';
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
      }>
    >
  >;
  gallerySliderComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_GallerySliderComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_GallerySliderComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_GallerySliderComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type GallerySlider_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type GallerySliderFragment =
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | GallerySlider_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type GoogleMap_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type GoogleMap_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
  fieldGroupName?: Maybe<string>;
  title?: Maybe<string>;
  address?: Maybe<string>;
  addressUrl?: Maybe<string>;
  addressOverride?: Maybe<boolean>;
  phone?: Maybe<string>;
  email?: Maybe<string>;
  hideEmailCta?: Maybe<boolean>;
  emailCtaText?: Maybe<string>;
  zoomControl?: Maybe<number>;
  title_noTx?: Maybe<string>;
  emailCtaOverride?: Maybe<{
    __typename?: 'AcfLink';
    target?: Maybe<string>;
    title?: Maybe<string>;
    url?: Maybe<string>;
  }>;
  map?: Maybe<{
    __typename?: 'ACF_GoogleMap';
    latitude?: Maybe<number>;
    longitude?: Maybe<number>;
    zoom?: Maybe<string>;
  }>;
  locationGoogleMapComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap_LocationGoogleMapComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap_LocationGoogleMapComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap_LocationGoogleMapComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type GoogleMap_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type GoogleMapFragment =
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | GoogleMap_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type HeroImage_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type HeroImage_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
  fieldGroupName?: Maybe<string>;
  subtitle?: Maybe<string>;
  subtitleSize?: Maybe<string>;
  title?: Maybe<string>;
  titleSize?: Maybe<string>;
  imagePositioning?: Maybe<string>;
  imageCarouselChoice?: Maybe<boolean>;
  logoOverlayColour?: Maybe<string>;
  logoOverlayOpacity?: Maybe<number>;
  logoWidth?: Maybe<number>;
  logoHeight?: Maybe<number>;
  videoChoice?: Maybe<boolean>;
  videoCtaPlay?: Maybe<string>;
  videoCtaPause?: Maybe<string>;
  videoMute?: Maybe<boolean>;
  videoQualityOverride?: Maybe<boolean>;
  videoQualitySlider?: Maybe<number>;
  bookingWidgetChoice?: Maybe<boolean>;
  bookingWidgetPosition?: Maybe<string>;
  bookingWidgetMobile?: Maybe<boolean>;
  mobileCroppingAutoPosition?: Maybe<boolean>;
  mobileCroppingCropHeight?: Maybe<string>;
  mobileCroppingCropWidth?: Maybe<string>;
  mobileCroppingXPosition?: Maybe<string>;
  mobileCroppingXPositionAdvanced?: Maybe<string>;
  mobileCroppingYPosition?: Maybe<string>;
  mobileCroppingYPositionAdvanced?: Maybe<string>;
  image?: Maybe<{ __typename?: 'MediaItem'; altText?: Maybe<string>; sourceUrl?: Maybe<string> }>;
  imageCarousel?: Maybe<
    Array<Maybe<{ __typename?: 'MediaItem'; altText?: Maybe<string>; sourceUrl?: Maybe<string> }>>
  >;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  logoImage?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
    mediaDetails?: Maybe<{
      __typename?: 'MediaDetails';
      height?: Maybe<number>;
      width?: Maybe<number>;
    }>;
  }>;
  videoUrl?: Maybe<{ __typename?: 'MediaItem'; mediaItemUrl?: Maybe<string> }>;
  captionTracks?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_captionTracks';
        label?: Maybe<string>;
        language?: Maybe<string>;
        type?: Maybe<string>;
        url?: Maybe<string>;
      }>
    >
  >;
  transcriptTracks?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_transcriptTracks';
        label?: Maybe<string>;
        language?: Maybe<string>;
        type?: Maybe<string>;
        url?: Maybe<string>;
      }>
    >
  >;
  audioTracks?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_audioTracks';
        videoGroup?: Maybe<string>;
        videoLabel?: Maybe<string>;
        videoName?: Maybe<string>;
        videoUrl?: Maybe<string>;
        captionTracks?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_audioTracks_captionTracks';
              label?: Maybe<string>;
              language?: Maybe<string>;
              type?: Maybe<string>;
              url?: Maybe<string>;
            }>
          >
        >;
        transcriptTracks?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_audioTracks_transcriptTracks';
              label?: Maybe<string>;
              language?: Maybe<string>;
              type?: Maybe<string>;
              url?: Maybe<string>;
            }>
          >
        >;
      }>
    >
  >;
  mastheadComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_MastheadComponentSettings';
    fieldGroupName?: Maybe<string>;
    mastheadHeight?: Maybe<string>;
    overlayBackgroundColour?: Maybe<string>;
    overlayMobileBackgroundColour?: Maybe<string>;
    mobileTitleColour?: Maybe<string>;
    mobileSubtitleColour?: Maybe<string>;
    overlayBackgroundOpacity?: Maybe<number>;
    overlayBlur?: Maybe<number>;
    overlayFullWidth?: Maybe<string>;
    overlayXPosition?: Maybe<string>;
    overlayYPosition?: Maybe<string>;
    mastheadGradient?: Maybe<string>;
    overlayMobilePosition?: Maybe<string>;
    titleColor?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    videoCtaBackgroundColor?: Maybe<string>;
    videoCtaBorderColor?: Maybe<string>;
    videoCtaMuteColor?: Maybe<string>;
    videoCtaPlaypauseColor?: Maybe<string>;
    videoCtaUnmuteColor?: Maybe<string>;
    videoMastheadCtaPosition?: Maybe<string>;
    videoCtaShadow?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    logoXPosition?: Maybe<string>;
    logoYPosition?: Maybe<string>;
    heroCarouselArrowColour?: Maybe<string>;
    heroCarouselBackgroundColour?: Maybe<string>;
    heroCarouselTextColour?: Maybe<string>;
    heroCarouselCounterColour?: Maybe<string>;
    heroCarouselDividerColour?: Maybe<string>;
    heroCarouselRoundedCorners?: Maybe<string>;
    showHide?: Maybe<boolean>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    mastheadSpacer?: Maybe<boolean>;
    mastheadSpacerColour?: Maybe<string>;
  }>;
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type HeroImage_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type HeroImageFragment =
  | HeroImage_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | HeroImage_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type HotelPolicies_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
  fieldGroupName?: Maybe<string>;
  subtitle?: Maybe<string>;
  title?: Maybe<string>;
  copy?: Maybe<string>;
  displayHotelAccessibilityLink?: Maybe<boolean>;
  hotelAccessibilityGuideUrl?: Maybe<string>;
  hotelPoliciesComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies_HotelPoliciesComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies_HotelPoliciesComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies_HotelPoliciesComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type HotelPolicies_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type HotelPoliciesFragment =
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | HotelPolicies_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type HotelTitle_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type HotelTitle_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
  fieldGroupName?: Maybe<string>;
  hotelTitleComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle_HotelTitleComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle_HotelTitleComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle_HotelTitleComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type HotelTitle_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type HotelTitleFragment =
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | HotelTitle_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type HtmlMenu_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
  header?: Maybe<string>;
  tabTypeButtonStyle?: Maybe<string>;
  header_noTx?: Maybe<string>;
  menu?: Maybe<{ __typename?: 'Html_menu'; id: string }>;
  menuRepeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_menuRepeater';
        fieldGroupName?: Maybe<string>;
        tabTitle?: Maybe<string>;
        tabTitle_noTx?: Maybe<string>;
        menuSections?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_menuRepeater_menuSections';
              fieldGroupName?: Maybe<string>;
              pricePosition?: Maybe<string>;
              sectionBackgroundColour?: Maybe<string>;
              sectionBorderColour?: Maybe<string>;
              itemDescriptionColour?: Maybe<string>;
              itemDisclaimerColour?: Maybe<string>;
              itemPriceColour?: Maybe<string>;
              itemTitleColour?: Maybe<string>;
              sectionAlignment?: Maybe<string>;
              sectionDisclaimer?: Maybe<boolean>;
              sectionDisclaimerLine1?: Maybe<string>;
              sectionDisclaimerLine2?: Maybe<string>;
              sectionDisclaimerColour?: Maybe<string>;
              spotlightBorderColour?: Maybe<string>;
              sectionLayout?: Maybe<string>;
              sectionSubtitle?: Maybe<string>;
              sectionTitle?: Maybe<string>;
              sectionDisclaimerLine1_noTx?: Maybe<string>;
              sectionDisclaimerLine2_noTx?: Maybe<string>;
              sectionSubtitle_noTx?: Maybe<string>;
              sectionTitle_noTx?: Maybe<string>;
              sectionItems?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_menuRepeater_menuSections_sectionItems';
                    fieldGroupName?: Maybe<string>;
                    itemDisclaimer?: Maybe<string>;
                    itemLine1?: Maybe<string>;
                    itemLine2?: Maybe<string>;
                    itemPrice?: Maybe<string>;
                    highlightItem?: Maybe<boolean>;
                    itemDisclaimer_noTx?: Maybe<string>;
                    itemLine1_noTx?: Maybe<string>;
                    itemLine2_noTx?: Maybe<string>;
                    itemPrice_noTx?: Maybe<string>;
                  }>
                >
              >;
            }>
          >
        >;
      }>
    >
  >;
  htmlMenuSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_HtmlMenuSettings';
    fieldGroupName?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_HtmlMenuSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_HtmlMenuSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type HtmlMenu_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type HtmlMenuFragment =
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | HtmlMenu_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
  showHotelAmenitiesIcons?: Maybe<boolean>;
  ctyhocn?: Maybe<string>;
  header?: Maybe<string>;
  buttonStyling?: Maybe<string>;
  iconBlockCorePlusComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities_IconBlockCorePlusComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities_IconBlockCorePlusComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities_IconBlockCorePlusComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type IconGridCorePlus_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type IconGridCorePlusFragment =
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | IconGridCorePlus_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type IconGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type IconGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
  fieldGroupName?: Maybe<string>;
  header?: Maybe<string>;
  headerCopy?: Maybe<string>;
  iconColumnCount?: Maybe<string>;
  iconSize?: Maybe<string>;
  iconRepeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_iconRepeater';
        fieldGroupName?: Maybe<string>;
        iconList?: Maybe<string>;
        iconLabel?: Maybe<string>;
        iconColor?: Maybe<string>;
        labelColor?: Maybe<string>;
      }>
    >
  >;
  iconBlockComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_IconBlockComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_IconBlockComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_IconBlockComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type IconGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type IconGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type IconGridFragment =
  | IconGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | IconGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
  fieldGroupName?: Maybe<string>;
  gallerySubtitle?: Maybe<string>;
  columnCount?: Maybe<number>;
  imageVerticalAlignment?: Maybe<string>;
  galleryTitle?: Maybe<string>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_repeater';
        title?: Maybe<string>;
        selectOrientation?: Maybe<string>;
        faceDetection?: Maybe<boolean>;
        portraitModal?: Maybe<boolean>;
        imageHeight?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        description?: Maybe<string>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
          mediaDetails?: Maybe<{
            __typename?: 'MediaDetails';
            height?: Maybe<number>;
            width?: Maybe<number>;
          }>;
        }>;
      }>
    >
  >;
  galleryWallComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_GalleryWallComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_GalleryWallComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_GalleryWallComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type ImageGalleryWall_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type ImageGalleryWallFragment =
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | ImageGalleryWall_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type ImageGallery_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type ImageGallery_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
  fieldGroupName?: Maybe<string>;
  imageGallery?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_imageGallery';
        galleryTitle?: Maybe<string>;
        galleryThumbnail?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        galleryImages?: Maybe<
          Array<
            Maybe<{
              __typename?: 'MediaItem';
              altText?: Maybe<string>;
              caption?: Maybe<string>;
              sourceUrl?: Maybe<string>;
              mediaDetails?: Maybe<{
                __typename?: 'MediaDetails';
                height?: Maybe<number>;
                width?: Maybe<number>;
              }>;
            }>
          >
        >;
      }>
    >
  >;
  imageGalleryComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_ImageGalleryComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_ImageGalleryComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_ImageGalleryComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type ImageGallery_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type ImageGalleryFragment =
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | ImageGallery_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type ImageReveal_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type ImageReveal_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
  fieldGroupName?: Maybe<string>;
  header?: Maybe<string>;
  columnCount?: Maybe<number>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_repeater';
        copy?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        title?: Maybe<string>;
        showHide?: Maybe<boolean>;
        title_noTx?: Maybe<string>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        button?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  imageRevealComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_ImageRevealComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_ImageRevealComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_ImageRevealComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type ImageReveal_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type ImageRevealFragment =
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | ImageReveal_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type InformationBar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type InformationBar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type InformationBar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
  fieldGroupName?: Maybe<string>;
  emailAddress?: Maybe<string>;
  hideIcons?: Maybe<boolean>;
  phoneNumber?: Maybe<string>;
  bookCta?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_BookCta';
    fieldGroupName?: Maybe<string>;
    buttonStyle?: Maybe<string>;
    link?: Maybe<{
      __typename?: 'AcfLink';
      target?: Maybe<string>;
      title?: Maybe<string>;
      url?: Maybe<string>;
    }>;
  }>;
  details?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Details';
    fieldGroupName?: Maybe<string>;
    copy?: Maybe<string>;
    buttons?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Details_buttons';
          buttonStyle?: Maybe<string>;
          link?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
  location?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Location';
    fieldGroupName?: Maybe<string>;
    copy?: Maybe<string>;
    buttons?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Location_buttons';
          buttonStyle?: Maybe<string>;
          link?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
  hours?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Hours';
    fieldGroupName?: Maybe<string>;
    copy?: Maybe<string>;
    buttons?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Hours_buttons';
          buttonStyle?: Maybe<string>;
          link?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
  informationBarComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_InformationBarComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_InformationBarComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_InformationBarComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type InformationBar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type InformationBar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type InformationBarFragment =
  | InformationBar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | InformationBar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
  gridContent?: Maybe<string>;
  gridSubtitle?: Maybe<string>;
  gridTitle?: Maybe<string>;
  columnTitles?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_ColumnTitles';
    title1?: Maybe<string>;
    title2?: Maybe<string>;
    title3?: Maybe<string>;
  }>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_repeater';
        title?: Maybe<string>;
        contentBackground?: Maybe<string>;
        content?: Maybe<string>;
        backgroundOpacity?: Maybe<number>;
        columnSelect?: Maybe<string>;
        iconList?: Maybe<string>;
      }>
    >
  >;
  keyInfoGridSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_KeyInfoGridSettings';
    fieldGroupName?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_KeyInfoGridSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_KeyInfoGridSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type KeyInfoGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type KeyInfoGridFragment =
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | KeyInfoGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type LogoGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type LogoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
  fieldGroupName?: Maybe<string>;
  heading?: Maybe<string>;
  headingCopy?: Maybe<string>;
  columnCount?: Maybe<number>;
  logoRepeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid_logoRepeater';
        label?: Maybe<string>;
        maxWidth?: Maybe<string>;
        tailwindClasses?: Maybe<string>;
        logo?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
      }>
    >
  >;
  button?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid_Button';
    buttonStyle?: Maybe<string>;
    link?: Maybe<{
      __typename?: 'AcfLink';
      target?: Maybe<string>;
      title?: Maybe<string>;
      url?: Maybe<string>;
    }>;
  }>;
  logoGridComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid_LogoGridComponentSettings';
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    animationDirection?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid_LogoGridComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid_LogoGridComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type LogoGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type LogoGridFragment =
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | LogoGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
  fieldGroupName?: Maybe<string>;
  imageSizes?: Maybe<string>;
  layoutDirection?: Maybe<string>;
  layoutMode?: Maybe<string>;
  offsetImage?: Maybe<string>;
  header?: Maybe<string>;
  headingValue?: Maybe<string>;
  copy?: Maybe<string>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_repeater';
        copy?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        title?: Maybe<string>;
        imagePositioning?: Maybe<string>;
        showHide?: Maybe<boolean>;
        title_noTx?: Maybe<string>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        tableData?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_repeater_tableData';
              text?: Maybe<string>;
              title?: Maybe<string>;
            }>
          >
        >;
        singleLink?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  singleItemSliderComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_SingleItemSliderComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_SingleItemSliderComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_SingleItemSliderComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' };

export type MediaAndCopyCarouselFragment =
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MediaAndCopyCarousel_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
  title?: Maybe<string>;
  flipImageHorizontally?: Maybe<boolean>;
  layoutMode?: Maybe<string>;
  copy?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  title_noTx?: Maybe<string>;
  image?: Maybe<{
    __typename?: 'MediaItem';
    sourceUrl?: Maybe<string>;
    altText?: Maybe<string>;
    mediaDetails?: Maybe<{
      __typename?: 'MediaDetails';
      height?: Maybe<number>;
      width?: Maybe<number>;
    }>;
  }>;
  mediaBlock?: Maybe<{ __typename?: 'Media_block'; id: string }>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  mediaAndCopyOverlayComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_MediaAndCopyOverlayComponentSettings';
    fieldGroupName?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    mediaAndCopyPadding?: Maybe<string>;
    horizontalDirection?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    showHide?: Maybe<boolean>;
    containerMaxWidth?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_MediaAndCopyOverlayComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_MediaAndCopyOverlayComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' };

export type MediaAndCopyOverlayFragment =
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MediaAndCopyOverlay_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
  fieldGroupName?: Maybe<string>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater';
        copy?: Maybe<string>;
        title?: Maybe<string>;
        imageSize?: Maybe<string>;
        imagePositioning?: Maybe<string>;
        layoutMode?: Maybe<string>;
        componentVersion?: Maybe<string>;
        title_noTx?: Maybe<string>;
        accordionPanel?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_accordionPanel';
              panelLabel?: Maybe<string>;
              panelRepeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_accordionPanel_panelRepeater';
                    fieldGroupName?: Maybe<string>;
                    rowLabel?: Maybe<string>;
                    rowText?: Maybe<string>;
                  }>
                >
              >;
            }>
          >
        >;
        titleIcon?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        mediaBlock?: Maybe<{ __typename?: 'Media_block'; id: string }>;
        mediaCopyIconList?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_iconList';
              iconColor?: Maybe<string>;
              iconList?: Maybe<string>;
              text?: Maybe<string>;
            }>
          >
        >;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_buttons';
              buttonStyle?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
        tableData?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_tableData';
              title?: Maybe<string>;
              text?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
            }>
          >
        >;
        imageGallery?: Maybe<
          Array<
            Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string>; altText?: Maybe<string> }>
          >
        >;
      }>
    >
  >;
  halfAndHalfComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_HalfAndHalfComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    accordionBorderColor?: Maybe<string>;
    accordionIconColor?: Maybe<string>;
    accordionIconColorActive?: Maybe<string>;
    accordionPanelBackgroundColor?: Maybe<string>;
    accordionPanelLabelColor?: Maybe<string>;
    accordionRowLabelColor?: Maybe<string>;
    accordionRowTextColor?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_HalfAndHalfComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_HalfAndHalfComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MediaAndCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type MediaAndCopyFragment =
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MediaAndCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
  fieldGroupName?: Maybe<string>;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  copy?: Maybe<string>;
  meetingCapacityChartsSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    tableHeadings?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_TableHeadings';
      backgroundColour?: Maybe<string>;
      fieldGroupName?: Maybe<string>;
      textColour?: Maybe<string>;
    }>;
    tableBody?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_TableBody';
      rowTextColour2?: Maybe<string>;
      rowTextColour?: Maybe<string>;
      rowBackgroundColour?: Maybe<string>;
      fieldGroupName?: Maybe<string>;
      alternatingRowTextColour2?: Maybe<string>;
      alternatingRowTextColour?: Maybe<string>;
      alternatingRowBackgroundColour?: Maybe<string>;
    }>;
    unitTypeSwitch?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_UnitTypeSwitch';
      toggleSwitchColour?: Maybe<string>;
      toggleOutlineColour?: Maybe<string>;
      toggleBackgroundColour?: Maybe<string>;
      textColourOfUnitTypeLabel?: Maybe<string>;
      textColourOfMeasurementType?: Maybe<string>;
      fieldGroupName?: Maybe<string>;
    }>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' };

export type MeetingCapacityChartsFragment =
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MeetingCapacityCharts_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
  fieldGroupName?: Maybe<string>;
  heading?: Maybe<string>;
  meetingCapacitySummaryComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity_MeetingCapacitySummaryComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity_MeetingCapacitySummaryComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity_MeetingCapacitySummaryComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MeetingCapacity_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type MeetingCapacityFragment =
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MeetingCapacity_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
  fieldGroupName?: Maybe<string>;
  meetingGroupComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup_MeetingGroupComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup_MeetingGroupComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup_MeetingGroupComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MeetingsGroup_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type MeetingsGroupFragment =
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MeetingsGroup_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MultiColumnList_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MultiColumnList_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
  fieldGroupName?: Maybe<string>;
  title?: Maybe<string>;
  copy?: Maybe<string>;
  column?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_column';
        columnHeading?: Maybe<string>;
        list?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_column_list';
              listItem?: Maybe<string>;
            }>
          >
        >;
      }>
    >
  >;
  multiColumnListComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_MultiColumnListComponentSettings';
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    animationDirection?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_MultiColumnListComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_MultiColumnListComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MultiColumnList_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type MultiColumnListFragment =
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MultiColumnList_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
  fieldGroupName?: Maybe<string>;
  copy?: Maybe<string>;
  columnCount?: Maybe<number>;
  header?: Maybe<string>;
  headingValue?: Maybe<string>;
  headingOnActiveState?: Maybe<string>;
  titleAlignment?: Maybe<string>;
  tileLayout?: Maybe<string>;
  tileSizes?: Maybe<string>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_repeater';
        copy?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        title?: Maybe<string>;
        tileBackgroundLayer2?: Maybe<string>;
        showHide?: Maybe<boolean>;
        title_noTx?: Maybe<string>;
        tableData?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_repeater_tableData';
              title?: Maybe<string>;
              text?: Maybe<string>;
            }>
          >
        >;
        image?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
        }>;
        logo?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
        }>;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_repeater_buttons';
              buttonStyle?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
      }>
    >
  >;
  multiItemCarouselComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_MultiItemCarouselComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    headerTitleColour?: Maybe<string>;
    headerCopyColour?: Maybe<string>;
    uiPrimaryColour?: Maybe<string>;
    uiSecondaryColour?: Maybe<string>;
    titleSize?: Maybe<string>;
    tileTitleBackgroundColour?: Maybe<string>;
    headingStyle?: Maybe<string>;
    tileTitleAlignment?: Maybe<string>;
    tileTitleLayout?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_MultiItemCarouselComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_MultiItemCarouselComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' };

export type MultiColumnTilesCarouselFragment =
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MultiColumnTilesCarousel_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
  fieldGroupName?: Maybe<string>;
  header?: Maybe<string>;
  headerCopy?: Maybe<string>;
  headingValue?: Maybe<string>;
  columnCount?: Maybe<number>;
  imageSize?: Maybe<string>;
  tileFilters?: Maybe<boolean>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  filterCategories?: Maybe<
    Array<Maybe<{ __typename?: 'Category'; slug?: Maybe<string>; name?: Maybe<string> }>>
  >;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater';
        title?: Maybe<string>;
        copy?: Maybe<string>;
        iconLabel?: Maybe<string>;
        iconList?: Maybe<string>;
        tileImageLabel?: Maybe<string>;
        showHide?: Maybe<boolean>;
        title_noTx?: Maybe<string>;
        accordionPanel?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_accordionPanel';
              panelLabel?: Maybe<string>;
              panelRepeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_accordionPanel_panelRepeater';
                    fieldGroupName?: Maybe<string>;
                    rowLabel?: Maybe<string>;
                    rowText?: Maybe<string>;
                  }>
                >
              >;
            }>
          >
        >;
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        images?: Maybe<
          Array<
            Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string>; altText?: Maybe<string> }>
          >
        >;
        category?: Maybe<{ __typename?: 'Category'; name?: Maybe<string>; slug?: Maybe<string> }>;
        tableData?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_tableData';
              title?: Maybe<string>;
              copy?: Maybe<string>;
            }>
          >
        >;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_buttons';
              buttonStyle?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
      }>
    >
  >;
  multiColumnComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_MultiColumnComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    accordionBorderColor?: Maybe<string>;
    accordionIconColor?: Maybe<string>;
    accordionIconColorActive?: Maybe<string>;
    accordionPanelBackgroundColor?: Maybe<string>;
    accordionPanelLabelColor?: Maybe<string>;
    accordionRowLabelColor?: Maybe<string>;
    accordionRowTextColor?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    filterActiveTextColour?: Maybe<string>;
    filterBackgroundColour?: Maybe<string>;
    filterTextColour?: Maybe<string>;
    uiBackgroundColour?: Maybe<string>;
    uiControlsColour?: Maybe<string>;
    headerTitleColour?: Maybe<string>;
    headerCopyColour?: Maybe<string>;
    tileImageLabelBackgroundColor?: Maybe<string>;
    tileImageLabelIconColor?: Maybe<string>;
    tileImageLabelTextColor?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_MultiColumnComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_MultiColumnComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type MultiColumnTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type MultiColumnTilesFragment =
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | MultiColumnTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type OffersCurated_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type OffersCurated_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
  fieldGroupName?: Maybe<string>;
  title?: Maybe<string>;
  copy?: Maybe<string>;
  hideList?: Maybe<string>;
  offersCuratedComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated_OffersCuratedComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated_OffersCuratedComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated_OffersCuratedComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type OffersCurated_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type OffersCuratedFragment =
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | OffersCurated_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type Offset_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type Offset_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type Offset_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type Offset_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type Offset_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type Offset_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type Offset_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type Offset_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type Offset_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type Offset_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type Offset_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type Offset_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type Offset_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type Offset_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type Offset_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type Offset_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type Offset_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type Offset_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type Offset_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type Offset_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type Offset_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type Offset_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type Offset_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type Offset_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type Offset_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type Offset_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type Offset_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type Offset_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type Offset_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type Offset_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type Offset_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type Offset_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type Offset_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type Offset_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
  description?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  heading?: Maybe<string>;
  image1CroppingAutoPosition?: Maybe<boolean>;
  image1Cropping?: Maybe<string>;
  image1CroppingCropHeight?: Maybe<string>;
  image1CroppingCropType?: Maybe<string>;
  image1CroppingCropWidth?: Maybe<string>;
  image1CroppingEnableCropping?: Maybe<boolean>;
  image1CroppingOrientation?: Maybe<string>;
  image1CroppingXPosition?: Maybe<string>;
  image1CroppingXPositionAdvanced?: Maybe<string>;
  image1CroppingYPosition?: Maybe<string>;
  image1CroppingYPositionAdvanced?: Maybe<string>;
  image2CroppingYPositionAdvanced?: Maybe<string>;
  image2CroppingYPosition?: Maybe<string>;
  image2CroppingXPositionAdvanced?: Maybe<string>;
  image2CroppingXPosition?: Maybe<string>;
  image2CroppingOrientation?: Maybe<string>;
  image2CroppingEnableCropping?: Maybe<boolean>;
  image2CroppingCropWidth?: Maybe<string>;
  image2CroppingCropType?: Maybe<string>;
  image2CroppingCropHeight?: Maybe<string>;
  image2Cropping?: Maybe<string>;
  image2CroppingAutoPosition?: Maybe<boolean>;
  reverseDirection?: Maybe<boolean>;
  image1?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    slug?: Maybe<string>;
    sourceUrl?: Maybe<string>;
    title?: Maybe<string>;
    caption?: Maybe<string>;
  }>;
  image2?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
    slug?: Maybe<string>;
    caption?: Maybe<string>;
  }>;
  image2CaptionImageCaption?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_Image2CaptionImageCaption';
    captionText?: Maybe<string>;
    fieldGroupName?: Maybe<string>;
    captionLink?: Maybe<{
      __typename?: 'AcfLink';
      url?: Maybe<string>;
      title?: Maybe<string>;
      target?: Maybe<string>;
    }>;
  }>;
  image1CaptionImageCaption?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_Image1CaptionImageCaption';
    captionText?: Maybe<string>;
    fieldGroupName?: Maybe<string>;
    captionLink?: Maybe<{
      __typename?: 'AcfLink';
      target?: Maybe<string>;
      title?: Maybe<string>;
      url?: Maybe<string>;
    }>;
  }>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  offsetComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_OffsetComponentSettings';
    imageCaptionTextColor?: Maybe<string>;
    imageCaptionBackgroundColor?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    animationDirection?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_OffsetComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_OffsetComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type Offset_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type Offset_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type Offset_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type Offset_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type Offset_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type Offset_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type Offset_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type Offset_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type Offset_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type Offset_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type Offset_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type Offset_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type Offset_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type Offset_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type Offset_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type Offset_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type Offset_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type Offset_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type OffsetFragment =
  | Offset_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | Offset_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater';
        horizontalDirection?: Maybe<string>;
        title?: Maybe<string>;
        copy?: Maybe<string>;
        image1Cropping?: Maybe<string>;
        image1CroppingAutoPosition?: Maybe<boolean>;
        image1CroppingCropHeight?: Maybe<string>;
        image1CroppingCropType?: Maybe<string>;
        image1CroppingCropWidth?: Maybe<string>;
        image1CroppingEnableCropping?: Maybe<boolean>;
        image1CroppingOrientation?: Maybe<string>;
        image1CroppingXPosition?: Maybe<string>;
        image1CroppingXPositionAdvanced?: Maybe<string>;
        image1CroppingYPosition?: Maybe<string>;
        image1CroppingYPositionAdvanced?: Maybe<string>;
        image2Cropping?: Maybe<string>;
        image2CroppingAutoPosition?: Maybe<boolean>;
        image2CroppingCropHeight?: Maybe<string>;
        image2CroppingCropType?: Maybe<string>;
        image2CroppingCropWidth?: Maybe<string>;
        image2CroppingEnableCropping?: Maybe<boolean>;
        image2CroppingOrientation?: Maybe<string>;
        image2CroppingXPosition?: Maybe<string>;
        image2CroppingXPositionAdvanced?: Maybe<string>;
        image2CroppingYPosition?: Maybe<string>;
        image2CroppingYPositionAdvanced?: Maybe<string>;
        image3Cropping?: Maybe<string>;
        image3CroppingAutoPosition?: Maybe<boolean>;
        image3CroppingCropHeight?: Maybe<string>;
        image3CroppingCropType?: Maybe<string>;
        image3CroppingCropWidth?: Maybe<string>;
        image3CroppingEnableCropping?: Maybe<boolean>;
        image3CroppingOrientation?: Maybe<string>;
        image3CroppingXPosition?: Maybe<string>;
        image3CroppingXPositionAdvanced?: Maybe<string>;
        image3CroppingYPosition?: Maybe<string>;
        image3CroppingYPositionAdvanced?: Maybe<string>;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_buttons';
              buttonStyle?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
        image1?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
          title?: Maybe<string>;
          caption?: Maybe<string>;
        }>;
        image1CaptionImageCaption?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_Image1CaptionImageCaption';
          captionText?: Maybe<string>;
          captionLink?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>;
        image2?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
          title?: Maybe<string>;
          caption?: Maybe<string>;
        }>;
        image2CaptionImageCaption?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_Image2CaptionImageCaption';
          captionText?: Maybe<string>;
          captionLink?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>;
        image3?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
          title?: Maybe<string>;
        }>;
        image3CaptionImageCaption?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_Image3CaptionImageCaption';
          captionText?: Maybe<string>;
          captionLink?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>;
      }>
    >
  >;
  patchworkGridSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_PatchworkGridSettings';
    imageCaptionBackgroundColor?: Maybe<string>;
    imageCaptionTextColor?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    animationDirection?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_PatchworkGridSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_PatchworkGridSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type PatchworkGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type PatchworkGridFragment =
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | PatchworkGrid_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type ResortMap_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type ResortMap_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
  fieldGroupName?: Maybe<string>;
  mapCategoryDescription?: Maybe<string>;
  mapCategoryMobileButtonPosition?: Maybe<string>;
  mapCategoryTitle?: Maybe<string>;
  mapOverlayPosition?: Maybe<string>;
  mapImage?: Maybe<{
    __typename?: 'MediaItem';
    sourceUrl?: Maybe<string>;
    altText?: Maybe<string>;
    mediaDetails?: Maybe<{
      __typename?: 'MediaDetails';
      height?: Maybe<number>;
      width?: Maybe<number>;
    }>;
  }>;
  mapPins?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_mapPins';
        pinTitle?: Maybe<string>;
        pinX?: Maybe<string>;
        pinY?: Maybe<string>;
        pinCategory?: Maybe<string>;
        pinModal?: Maybe<boolean>;
        pinModalDescription?: Maybe<string>;
        pinModalTitle?: Maybe<string>;
        modalLinkText?: Maybe<string>;
        pinModalImage?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
          mediaDetails?: Maybe<{
            __typename?: 'MediaDetails';
            height?: Maybe<number>;
            width?: Maybe<number>;
          }>;
        }>;
        modalLink2?: Maybe<{ __typename?: 'AcfLink'; url?: Maybe<string>; target?: Maybe<string> }>;
        modalLink?: Maybe<{
          __typename?: 'Page';
          id: string;
          slug?: Maybe<string>;
          link?: Maybe<string>;
        }>;
      }>
    >
  >;
  resortMapV2Settings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_ResortMapV2Settings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    mapControlsColor?: Maybe<string>;
    mapControlsBackgroundColor?: Maybe<string>;
    tabBackgroundColor?: Maybe<string>;
    tabTextColor?: Maybe<string>;
    selectedTabBackgroundColor?: Maybe<string>;
    selectedTabTextColor?: Maybe<string>;
    mobileFilterDropdownBackgroundColor?: Maybe<string>;
    mapControlsPlacement?: Maybe<string>;
    mobileFilterDropdownTextColor?: Maybe<string>;
    pinAccentColor?: Maybe<string>;
    pinColor?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    titleColor?: Maybe<string>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    textColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_ResortMapV2Settings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_ResortMapV2Settings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type ResortMap_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type ResortMap_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type ResortMapFragment =
  | ResortMap_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | ResortMap_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type Restaurants_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type Restaurants_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
  fieldGroupName?: Maybe<string>;
  readMoreLess?: Maybe<boolean>;
  hideList?: Maybe<string>;
  restaurants?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_restaurants';
        cuisineType?: Maybe<string>;
        cuisineTypeSelect?: Maybe<string>;
        iconList?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        restaurantId?: Maybe<string>;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_restaurants_buttons';
              buttonStyle?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
        openingHours?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_restaurants_openingHours';
              hours?: Maybe<string>;
              title?: Maybe<string>;
            }>
          >
        >;
        image?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
        }>;
      }>
    >
  >;
  restaurantsComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_RestaurantsComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    hoursSeperatorColour?: Maybe<string>;
    hideTileBorder?: Maybe<boolean>;
    cuisineTypeTextColour?: Maybe<string>;
    cuisineTypeIconColour?: Maybe<string>;
    tileBorderColour?: Maybe<string>;
    topSectionBackgroundColour?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_RestaurantsComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_RestaurantsComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type Restaurants_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type Restaurants_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type RestaurantsFragment =
  | Restaurants_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | Restaurants_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type RetailFlyout_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
  copy?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  openByDefault?: Maybe<boolean>;
  pagePosition?: Maybe<string>;
  buttonStyle?: Maybe<string>;
  title?: Maybe<string>;
  link?: Maybe<{
    __typename?: 'AcfLink';
    target?: Maybe<string>;
    title?: Maybe<string>;
    url?: Maybe<string>;
  }>;
  retailFlyoutComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout_RetailFlyoutComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout_RetailFlyoutComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout_RetailFlyoutComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type RetailFlyout_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type RetailFlyoutFragment =
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | RetailFlyout_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type RetailRail_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type RetailRail_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
  fieldGroupName?: Maybe<string>;
  copy?: Maybe<string>;
  title?: Maybe<string>;
  buttons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail_buttons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  retailRailComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail_RetailRailComponentSettings';
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    closeIconColour?: Maybe<string>;
    closeTextColour?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
  }>;
};

type RetailRail_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type RetailRail_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type RetailRailFragment =
  | RetailRail_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | RetailRail_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
  fieldGroupName?: Maybe<string>;
  ctyhocn?: Maybe<string>;
  hideRoomCodes?: Maybe<string>;
  showFindYourBestRoomFilters?: Maybe<boolean>;
  roomsComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite_RoomsComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite_RoomsComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite_RoomsComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type RoomTilesLite_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type RoomTilesLiteFragment =
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | RoomTilesLite_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type RoomsTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
  fieldGroupName?: Maybe<string>;
  ctyhocn?: Maybe<string>;
  showPricing?: Maybe<Array<Maybe<string>>>;
  hideLeadRates?: Maybe<Array<Maybe<string>>>;
  hideMaxGuestsFilter?: Maybe<Array<Maybe<string>>>;
  hideBedsFilter?: Maybe<Array<Maybe<string>>>;
  hidePriceFilter?: Maybe<Array<Maybe<string>>>;
  showFindYourBestRoomFilters?: Maybe<boolean>;
  arrivalDate?: Maybe<string>;
  displayRoomsFromPosts?: Maybe<boolean>;
  pricingDisclaimer?: Maybe<string>;
  sortRoomsBy?: Maybe<string>;
  room?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room';
        roomTypeCode?: Maybe<string>;
        view?: Maybe<string>;
        recommendedFor?: Maybe<Array<Maybe<string>>>;
        premiumOptions?: Maybe<Array<Maybe<string>>>;
        balconyDetail?: Maybe<string>;
        customView?: Maybe<string>;
        customBalcony?: Maybe<string>;
        squareFootage?: Maybe<number>;
        outdoorFeatures?: Maybe<Array<Maybe<string>>>;
        roomFeatures?: Maybe<Array<Maybe<string>>>;
        roomType?: Maybe<Array<Maybe<string>>>;
        bathroomAmenities?: Maybe<Array<Maybe<string>>>;
        locations?: Maybe<Array<Maybe<string>>>;
        iconWidth?: Maybe<number>;
        floorPlanCtaUrl?: Maybe<string>;
        floorPlanCtaLabel?: Maybe<string>;
        buttonStyle?: Maybe<string>;
        enableUpsell?: Maybe<string>;
        upsellModalTitle?: Maybe<string>;
        upsellModalTitleHighlight?: Maybe<string>;
        upsellRoomCode?: Maybe<string>;
        view_noTx?: Maybe<string>;
        balconyDetail_noTx?: Maybe<string>;
        customView_noTx?: Maybe<string>;
        customBalcony_noTx?: Maybe<string>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
        }>;
        icon?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        carouselImages?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_carouselImages';
              image?: Maybe<{
                __typename?: 'MediaItem';
                sourceUrl?: Maybe<string>;
                altText?: Maybe<string>;
                mediaDetails?: Maybe<{
                  __typename?: 'MediaDetails';
                  height?: Maybe<number>;
                  width?: Maybe<number>;
                }>;
              }>;
            }>
          >
        >;
        showCustomFilter1?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ShowCustomFilter1';
          customFilter1Select?: Maybe<string>;
          showCustomFilter1?: Maybe<boolean>;
        }>;
        showCustomFilter2?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ShowCustomFilter2';
          customFilter2Select?: Maybe<string>;
          showCustomFilter2?: Maybe<boolean>;
        }>;
        showCustomFilter3?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ShowCustomFilter3';
          customFilter3Select?: Maybe<string>;
          showCustomFilter3?: Maybe<boolean>;
        }>;
        rooms?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms';
          fieldGroupName?: Maybe<string>;
          selectedRoomTitle?: Maybe<string>;
          selectedRoomTitleHighlight?: Maybe<string>;
          upsellRoomTitle?: Maybe<string>;
          upsellRoomTitleHighlight?: Maybe<string>;
          selectedRoomFeatures?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_selectedRoomFeatures';
                item?: Maybe<string>;
              }>
            >
          >;
          selectedRoomDownsellFeatures?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_selectedRoomDownsellFeatures';
                item?: Maybe<string>;
              }>
            >
          >;
          upsellRoomFeatures?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_upsellRoomFeatures';
                item?: Maybe<string>;
              }>
            >
          >;
          upsellRoomAdditionalFeatures?: Maybe<
            Array<
              Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_upsellRoomAdditionalFeatures';
                item?: Maybe<string>;
              }>
            >
          >;
        }>;
        balconyIconGroup?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_BalconyIconGroup';
          iconList?: Maybe<string>;
          iconList_noTx?: Maybe<string>;
        }>;
        viewIconGroup?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ViewIconGroup';
          iconList?: Maybe<string>;
          iconList_noTx?: Maybe<string>;
        }>;
        squareFootageIconGroup?: Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_SquareFootageIconGroup';
          iconList?: Maybe<string>;
          iconList_noTx?: Maybe<string>;
        }>;
      }>
    >
  >;
  roomsComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_RoomsComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_RoomsComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_RoomsComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type RoomsTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type RoomsTilesFragment =
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | RoomsTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
  feedId?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  socialLinkTitle?: Maybe<string>;
  socialLinkUrl?: Maybe<string>;
  socialTitle?: Maybe<string>;
  socialMediaFeedSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed_SocialMediaFeedSettings';
    fieldGroupName?: Maybe<string>;
    socialMediaIconColor?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed_SocialMediaFeedSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed_SocialMediaFeedSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type SocialMediaFeed_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type SocialMediaFeedFragment =
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | SocialMediaFeed_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type Spacer_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type Spacer_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type Spacer_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type Spacer_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type Spacer_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type Spacer_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type Spacer_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
};

type Spacer_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type Spacer_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type Spacer_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type Spacer_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type Spacer_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type Spacer_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type Spacer_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type Spacer_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type Spacer_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type Spacer_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type Spacer_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type Spacer_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type Spacer_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type Spacer_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type Spacer_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type Spacer_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type Spacer_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type Spacer_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type Spacer_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type Spacer_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type Spacer_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type Spacer_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type Spacer_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type Spacer_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type Spacer_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type Spacer_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type Spacer_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type Spacer_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type Spacer_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type Spacer_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type Spacer_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type Spacer_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type Spacer_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
  componentHeight?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  group?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_Group';
    fieldGroupName?: Maybe<string>;
    large1024px?: Maybe<string>;
    medium768px?: Maybe<string>;
    small640px?: Maybe<string>;
    extraLarge1280px?: Maybe<string>;
    twoXExtraLarge1440px?: Maybe<string>;
  }>;
  spacerComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_SpacerComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_SpacerComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_SpacerComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type Spacer_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type Spacer_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type Spacer_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type Spacer_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type Spacer_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type Spacer_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type SpacerFragment =
  | Spacer_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | Spacer_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type TabbedTableData_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type TabbedTableData_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
  fieldGroupName?: Maybe<string>;
  header?: Maybe<string>;
  menuRepeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater';
        fieldGroupName?: Maybe<string>;
        menuName?: Maybe<string>;
        contentSelection?: Maybe<string>;
        gallery?: Maybe<
          Array<
            Maybe<{
              __typename?: 'MediaItem';
              altText?: Maybe<string>;
              sourceUrl?: Maybe<string>;
              mediaDetails?: Maybe<{
                __typename?: 'MediaDetails';
                height?: Maybe<number>;
                width?: Maybe<number>;
              }>;
            }>
          >
        >;
        tableHeadingSpan?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater_tableHeadingSpan';
              text?: Maybe<string>;
              columnSpan?: Maybe<number>;
            }>
          >
        >;
        menuColumn?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater_menuColumn';
              fieldGroupName?: Maybe<string>;
              menuHeader?: Maybe<string>;
              menuItems?: Maybe<string>;
            }>
          >
        >;
        sectionTableColumns?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater_sectionTableColumns';
              column1?: Maybe<string>;
              column2?: Maybe<string>;
              column3?: Maybe<string>;
              column4?: Maybe<string>;
              column5?: Maybe<string>;
              column6?: Maybe<string>;
              column7?: Maybe<string>;
              column8?: Maybe<string>;
            }>
          >
        >;
      }>
    >
  >;
  onPageMenuSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_OnPageMenuSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_OnPageMenuSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_OnPageMenuSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type TabbedTableData_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type TabbedTableDataFragment =
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | TabbedTableData_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_repeater';
        copy?: Maybe<string>;
        title?: Maybe<string>;
        subtitle?: Maybe<string>;
        backgroundPosition?: Maybe<string>;
        backgroundRepeat?: Maybe<string>;
        backgroundSize?: Maybe<string>;
        title_noTx?: Maybe<string>;
        subtitle_noTx?: Maybe<string>;
        backgroundImage?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
        buttons?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_repeater_buttons';
              buttonStyle?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
            }>
          >
        >;
        image?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
          altText?: Maybe<string>;
        }>;
        logo?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
          mediaDetails?: Maybe<{
            __typename?: 'MediaDetails';
            height?: Maybe<number>;
            width?: Maybe<number>;
          }>;
        }>;
      }>
    >
  >;
  twoColumnCompareComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_TwoColumnCompareComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_TwoColumnCompareComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_TwoColumnCompareComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type TwoColumnCompare_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type TwoColumnCompareFragment =
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | TwoColumnCompare_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
  fieldGroupName?: Maybe<string>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_repeater';
        copy?: Maybe<string>;
        tileSize?: Maybe<string>;
        title?: Maybe<string>;
        title_noTx?: Maybe<string>;
        image?: Maybe<{
          __typename?: 'MediaItem';
          altText?: Maybe<string>;
          sourceUrl?: Maybe<string>;
        }>;
      }>
    >
  >;
  fourStaggeredTilesComponentSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_FourStaggeredTilesComponentSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_FourStaggeredTilesComponentSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_FourStaggeredTilesComponentSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar' };

export type TwoColumnHoverTilesFragment =
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | TwoColumnHoverTiles_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
  fieldGroupName?: Maybe<string>;
  subtitle?: Maybe<string>;
  title?: Maybe<string>;
  repeater?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_repeater';
        fieldGroupName?: Maybe<string>;
        iconList?: Maybe<string>;
        iconDescription?: Maybe<string>;
        iconText?: Maybe<string>;
      }>
    >
  >;
  twoColumnIconListSettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_TwoColumnIconListSettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_TwoColumnIconListSettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_TwoColumnIconListSettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type TwoColumnIconList_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type TwoColumnIconListFragment =
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | TwoColumnIconList_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
  fieldGroupName?: Maybe<string>;
  subtitle?: Maybe<string>;
  title?: Maybe<string>;
  flipLargeImageHorizontally?: Maybe<boolean>;
  copy?: Maybe<string>;
  imageLargeCropHeight?: Maybe<string>;
  imageLargeCropWidth?: Maybe<string>;
  imageLargeEnableCropping?: Maybe<boolean>;
  imageLargeCropType?: Maybe<string>;
  imageLargeXPosition?: Maybe<string>;
  imageLargeYPosition?: Maybe<string>;
  imageLargeXPositionAdvanced?: Maybe<string>;
  imageLargeYPositionAdvanced?: Maybe<string>;
  imageSmallCropHeight?: Maybe<string>;
  imageSmallCropWidth?: Maybe<string>;
  imageSmallEnableCropping?: Maybe<boolean>;
  imageSmallCropType?: Maybe<string>;
  imageSmallXPosition?: Maybe<string>;
  imageSmallYPosition?: Maybe<string>;
  imageSmallXPositionAdvanced?: Maybe<string>;
  imageSmallYPositionAdvanced?: Maybe<string>;
  subtitle_noTx?: Maybe<string>;
  title_noTx?: Maybe<string>;
  largeImage?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
  }>;
  smallImage?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
  }>;
  additionalSmallImage?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
  }>;
  twoOneMediaCopySettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_TwoOneMediaCopySettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    horizontalDirection?: Maybe<string>;
    verticalDirection?: Maybe<string>;
    smallImageBorderColour?: Maybe<string>;
    smallImageBorderWidth?: Maybe<string>;
    imageInsetBorderColour?: Maybe<string>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_TwoOneMediaCopySettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_TwoOneMediaCopySettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type TwoOneMediaCopyFragment =
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | TwoOneMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
  fieldGroupName?: Maybe<string>;
  reverseDirection?: Maybe<boolean>;
  imageColumn1AutoPosition?: Maybe<boolean>;
  imageColumn1CropHeight?: Maybe<string>;
  imageColumn1CropType?: Maybe<string>;
  imageColumn1CropWidth?: Maybe<string>;
  imageColumn1EnableCropping?: Maybe<boolean>;
  imageColumn1XPosition?: Maybe<string>;
  imageColumn1XPositionAdvanced?: Maybe<string>;
  imageColumn1YPosition?: Maybe<string>;
  imageColumn1YPositionAdvanced?: Maybe<string>;
  imageColumn2AutoPosition?: Maybe<boolean>;
  imageColumn2CropHeight?: Maybe<string>;
  imageColumn2CropType?: Maybe<string>;
  imageColumn2CropWidth?: Maybe<string>;
  imageColumn2EnableCropping?: Maybe<boolean>;
  imageColumn2XPosition?: Maybe<string>;
  imageColumn2XPositionAdvanced?: Maybe<string>;
  imageColumn2YPosition?: Maybe<string>;
  imageColumn2YPositionAdvanced?: Maybe<string>;
  column1?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column1';
    title?: Maybe<string>;
    copy?: Maybe<string>;
    title_noTx?: Maybe<string>;
    image?: Maybe<{ __typename?: 'MediaItem'; altText?: Maybe<string>; sourceUrl?: Maybe<string> }>;
    buttons?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column1_buttons';
          buttonStyle?: Maybe<string>;
          link?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
  column2?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column2';
    title?: Maybe<string>;
    copy?: Maybe<string>;
    title_noTx?: Maybe<string>;
    image?: Maybe<{ __typename?: 'MediaItem'; altText?: Maybe<string>; sourceUrl?: Maybe<string> }>;
    buttons?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column2_buttons';
          buttonStyle?: Maybe<string>;
          link?: Maybe<{
            __typename?: 'AcfLink';
            target?: Maybe<string>;
            title?: Maybe<string>;
            url?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
  twoTwoMediaCopySettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_TwoTwoMediaCopySettings';
    fieldGroupName?: Maybe<string>;
    horizontalDirection?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_TwoTwoMediaCopySettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_TwoTwoMediaCopySettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type TwoTwoMediaCopyFragment =
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | TwoTwoMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
  copy?: Maybe<string>;
  fieldGroupName?: Maybe<string>;
  videoMediaUrl?: Maybe<string>;
  videoTitle?: Maybe<string>;
  heading?: Maybe<string>;
  imageLarge?: Maybe<string>;
  imageLargeAutoPosition?: Maybe<boolean>;
  imageLargeCropHeight?: Maybe<string>;
  imageLargeCropType?: Maybe<string>;
  imageSmall?: Maybe<string>;
  imageLargeYPositionAdvanced?: Maybe<string>;
  imageLargeYPosition?: Maybe<string>;
  imageLargeXPositionAdvanced?: Maybe<string>;
  imageLargeXPosition?: Maybe<string>;
  imageLargeEnableCropping?: Maybe<boolean>;
  imageLargeCropWidth?: Maybe<string>;
  imageSmallYPositionAdvanced?: Maybe<string>;
  imageSmallYPosition?: Maybe<string>;
  imageSmallXPositionAdvanced?: Maybe<string>;
  imageSmallXPosition?: Maybe<string>;
  imageSmallEnableCropping?: Maybe<boolean>;
  imageSmallCropWidth?: Maybe<string>;
  imageSmallCropType?: Maybe<string>;
  imageSmallCropHeight?: Maybe<string>;
  imageSmallAutoPosition?: Maybe<boolean>;
  buttonsButtons?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_buttonsButtons';
        buttonStyle?: Maybe<string>;
        fieldGroupName?: Maybe<string>;
        link?: Maybe<{
          __typename?: 'AcfLink';
          target?: Maybe<string>;
          title?: Maybe<string>;
          url?: Maybe<string>;
        }>;
      }>
    >
  >;
  largeImage?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
  }>;
  smallImage?: Maybe<{
    __typename?: 'MediaItem';
    altText?: Maybe<string>;
    sourceUrl?: Maybe<string>;
  }>;
  componentSettingsVideoMediaCopySettings?: Maybe<{
    __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_ComponentSettingsVideoMediaCopySettings';
    fieldGroupName?: Maybe<string>;
    showHide?: Maybe<boolean>;
    componentBackgroundColor?: Maybe<string>;
    componentBackgroundSize?: Maybe<string>;
    componentBackgroundRepeat?: Maybe<string>;
    componentBackgroundPosition?: Maybe<string>;
    contentBackgroundColor?: Maybe<string>;
    contentBackgroundSize?: Maybe<string>;
    contentBackgroundRepeat?: Maybe<string>;
    contentBackgroundPosition?: Maybe<string>;
    contentBackgroundColorLayerTwo?: Maybe<string>;
    subtitleColor?: Maybe<string>;
    textAlignment?: Maybe<string>;
    textColor?: Maybe<string>;
    titleColor?: Maybe<string>;
    animationDirection?: Maybe<string>;
    hideAnimations?: Maybe<string>;
    hideParallax?: Maybe<string>;
    sliderAnimations?: Maybe<string>;
    zoomOnHover?: Maybe<string>;
    containerMaxWidth?: Maybe<string>;
    headingsNoTranslate?: Maybe<boolean>;
    componentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    contentBackgroundImage?: Maybe<{ __typename?: 'MediaItem'; sourceUrl?: Maybe<string> }>;
    paddingTop?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_ComponentSettingsVideoMediaCopySettings_PaddingTop';
      paddingTopDesktop?: Maybe<string>;
      paddingTopTablet?: Maybe<string>;
      paddingTopMobile?: Maybe<string>;
    }>;
    paddingBottom?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_ComponentSettingsVideoMediaCopySettings_PaddingBottom';
      paddingBottomDesktop?: Maybe<string>;
      paddingBottomTablet?: Maybe<string>;
      paddingBottomMobile?: Maybe<string>;
    }>;
  }>;
};

type VideoMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
};

export type VideoMediaCopyFragment =
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | VideoMediaCopy_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid' };

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles' };

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles' };

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent' };

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_LogoGrid';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay' };

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment =
  { __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts' };

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Offset_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment = {
  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
};

type WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment =
  {
    __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
    introCopy?: Maybe<string>;
    title?: Maybe<string>;
    componentSettings?: Maybe<{
      __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings';
      backgroundPrimary?: Maybe<string>;
      backgroundSecondary?: Maybe<string>;
      backgroundTernary?: Maybe<string>;
      headerCopyAlign?: Maybe<string>;
      headerCopyColor?: Maybe<string>;
      headerTitleAlign?: Maybe<string>;
      headerTitleColor?: Maybe<string>;
      mainTextColor?: Maybe<string>;
      progressActive?: Maybe<string>;
      progressInactive?: Maybe<string>;
      radioButtonChecked?: Maybe<string>;
      radioButtonDisabled?: Maybe<string>;
      radioButtonUnchecked?: Maybe<string>;
      subheadingAlign?: Maybe<string>;
      subheadingColor?: Maybe<string>;
      emailAddress?: Maybe<string>;
      emailCopy?: Maybe<string>;
      emailTitle?: Maybe<string>;
      activeBackgroundColor?: Maybe<string>;
      activeTextColor?: Maybe<string>;
      inactiveBackgroundColor?: Maybe<string>;
      inactiveTextColor?: Maybe<string>;
      cellFreeGroup?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellFreeGroup';
        backgroundColor?: Maybe<string>;
        cornerFlagColor?: Maybe<string>;
        textColor?: Maybe<string>;
      }>;
      cellFullGroup?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellFullGroup';
        backgroundColor?: Maybe<string>;
        cornerFlagColor?: Maybe<string>;
        textColor?: Maybe<string>;
      }>;
      cellPartialGroup?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellPartialGroup';
        backgroundColor?: Maybe<string>;
        cornerFlagColor?: Maybe<string>;
        textColor?: Maybe<string>;
      }>;
      cellSelectedGroup?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellSelectedGroup';
        backgroundColor?: Maybe<string>;
        cornerFlagColor?: Maybe<string>;
        textColor?: Maybe<string>;
      }>;
      paddingBottom?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_PaddingBottom';
        paddingBottomDesktop?: Maybe<string>;
        paddingBottomMobile?: Maybe<string>;
        paddingBottomTablet?: Maybe<string>;
      }>;
      paddingTop?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_PaddingTop';
        paddingTopDesktop?: Maybe<string>;
        paddingTopMobile?: Maybe<string>;
        paddingTopTablet?: Maybe<string>;
      }>;
      cellDisabledGroup?: Maybe<{
        __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellDisabledGroup';
        backgroundColor?: Maybe<string>;
        textColor?: Maybe<string>;
        cornerFlagColor?: Maybe<string>;
      }>;
    }>;
  };

export type WeddingsAvailabilityCalendarFragment =
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Accordion_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_AddressBar_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ComparisonTable_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Cvent_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EditorialSnippet_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_FullWidthImageContent_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_GallerySlider_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HalfAndHalf_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelAmenities_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelPolicies_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HotelTitle_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_HtmlMenu_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_IconBlock_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageGallery_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageGalleryWall_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ImageReveal_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_InformationBar_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Introduction_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_KeyInfoGrid_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_LocationGoogleMap_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_LogoGrid_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Masthead_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingCapacity_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MeetingsGroup_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiColumn_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiColumnList_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_MultiItemCarousel_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_OffersCurated_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Offset_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_OnPageMenus_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_PatchworkGrid_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ResortMap_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_ResortMapV2_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Restaurants_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RetailFlyout_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RetailRail_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RoomTypes_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_RoomTypesLite_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_SingleItemSlider_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_SocialMediaFeed_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_Spacer_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoColumnCompare_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoColumnIconList_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_VideoMediaCopy_Fragment
  | WeddingsAvailabilityCalendar_Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_Fragment;
