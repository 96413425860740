/* eslint-disable import/namespace */
import type { PageProps } from '../';
import { brandProps } from '../brand-props';
import type {
  BreadcrumbProps,
  FooterProps,
  GenericModalProps,
  HeaderStyles,
  LogoSettingsProps,
} from '@curated-property/shared-components';

type WpThemeSettings = NonNullable<NonNullable<PageProps>['wpThemeSettings']>;

export function headerStylesCMS(
  props?: NonNullable<WpThemeSettings['headerSettings']>['SettingsHeaderSettings'],
  brandCode?: string,
  enableHHR?: boolean
) {
  // Use the new Hilton Hotel and Resorts defaults if the enableHHR value is true
  const useBrandCode = enableHHR ? 'hiltonhotelsresorts' : brandCode?.toLowerCase() || '';
  const allBrandProps = brandProps[useBrandCode as keyof typeof brandProps] as WpThemeSettings & {
    brandHeaderProps?: HeaderStyles;
  };
  const brand = allBrandProps?.brandHeaderProps;
  const hasBackground = props?.mainNavStyles?.menuItemActiveState === 'Background Colour';
  const hasUnderline =
    props?.mainNavStyles?.menuItemActiveState === 'Underline' ||
    props?.mainNavStyles?.menuItemActiveState == null;

  return {
    headerClassName: brand?.headerClassName || undefined,
    navStyles: brand?.navStyles || undefined,
    textColorOnLoad: props?.textColour || brand?.textColorOnLoad || '#fff',
    bgColorOnLoad: props?.backgroundColourGradient
      ? props?.backgroundColourGradient
      : props?.backgroundColour || brand?.bgColorOnLoad || 'rgba(42, 58, 78, 0.7)',
    textColorOnActive: props?.textColourActive || brand?.textColorOnActive || '#fff',
    bgColorOnActive:
      props?.backgroundColourActive || brand?.bgColorOnActive || 'rgba(10, 57, 113, 0.98)',
    bgSubmenuColor:
      props?.mainNavStyles?.submenuBackgroundColour ||
      brand?.bgSubmenuColor ||
      'rgba(10, 57, 113, 0.97)',
    separatorColor: brand?.separatorColor || undefined,
    separatorClasses: brand?.separatorColor || undefined,
    fontWeight: props?.mainNavStyles?.fontWeight || brand?.fontWeight || 'bold',
    dividerColourActive: props?.dividerColourActive,
    dividerColour: props?.dividerColour,
    menuItems: {
      fontSize: props?.mainNavStyles?.fontSize || brand?.menuItems?.fontSize || undefined,
      colorOnHover: brand?.menuItems?.colorOnHover || undefined,
      paddingClasses:
        props?.mainNavStyles?.menuItemPadding || brand?.menuItems?.paddingClasses || undefined,
      case: props?.mainNavStyles?.fontCase || brand?.menuItems?.case || undefined,
      underline: {
        active: hasUnderline ? true : false,
        color:
          props?.mainNavStyles?.menuItemUnderlineColour ||
          brand?.menuItems?.underline?.color ||
          undefined,
        size: brand?.menuItems?.underline?.size || undefined,
      },
      itemActive: {
        classes: hasBackground ? 'transition-all duration-200 p-1.5 py-3 rounded' : undefined,
        bgColor: hasBackground ? props?.mainNavStyles?.menuItemActiveBackgroundColour : undefined,
        bgText: hasBackground ? props?.mainNavStyles?.menuItemActiveTextColour : undefined,
      },
    },
    iconMenu: {
      extraClasses: brand?.iconMenu?.extraClasses || undefined,
      fontSize: brand?.iconMenu?.fontSize || undefined,
      color: props?.iconNavStyles?.textColour || brand?.iconMenu?.color,
      iconColor: props?.iconNavStyles?.iconColour || brand?.iconMenu?.iconColor,
      activeColor: props?.iconNavStyles?.textColourActive || brand?.iconMenu?.activeColor,
      activeIconColor: props?.iconNavStyles?.iconColourActive || brand?.iconMenu?.activeIconColor,
      separatorColor: props?.iconNavStyles?.separatorColour || brand?.iconMenu?.separatorColor,
      underline: props?.iconNavStyles?.underline || brand?.iconMenu?.underline,
      underlineHover:
        // props?.iconNavStyles?.underlineHover ||
        brand?.iconMenu?.underlineHover || true,
    },
    honorsSignIn: {
      backgroundColor:
        props?.honorsSignInStyles?.backgroundColour ||
        brand?.honorsSignIn?.backgroundColor ||
        undefined,
      backgroundHighlightColor:
        props?.honorsSignInStyles?.menuLinkBackgroundHighlightColour ||
        brand?.honorsSignIn?.backgroundHighlightColor ||
        undefined,
      textColor:
        props?.honorsSignInStyles?.textColour || brand?.honorsSignIn?.textColor || undefined,
      textHighlightColor:
        props?.honorsSignInStyles?.menuLinkTextHighlightColour ||
        brand?.honorsSignIn?.textHighlightColor ||
        undefined,
    },
    bookingButton: {
      mainClasses: brand?.bookingButton?.mainClasses || undefined,
      extraClasses: brand?.bookingButton?.extraClasses || undefined,
      buttonStyle:
        props?.bookingWidgetButtonStyles?.buttonStyle ||
        brand?.bookingButton?.buttonStyle ||
        undefined,
      backgroundColor:
        props?.bookingWidgetButtonStyles?.backgroundColour ||
        brand?.bookingButton?.backgroundColor ||
        undefined,
      backgroundColorHover:
        props?.bookingWidgetButtonStyles?.backgroundColourHover ||
        brand?.bookingButton?.backgroundColorHover ||
        undefined,
      textColor:
        props?.bookingWidgetButtonStyles?.textColour ||
        brand?.bookingButton?.textColor ||
        undefined,
      textColorHover:
        props?.bookingWidgetButtonStyles?.textColourHover ||
        brand?.bookingButton?.textColorHover ||
        undefined,
      borderColor:
        props?.bookingWidgetButtonStyles?.borderColour ||
        brand?.bookingButton?.borderColor ||
        undefined,
      borderColorHover:
        props?.bookingWidgetButtonStyles?.borderColourHover ||
        brand?.bookingButton?.borderColorHover ||
        undefined,
      textCase:
        props?.bookingWidgetButtonStyles?.textCase || brand?.bookingButton?.textCase || undefined,
      hideBookingWidget: props?.bookingWidgetButtonStyles?.hideBookingWidget || undefined,
    },
    languageSwitchTitleStyles: {
      textCase:
        props?.languageSwitchTitleStyles?.textCase ||
        brand?.languageSwitchTitleStyles?.textCase ||
        undefined,
      textColour:
        props?.languageSwitchTitleStyles?.textColour ||
        brand?.languageSwitchTitleStyles?.textColour ||
        undefined,
      textColourActive:
        props?.languageSwitchTitleStyles?.textColourActive ||
        brand?.languageSwitchTitleStyles?.textColourActive ||
        undefined,
      textColourActiveHover:
        props?.languageSwitchTitleStyles?.textColourActive ||
        brand?.languageSwitchTitleStyles?.textColourActiveHover ||
        undefined,
      textColourHover:
        props?.languageSwitchTitleStyles?.textColourHover ||
        brand?.languageSwitchTitleStyles?.textColourHover ||
        undefined,
    },
    languageSwitchDropdownStyles: {
      textColourHover:
        props?.languageSwitchDropdownStyles?.textColourHover ||
        brand?.languageSwitchDropdownStyles?.textColourHover ||
        undefined,
      textColour:
        props?.languageSwitchDropdownStyles?.textColour ||
        brand?.languageSwitchDropdownStyles?.textColour ||
        undefined,
      dividerColour:
        props?.languageSwitchDropdownStyles?.dividerColour ||
        brand?.languageSwitchDropdownStyles?.dividerColour ||
        undefined,
      backgroundColourHover:
        props?.languageSwitchDropdownStyles?.backgroundColourHover ||
        brand?.languageSwitchDropdownStyles?.backgroundColourHover ||
        undefined,
      backgroundColour:
        props?.languageSwitchDropdownStyles?.backgroundColour ||
        brand?.languageSwitchDropdownStyles?.backgroundColour ||
        undefined,
    },
    languageSwitchButtonStyles: {
      backgroundColour:
        props?.languageSwitchButtonStyles?.backgroundColour ||
        brand?.languageSwitchButtonStyles?.backgroundColour ||
        undefined,
      backgroundColourHover:
        props?.languageSwitchButtonStyles?.backgroundColourHover ||
        brand?.languageSwitchButtonStyles?.backgroundColourHover ||
        undefined,
      textCase:
        props?.languageSwitchButtonStyles?.textCase ||
        brand?.languageSwitchButtonStyles?.textCase ||
        undefined,
      textColour:
        props?.languageSwitchButtonStyles?.textColour ||
        brand?.languageSwitchButtonStyles?.textColour ||
        undefined,
      textColourHover:
        props?.languageSwitchButtonStyles?.textColourHover ||
        brand?.languageSwitchButtonStyles?.textColourHover ||
        undefined,
    },
    languageSwitchButtonStylesActive: {
      textColourHover:
        props?.languageSwitchButtonStylesActive?.textColourHover ||
        brand?.languageSwitchButtonStylesActive?.textColourHover ||
        undefined,
      textColour:
        props?.languageSwitchButtonStylesActive?.textColour ||
        brand?.languageSwitchButtonStylesActive?.textColour ||
        undefined,
      textCase:
        props?.languageSwitchButtonStylesActive?.textCase ||
        brand?.languageSwitchButtonStylesActive?.textCase ||
        undefined,
      backgroundColourHover:
        props?.languageSwitchButtonStylesActive?.backgroundColourHover ||
        brand?.languageSwitchButtonStylesActive?.backgroundColourHover ||
        undefined,
      backgroundColour: props?.languageSwitchButtonStylesActive?.backgroundColour || undefined,
    },
  };
}

export function breadcrumbStylesCMS(
  props?: NonNullable<WpThemeSettings['footerSettings']>['SettingsFooterSettings'],
  brandCode?: string
) {
  const allBrandProps = brandProps[
    (brandCode?.toLowerCase() || '') as keyof typeof brandProps
  ] as WpThemeSettings & { brandBreadcrumbProps?: BreadcrumbProps };
  const brand = allBrandProps?.brandBreadcrumbProps;
  return {
    activeTextColour: props?.breadcrumbs?.activeTextColour || brand?.activeTextColour || undefined,
    backgroundColour: props?.breadcrumbs?.backgroundColour || brand?.backgroundColour || undefined,
    textColour: props?.breadcrumbs?.textColour || brand?.textColour || undefined,
    delimiterColour: props?.breadcrumbs?.delimiterColour || brand?.delimiterColour || undefined,
    delimiterPadding: props?.breadcrumbs?.delimiterPadding || brand?.delimiterPadding || 'p-3',
  };
}

export function footerStylesCMS(
  props?: NonNullable<WpThemeSettings['footerSettings']>['SettingsFooterSettings'],
  brandCode?: string
) {
  const allBrandProps = brandProps[
    (brandCode?.toLowerCase() || '') as keyof typeof brandProps
  ] as WpThemeSettings & { brandFooterProps?: FooterProps };
  const brand = allBrandProps?.brandFooterProps;
  return {
    brandPhoneNumber: brand?.brandPhoneNumber || '+1-800-HILTONS',
    backgroundImage:
      props?.footer?.backgroundImage?.sourceUrl || brand?.backgroundImage || undefined,
    backgroundColour: props?.footer?.backgroundColour || brand?.backgroundColour || undefined,
    textColour: props?.footer?.textColour || brand?.textColour || undefined,
    copyrightBackgroundColour:
      props?.copyright?.backgroundColour || brand?.copyrightBackgroundColour || undefined,
    copyrightTextColour: props?.copyright?.textColour || brand?.copyrightTextColour || undefined,
    dividerColour: props?.footer?.dividerColour || undefined,
  };
}

export function logoStylesCMS(
  props?: NonNullable<WpThemeSettings['logoSettings']>['SettingsLogoSettings'],
  brandCode?: string
) {
  const allBrandProps = brandProps[
    (brandCode?.toLowerCase() || '') as keyof typeof brandProps
  ] as WpThemeSettings & { brandLogoProps?: LogoSettingsProps };
  const brand = allBrandProps?.brandLogoProps;
  return {
    brandLogoClasses: props?.brandLogoClasses || brand?.brandLogoClasses || undefined,
    brandLogoClassesFooter:
      props?.brandLogoClassesFooter || brand?.brandLogoClassesFooter || undefined,
    brandLogoColourActive:
      props?.brandLogoColourActive || brand?.brandLogoColourActive || undefined,
    brandLogoColourFooter:
      props?.brandLogoColourFooter || brand?.brandLogoColourFooter || undefined,
    brandLogoColourInactive:
      props?.brandLogoColourInactive || brand?.brandLogoColourInactive || undefined,
    hotelLogoClasses: props?.hotelLogoClasses || brand?.hotelLogoClasses || undefined,
    hotelLogoClassesFooter:
      props?.hotelLogoClassesFooter || brand?.hotelLogoClassesFooter || undefined,
    hotelLogoColourFooter:
      props?.hotelLogoColourFooter || brand?.hotelLogoColourFooter || undefined,
    hotelLogoColourActive:
      props?.hotelLogoColourActive || brand?.hotelLogoColourActive || undefined,
    hotelLogoColourInactive:
      props?.hotelLogoColourInactive || brand?.hotelLogoColourInactive || undefined,
    hotelLogo: {
      altText: props?.hotelLogo?.altText || brand?.hotelLogo?.altText || undefined,
      sourceUrl: props?.hotelLogo?.sourceUrl || brand?.hotelLogo?.sourceUrl || undefined,
    },
    brandLogo: {
      altText: props?.brandLogo?.altText || brand?.brandLogo?.altText || undefined,
      sourceUrl: props?.brandLogo?.sourceUrl || brand?.brandLogo?.sourceUrl || undefined,
    },
  };
}

export function modalStylesCMS(
  props?: NonNullable<WpThemeSettings['modalSettings']>['modalSettings'],
  brandCode?: string
) {
  const allBrandProps = brandProps[
    (brandCode?.toLowerCase() || '') as keyof typeof brandProps
  ] as WpThemeSettings & {
    brandModalProps?: GenericModalProps['modalSettings'];
  };
  const brand = allBrandProps?.brandModalProps;
  return {
    modalBackgroundColour:
      props?.modalBackgroundColour || brand?.modalBackgroundColour || undefined,
    modalBackgroundOpacity:
      props?.modalBackgroundOpacity || brand?.modalBackgroundOpacity || undefined,
    modalCloseButtonBackgroundColour:
      props?.modalCloseButtonBackgroundColour ||
      brand?.modalCloseButtonBackgroundColour ||
      undefined,
    modalCloseButtonIconColour:
      props?.modalCloseButtonIconColour || brand?.modalCloseButtonIconColour || undefined,
    contentBackgroundColour:
      props?.contentBackgroundColour || brand?.contentBackgroundColour || undefined,
    contentTextColor: props?.contentTextColour || undefined,
    hideBoxShadow: props?.hideBoxShadow || brand?.hideBoxShadow || undefined,
  };
}
