import * as React from 'react';
import { HandleWYSIWYGContent } from './functions/helper';

interface SingleLine {
  text?: string;
  copy?: string;
  title?: string;
}

interface Props {
  repeater?: SingleLine[];
  textColor?: string;
}

export function CopyTable({ repeater, textColor }: Props) {
  return (
    <div
      data-element-id="copy-table-component"
      data-testid="copy-table"
      className="mb-8 flex w-full flex-col justify-between"
    >
      {repeater?.map((item, key: number) => {
        let srHours = '';
        if (item?.text && item?.text.includes('–')) {
          srHours = item?.text.replace(/–/g, 'through').replace(/<br\/>/g, ' ');
        }
        return (
          <React.Fragment key={key}>
            {item?.title || item?.text ? (
              <div
                data-element-id="copy-table-component-row-item"
                data-testid="copy-table-row-item"
                className="border-current/10 flex justify-between border-b border-solid py-2 text-base lg:text-lg"
                style={{ borderColor: textColor }}
                key={key}
              >
                <span className="w-7/12 text-left font-bold" style={{ color: textColor }}>
                  {item?.title}
                </span>
                <span
                  aria-hidden={item?.text && item?.text.includes('–') ? true : false}
                  dangerouslySetInnerHTML={{
                    __html: HandleWYSIWYGContent(item?.text, textColor),
                  }}
                  style={{ color: textColor }}
                  className="w-2/5"
                />
                {item?.text && item?.text.includes('–') ? (
                  <div className="sr-only">{srHours}</div>
                ) : (
                  ''
                )}
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
}
